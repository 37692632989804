// @flow
import type {ComponentType} from 'react'
import type {HOC} from 'recompose'
import type {ReissueCardFormType, Card} from '../../state'

import {hoistStatics} from 'recompose'
import {connect} from 'react-redux'

import * as reissueCardForm from '../../actions/cards/reissueCardForm'

type InputReissueCardProps = {
  card: Card,
  Link: ComponentType<{openModal: typeof reissueCardForm.actions.openModal}>
}

export type ReissueCardProps = InputReissueCardProps &
  ReissueCardFormType &
  typeof reissueCardForm.actions

const container: HOC<ReissueCardProps, InputReissueCardProps> = connect(
  reissueCardForm.selector,
  reissueCardForm.actions
)

export default hoistStatics(container)
