// @flow
import type {HOC} from 'recompose'
import type {State, User} from '../state'

import {connect} from 'react-redux'
import {hoistStatics} from 'recompose'

import {userSelector} from '../selectors'

const container: HOC<{user: User}, {}> = connect((state: State) => ({
  user: userSelector(state)
}))

export default hoistStatics(container)
