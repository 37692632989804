import React, {PropsWithChildren} from 'react'
import styled, {CSSProperties, css} from 'styled-components'

type CommonTextProps = PropsWithChildren & {
  type?: 'secondary' | 'heading' | 'subheading' | 'large' | 'title' | 'lead'
  small?: boolean
  center?: boolean
  bold?: boolean
  uppercase?: boolean
  style?: CSSProperties
}

const StyledText = styled.span<CommonTextProps>`
  font-family: 'basier_circle', sans-serif;
  color: ${({
    color,
    theme: {
      text: {colors}
    }
  }) => colors[color || ''] || colors.default};
  font-size: ${props => (props.small ? '14px' : '16px')};
  margin-top: 0;
  text-align: ${props => (props.center ? 'center' : 'left')};
  font-weight: ${props => (props.bold ? '500' : '400')};

  ${props => String(props.style)}
`

const StyledTextSecondary = styled(StyledText)`
  color: #5d7083;

  ${props =>
    props.uppercase &&
    css`
      font-size: 12px;
      text-transform: uppercase;
    `};
`

StyledTextSecondary.defaultProps = {
  small: true
}

const StyledTextLarge = styled(StyledText)`
  font-size: 1.25rem;
  font-weight: 500;
`

const StyledTextTitle = styled(StyledText).attrs({as: 'h1'})`
  font-size: 32px;
  line-height: 48px;
  margin: 0;
  font-weight: normal;
  margin-bottom: 48px;
`

const StyledTextHeading = styled(StyledTextLarge).attrs({as: 'h2'})`
  margin: 0;
`

const StyledTextSubheading = styled(StyledText).attrs({as: 'h3'})`
  font-weight: 500;
  margin-bottom: 0.5em;
`

const StyledTextLead = styled(StyledText).attrs({as: 'p'})`
  margin-bottom: 1.5em;
`

const Text = ({type, ...restProps}: CommonTextProps) => {
  if (type === 'secondary') {
    return <StyledTextSecondary {...restProps} />
  } else if (type === 'heading') {
    return <StyledTextHeading {...restProps} />
  } else if (type === 'subheading') {
    return <StyledTextSubheading {...restProps} />
  } else if (type === 'large') {
    return <StyledTextLarge {...restProps} />
  } else if (type === 'title') {
    return <StyledTextTitle {...restProps} />
  } else if (type === 'lead') {
    return <StyledTextLead {...restProps} />
  }

  return <StyledText {...restProps} />
}

export default Text
