import qs from 'qs'

import {getEnv} from '../utils/env'
import {getPushToken} from '../utils/pushToken'
import storage from '../utils/storage'
import {isWeb} from '../utils/utils'

export class NetworkError /*:: extends Error*/ {
  name: string
  message: string
  stack: string | undefined

  constructor(message: string) {
    /*:: super() */
    this.name = 'NetworkError'
    this.message = message
    this.stack = new Error().stack
  }
}

export class ApiRequestError /*:: extends Error*/ {
  name: string
  status: number
  requestId: string | null
  message: string
  errorCode: string | undefined
  stack: string | undefined

  constructor(
    url: string,
    status: number,
    requestId: string | null,
    message: string,
    errorCode?: string
  ) {
    /*:: super() */
    this.name = 'ApiRequestError'
    this.status = status
    this.requestId = requestId
    this.message = `${message} (URL: ${url})`
    this.errorCode = errorCode
    this.stack = new Error().stack
  }
}

const parseResponse = async (res: Response) => {
  const contentType = res.headers.get('content-type')
  const isJSON = contentType && contentType.includes('application/json')
  const parsed = await (isJSON ? res.json() : res.text())
  if (res.ok) {
    if (isJSON) {
      return parsed
    } else {
      if (parsed === 'OK') return undefined
      else throw new Error(`Expected JSON but got text: ${parsed}`)
    }
  } else {
    const [errorMessage, errorCode]: [string, string | undefined] =
      typeof parsed === 'string'
        ? [parsed, undefined]
        : [parsed.message, parsed.errorCode || undefined]
    const requestId = res.headers.get('x-request-id')
    throw new ApiRequestError(
      res.url,
      res.status,
      requestId,
      errorMessage,
      errorCode
    )
  }
}

const cookieCredentials =
  process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'

export const loadWebAccessToken = async () => {
  const url = `${getEnv().REACT_APP_API_URL || ''}/api/auth/access-token`

  const options: RequestInit = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({}),
    credentials: cookieCredentials
  }

  let res
  try {
    res = await fetch(url, options)
    if (res.status !== 401) {
      const parsed = await parseResponse(res)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setApiRequest(parsed.token)
      return true
    }
  } catch (networkError) {
    throw new NetworkError((networkError as Error).message)
  }
  return false
}

const withAccessTokenRetry = async fn => {
  let res = await fn()

  // do not prolong access token on 'check-activity' request
  const splittedUrl = res.url.split('/')
  if (splittedUrl[splittedUrl.lenght - 1] === 'check-activity') {
    return res
  }

  if (isWeb() && res.status === 401) {
    await loadWebAccessToken()
    res = await fn()
  }
  return res
}

// NOTE: may throw a number of different errors
// Don't forget to wrap in try..catch and usually call
// await dispatch(handleApiError(error, ...)) in catch
type UserOptions = {
  body?: Record<string, unknown>
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  queryParams?: Record<string, unknown>
  formFields?: Record<string, unknown>
}
const getApiRequest = (token?: string) => async (
  apiPath: string,
  userOptions: UserOptions = {}
) => {
  let url = `${getEnv().REACT_APP_API_URL || ''}/api${apiPath}`

  if (userOptions.queryParams) {
    url = `${url}?${qs.stringify(userOptions.queryParams, {skipNulls: true})}`
  }

  const options: RequestInit & {
    headers: NonNullable<RequestInit['headers']>
  } = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Cache-Control': 'no-store'
    }
  }

  if (token) {
    options.headers['X-Token'] = token
  }

  if (isWeb()) {
    options.credentials = cookieCredentials
  }

  const pushToken = await getPushToken()
  if (pushToken) {
    options.headers['X-Push-Token'] = pushToken
  }

  options.headers['X-Platform'] = window.platform

  if (userOptions.method && userOptions.method !== 'GET') {
    options.method = userOptions.method
    options.cache = 'no-cache'
  }

  if (userOptions.body) {
    options.body = JSON.stringify(userOptions.body)
    options.headers['Content-Type'] = 'application/json'
  } else if (userOptions.formFields) {
    const formData = new FormData()
    Object.entries(userOptions.formFields).forEach(([fieldName, value]) => {
      const arr = Array.isArray(value) ? value : [value]
      arr.forEach(val => formData.append(fieldName, val))
    })
    options.body = formData
  }

  if (window.appVersion) options.headers['App-Version'] = window.appVersion
  if (window.buildVersion)
    options.headers['Build-Version'] = window.buildVersion

  const locale = storage.getItem('locale')
  if (locale) {
    options.headers['X-Lang'] = locale
  }

  let res
  try {
    res = await withAccessTokenRetry(() => fetch(url, options))
  } catch (networkError) {
    throw new NetworkError((networkError as Error).message)
  }
  return await parseResponse(res)
}

export let apiRequest = getApiRequest()
export let hasAccessToken = false

// using "function" to take advantage of JS hoisting
// the function will closure "access_token" into "apiRequest"
export function setApiRequest(token?: string) {
  apiRequest = getApiRequest(token)
  hasAccessToken = !!token
}
