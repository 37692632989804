// @flow
import {useState, useMemo, useEffect} from 'react'
import {compact} from 'lodash'
import {useDispatch} from 'react-redux'

import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import * as validations from '../../utils/validations'
import {usePrevious} from '../../utils/hooks'
import {hashAuthData, saveCredentials} from '../../utils/auth'

export type UsernameModalProps = {
  isOpen: boolean,
  close: Function,
  continueFlow: Function
}

export const useUsernameForm = ({isOpen, continueFlow}: UsernameModalProps) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [username, setUsername] = useState('')

  const wrapFieldSetter = fieldSetter => value => {
    setError(null)
    fieldSetter(value)
  }

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      setLoading(false)
      setError(null)
      setUsername('')
    }
  }, [isOpen, prevIsOpen])

  const submitUsernameAndContinue = async () => {
    if (loading) return

    setLoading(true)
    try {
      const {usernameType} = await apiRequest(
        '/auth/forgot-pw/validate-username',
        {
          method: 'POST',
          body: {username}
        }
      )
      continueFlow(usernameType, username)
    } catch (e) {
      setLoading(false)
      await dispatch(
        handleApiError(e, [
          [
            'ERR_INVALID_USERNAME',
            'ERR_RATELIMIT_EXCEEDED',
            'ERR_CODE_ENTER_LIMIT_EXCEEDED'
          ],
          errorCode => setError(errorCode)
        ])
      )
    }
  }

  return {
    loading,
    error,
    username,
    setUsername: wrapFieldSetter(setUsername),
    submitUsernameAndContinue
  }
}

export type NewPasswordModalProps = {
  isOpen: boolean,
  close: Function,
  username: string,
  usernameType: string,
  code: string
}

export const useNewPasswordForm = ({
  isOpen,
  username,
  usernameType,
  code,
  platform = 'mobile'
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const wrapFieldSetter = fieldSetter => value => {
    setError(null)
    fieldSetter(value)
  }

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      setLoading(false)
      setError(null)
      setPassword('')
      setConfirmPassword('')
    }
  }, [isOpen, prevIsOpen])

  const submitNewPasswordAndContinue = async () => {
    if (loading) return

    const validationErrors = compact([
      validations.password(password),
      password !== confirmPassword && 'ERR_PASSWORDS_DONT_MATCH'
    ])
    if (validationErrors.length) {
      setError(validationErrors[0])
      return
    }

    setLoading(true)
    try {
      const {token} = await apiRequest('/auth/forgot-pw/update-pw', {
        method: 'POST',
        body: {
          username,
          usernameType,
          password: hashAuthData(password),
          code
        }
      })

      await saveCredentials(token, username)
      dispatch({type: 'LOGIN'})
    } catch (e) {
      setLoading(false)
      await dispatch(
        handleApiError(e, [
          ['ERR_EXPIRED', 'ERR_INVALID_DATA', 'ERR_CODE_ENTER_LIMIT_EXCEEDED'],
          errorCode => setError(errorCode)
        ])
      )
    }
  }

  return {
    loading,
    error,
    fields: {password, confirmPassword},
    fieldSetters: {
      password: wrapFieldSetter(setPassword),
      confirmPassword: wrapFieldSetter(setConfirmPassword)
    },
    submitNewPasswordAndContinue
  }
}

type OpenedModal = null | 'Username' | 'Code' | 'NewPassword'

export const useForgotPasswordFlow = () => {
  const dispatch = useDispatch()

  const [openedModal, setOpenedModal] = useState<OpenedModal>(null)

  const [usernameType, setUsernameType] = useState(null)
  const [username, setUsername] = useState(null)
  const [code, setCode] = useState(null)

  const open = () => {
    if (openedModal) return
    setOpenedModal('Username')
  }

  const usernameModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'Username',
      close: () => setOpenedModal(null),
      continueFlow: (usernameType, username) => {
        setUsernameType(usernameType)
        setUsername(username)
        setOpenedModal('Code')
      }
    }),
    [openedModal, setOpenedModal]
  )

  const codeModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'Code',
      close: () => setOpenedModal(null),
      contactType: usernameType || 'email',
      resendCodeApiRequest: () =>
        apiRequest('/send-verification-code', {
          method: 'POST',
          body: {
            contactType: usernameType,
            contactValue: username,
            reason: 'FORGOT_PASSWORD'
          }
        }),
      validateCodeAndContinue: async (code, errorCallback) => {
        try {
          await apiRequest('/verify-contact-value', {
            method: 'POST',
            body: {
              contactType: usernameType,
              contactValue: username,
              reason: 'FORGOT_PASSWORD',
              code
            }
          })

          setCode(code)
          setOpenedModal('NewPassword')
        } catch (e) {
          await dispatch(
            handleApiError(e, [
              [
                'ERR_INVALID_DATA',
                'ERR_EXPIRED',
                'ERR_CODE_ENTER_LIMIT_EXCEEDED'
              ],
              errorCode => errorCallback(errorCode)
            ])
          )
        }
      }
    }),
    [dispatch, openedModal, setOpenedModal, username, usernameType]
  )

  const newPasswordModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'NewPassword',
      close: () => setOpenedModal(null),
      username,
      usernameType,
      code
    }),
    [code, openedModal, setOpenedModal, username, usernameType]
  )

  return {open, usernameModalProps, codeModalProps, newPasswordModalProps}
}
