// @flow
import type {ContextRouter} from 'react-router-dom'

import React, {useState} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {Download} from 'react-feather'

import {
  bankStatementsYearsSelector,
  bankStatementListByYearSelector
} from '../../../selectors'
import {formatMessage} from '../../../i18n'
import {Text, Group, GroupItem, Select} from '../../ui'

const Container = styled.div`
  flex: 1;
`

const DownloadButton = styled.a`
  font-size: 24px;
  border: 0;
  background: none;
  cursor: pointer;
  color: #5d7083;
`

const Filetype = styled.span`
  display: inline-block;
  border: 1px solid #d8e1e6;
  border-radius: 2px;
  padding: 4px;
  margin-right: 16px;
`

const Action = styled.div`
  display: flex;
  align-items: center;
`

const BankStatements = ({match}: ContextRouter) => {
  const accountId = match.params.accountId || ''

  const years = useSelector(state =>
    bankStatementsYearsSelector(state, accountId)
  )

  const [year, setYear] = useState(years[0] || null)

  const bankStatements = useSelector(state =>
    bankStatementListByYearSelector(state, accountId, year)
  )

  if (!years.length) {
    return (
      <Text data-cy="no-bank-statements" type="secondary">
        {formatMessage('noBankStatements')}
      </Text>
    )
  }

  return (
    <Container>
      <Select
        data-cy="year-select"
        label={formatMessage('period')}
        options={years.map(year => ({
          value: year,
          label: formatMessage('selectYearLabel', {year})
        }))}
        value={year}
        onChange={e => setYear(Number(e.target.value))}
      />
      <Group>
        {bankStatements.map(({id, year, month, extension, url}) => (
          <GroupItem
            data-cy="bank-statement"
            key={id}
            value={formatMessage('bankStatementLabel', {year, month})}
            action={
              <Action>
                <Filetype>
                  <Text type="secondary" data-cy="filetype">
                    {formatMessage('filetype', {extension})}
                  </Text>
                </Filetype>
                <DownloadButton
                  data-cy="download-button"
                  href={url}
                  target="_blank"
                >
                  <Download />
                </DownloadButton>
              </Action>
            }
          />
        ))}
      </Group>
    </Container>
  )
}

export default BankStatements
