// @flow
import type {HOC} from 'recompose'
import type {State} from '../state'

import {connect} from 'react-redux'
import {compose, hoistStatics} from 'recompose'

import {SET_VALUE} from '../actions/commonActions'

export type GeneralErrorProps = {
  visibleGeneralError: null | 'UNKNOWN_ERROR' | 'NETWORK_ERROR',
  dismissGeneralError: () => void
}

const dismissGeneralError = () => SET_VALUE(['visibleGeneralError'], null)

const container: HOC<GeneralErrorProps, {}> = compose(
  connect(
    (state: State) => ({visibleGeneralError: state.visibleGeneralError}),
    {dismissGeneralError}
  )
)

export default hoistStatics(container)
