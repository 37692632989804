export const theme = {
  alert: {
    colors: {
      success: {
        background: '#6EB472',
        color: '#fff'
      },
      error: {
        background: '#E15151',
        color: '#fff'
      },
      info: {
        background: '#F3F5F6',
        color: '#002F5E'
      }
    }
  },
  text: {
    colors: {
      green: '#6EB472',
      red: '#E15151',
      default: '#002f5e'
    }
  },
  app: {
    colors: {
      background: {dark: '#f3f5f6', light: '#fff'}
    },
    sizes: {
      maxWidth: '868px',
      minWidth: '768px'
    }
  },
  button: {
    colors: {
      primary: {normal: '#FFA920', hover: '#F09605', color: '#ffffff'},
      secondary: {normal: '#002F5E', hover: '#18497A', color: '#ffffff'},
      ghost: {
        normal: '#FFFFFF',
        hover: '#F3F5F6',
        color: '#002F5E',
        border: '#D8E1E6'
      },
      disabled: {
        normal: '#F3F5F6',
        hover: '#F3F5F6',
        color: 'rgba(0, 47, 94, 0.4)'
      }
    },
    sizes: {
      medium: {
        height: '56px',
        padding: '16px 24px',
        fontSize: '16px',
        borderRadius: '2px'
      },
      small: {
        height: '40px',
        padding: '8px 16px',
        fontSize: '14px',
        borderRadius: '2px'
      }
    }
  },
  switch: {
    colors: {
      on: {
        background: '#F3F5F6',
        knob: '#FFA920'
      },
      off: {
        background: '#F3F5F6',
        knob: '#788999'
      },
      disabled: {
        background: '#F3F5F6',
        knob: '#D8E1E6'
      }
    },
    sizes: {
      width: '48px',
      height: '24px'
    }
  },
  inputfield: {
    colors: {
      input: {
        border: {
          normal: '#D8E1E6',
          error: '#E15151'
        },
        background: '#fff',
        color: '#002F5E',
        placeholder: '#a0a0a0'
      },
      error: {
        background: '#E15151',
        color: '#fff'
      }
    }
  },
  groupItem: {
    colors: {
      red: '#E15151',
      default: '#002f5e'
    }
  }
}
