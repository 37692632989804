import React, {PropsWithChildren, ReactNode} from 'react'
import styled, {css} from 'styled-components'

import Text from './Text'

type CommonGroupItemProps = PropsWithChildren & {
  label?: ReactNode
  value?: ReactNode
  middleLabel?: ReactNode
  middleValue?: ReactNode
  action?: ReactNode
  hoverable?: boolean
  reverseContent?: boolean
  spaceBetween?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLLIElement>
  beforeContent?: ReactNode
  'data-cy'?: string
  labelColor?: string // TODO: better type / remove
}

const StyledGroupItem = styled.li<CommonGroupItemProps>`
  border-bottom: 1px solid #d8e1e6;
  text-decoration: none;
  padding: 0 24px;
  position: relative;
  height: ${props => (props.children ? 'auto' : '86px')};
  padding: ${props => (props.children ? '16px 24px' : '0 24px')};
  width: 100%;

  ${props =>
    (props.hoverable || props.onClick) &&
    css`
      transition: background-color 0.25s;
      cursor: pointer;

      &:hover {
        background-color: #fafafa;
      }
    `} &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: 0;
  }
`

const Content = styled.div<CommonGroupItemProps>`
  display: flex;
  flex-direction: ${props =>
    props.reverseContent ? 'column-reverse' : 'column'};
  max-width: 80%;
`

const ValueText = styled(Text)<CommonGroupItemProps>`
  font-weight: 500;
`

const LabelText = styled(Text).attrs({
  type: 'secondary'
})<CommonGroupItemProps>`
  margin-bottom: ${props => (props.reverseContent ? '0' : '8px')};
  margin-top: ${props => (props.reverseContent ? '4px' : '0')};
`
const MiddleLabelText = styled(LabelText)`
  margin-left: 8px;
`

const MiddleText = styled(Text)`
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Action = styled.div`
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`

const Row = styled.div<CommonGroupItemProps>`
  position: relative;
  display: flex;
  justify-content: ${props =>
    props.spaceBetween ? 'space-between' : 'flex-start'};
  align-items: center;
`

const BeforeContent = styled.div<CommonGroupItemProps>`
  margin-right: 24px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`

const GroupItem = ({
  label,
  value,
  middleLabel,
  middleValue,
  action,
  children,
  reverseContent,
  hoverable,
  disabled,
  beforeContent,
  'data-cy': datacy,
  labelColor,
  ...restProps
}: CommonGroupItemProps) => (
  <StyledGroupItem hoverable={hoverable} data-cy={datacy} {...restProps}>
    <Row>
      {beforeContent && (
        <BeforeContent disabled={disabled}>{beforeContent}</BeforeContent>
      )}
      {(label || value) && (
        <Content reverseContent={reverseContent}>
          <Row>
            {label && (
              <LabelText
                data-cy="group-item-label"
                reverseContent={reverseContent}
              >
                {label}
              </LabelText>
            )}
            {middleLabel && (
              <MiddleLabelText reverseContent={reverseContent}>
                {middleLabel}
              </MiddleLabelText>
            )}
          </Row>
          {middleValue && <MiddleText>{middleValue}</MiddleText>}
          {value && (
            <ValueText
              data-cy="group-item-value"
              labelColor={labelColor} // TODO: looks like this does nothing
            >
              {value}
            </ValueText>
          )}
        </Content>
      )}
      {action && <Action>{action}</Action>}
    </Row>
    {children}
  </StyledGroupItem>
)

export default GroupItem
