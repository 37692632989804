import type {State} from './state'

import {
  sortBy,
  values,
  filter,
  map,
  orderBy,
  uniq,
  groupBy,
  defaults
} from 'lodash'

// ##### AUTH #####

export const loginStatusSelector = (state: State) => state.auth.loginStatus

// ##### PIN LOCK #####

export const pinLockStatusSelector = (state: State) => state.pinLock.status

// ##### CLIENT #####

export const userSelector = (state: State) => state.user

export const clientsSelector = (state: State) => state.clients

// this can be undefined if user dont have any remoteClientId
export const clientSelector = (state: State) => {
  if (!state.user) return undefined
  if (!state.user.clientId) return undefined
  return state.clients[state.user.clientId]
}

export const usernameSelector = (state: State) => {
  const user = userSelector(state)
  const client = clientSelector(state)
  if (client) {
    return client.name || client.email || client.phone
  }
  if (user) {
    if (user.email) return user.email
    if (user.phone) return user.phone
  }
  return ''
}

export const emailSelector = (state: State) => {
  const user = userSelector(state)
  const client = clientSelector(state)

  if (user && user.email) {
    return user.email
  } else if (client && client.email) {
    return client.email
  }
  return ''
}

// ##### ACCOUNTS #####

export const accountsSelector = (state: State) => state.accounts

export const accountListSelector = (state: State) => {
  const accounts = accountsSelector(state)
  return sortBy(values(accounts), 'id')
}

export const accountSelector = (state: State, accountId: string) => {
  const accounts = accountsSelector(state)
  return accounts[accountId]
}

export const accountClientSelector = (state: State, accountId: string) => {
  const account = accountSelector(state, accountId)
  const clients = clientsSelector(state)
  return account ? clients[account.clientId] : undefined
}

// ##### TRANSACTIONS #####

export const accountTransactionsDataSelector = (
  state: State,
  accountId: string
) => state.transactionsByAccount[accountId]

// ##### CARDS #####

const cardsSelector = (state: State) => state.cards

export const allCardsListSelector = (state: State) => {
  const cards = cardsSelector(state)
  return values(cards)
}

export const areUserAndCardSettingsNotLoaded = (state: State) => {
  const user = userSelector(state)
  const cards = allCardsListSelector(state)

  if (!user) return true
  const openableCard = cards.find(c => c.openable)
  // when card is openable it should have settings - if they're missing,
  // we probably need to do a full client request to get them
  return !!openableCard && !openableCard.settings
}

export const accountCardsListSelector = (state: State, accountId: string) => {
  const cards = cardsSelector(state)
  return filter(cards, card => card.accountId === accountId)
}

export const allCardsAndAccountsListSelector = (state: State) => {
  const cardsList = allCardsListSelector(state)
  const accounts = accountsSelector(state)
  return map(cardsList, card => ({card, account: accounts[card.accountId]}))
}

export const cardSelector = (state: State, cardId: string) => {
  const cards = cardsSelector(state)
  return cards[cardId]
}

// ##### GUESTS #####

export const invitesSelector = (state: State) => state.invites

export const inviteListsGroupedSelector = (state: State, accountId: string) => {
  const invites = invitesSelector(state)
  const accountInvites = sortBy(
    filter(invites, invite => invite.accountId === accountId),
    'date'
  )
  const groupedInvites = groupBy(accountInvites, invite =>
    invite.accepted ? 'accepted' : 'waiting'
  )

  return defaults(groupedInvites, {accepted: [], waiting: []})
}

export const inviteSelector = (state: State, inviteId: string) => {
  const invites = invitesSelector(state)
  return invites ? invites[inviteId] : null
}

// ##### BANK STATEMENTS #####

export const bankStatementsSelector = (state: State, accountId: string) => {
  const account = accountSelector(state, accountId)
  return account ? account.bankStatements : {}
}

export const bankStatementListByYearSelector = (
  state: State,
  accountId: string,
  year: number
) => {
  const bankStatements = bankStatementsSelector(state, accountId)
  const filteredBankStatementList = filter(
    bankStatements,
    bankStatement => bankStatement.year === year
  )
  return orderBy(filteredBankStatementList, ['month'], ['desc'])
}

export const bankStatementsYearsSelector = (
  state: State,
  accountId: string
) => {
  const bankStatements = bankStatementsSelector(state, accountId)
  const years = map(bankStatements, ({year}) => year)
  const uniqYears = uniq(years)
  return orderBy(uniqYears, [], ['desc'])
}
