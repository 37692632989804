import {compact} from 'lodash'

import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import {SET_VALUE, SET_FORM_FIELD_VALUE} from '../commonActions'
import {getInitialRequestMoneyForm} from '../../state'
import {userSelector} from '../../selectors'
import * as validations from '../../utils/validations'
import {getNumber} from '../../utils/utils'

const PATH = ['forms', 'requestMoneyForm']

export const RequestMoneyModalType = {
  ChoosePaymentType: 'ChoosePaymentType',
  IbanPayment: 'IbanPayment',
  SkpayPayment: 'SkpayPayment',
  EnterCodeIban: 'EnterCodeIban',
  EnterCodeSkpay: 'EnterCodeSkpay',
  RequestSent: 'RequestSent'
}

export const selector = state => state.forms.requestMoneyForm

const setField = (fieldName, fieldValue) =>
  SET_FORM_FIELD_VALUE(PATH, fieldName, fieldValue)

const openModal = modalType => dispatch => {
  dispatch(SET_VALUE([...PATH, 'error'], null))
  dispatch(SET_VALUE([...PATH, 'loading'], false))
  dispatch(SET_VALUE([...PATH, 'openedModalType'], modalType))
}

const openRequestMoneyModal = () => (dispatch, getState) => {
  dispatch(openModal(RequestMoneyModalType.ChoosePaymentType))
}

const navigateToIbanPayment = () => (dispatch, getState) => {
  dispatch(openModal(RequestMoneyModalType.IbanPayment))
}

const navigateToSkpayPayment = () => (dispatch, getState) => {
  dispatch(openModal(RequestMoneyModalType.SkpayPayment))
}

const closeModal = () => (dispatch, getState) => {
  if (selector(getState()).loading) return
  dispatch(SET_VALUE(PATH, getInitialRequestMoneyForm()))
}

const PAYMENT_MODAL_TYPES = [
  RequestMoneyModalType.IbanPayment,
  RequestMoneyModalType.SkpayPayment
]

const isPaymentModalType = openedModalType =>
  PAYMENT_MODAL_TYPES.includes(openedModalType)

const submitAndSendCode = () => async (dispatch, getState) => {
  const user = userSelector(getState())
  const {
    fields: {
      iban,
      receiverSkpayAccountNo,
      amount,
      receiverName,
      vsCode,
      ssCode,
      ksCode
    },
    openedModalType,
    loading
  } = selector(getState())

  if (!user || !isPaymentModalType(openedModalType) || loading) return

  const amountNumber = getNumber(amount)

  const validationErrors = compact([
    openedModalType === RequestMoneyModalType.IbanPayment &&
      validations.iban(iban),
    openedModalType === RequestMoneyModalType.IbanPayment &&
      validations.ibanCollectionAccount(iban),
    openedModalType === RequestMoneyModalType.SkpayPayment &&
      validations.variableSymbol(receiverSkpayAccountNo),
    amountNumber.isEmpty && 'ERR_REQUIRED_IS_MISSING',
    validations.moneyNumber(amount),
    !amountNumber.isValid && 'ERR_INVALID_DATA',
    amountNumber.isValid && amountNumber.number <= 0 && 'ERR_INVALID_DATA',
    !receiverName && 'ERR_REQUIRED_IS_MISSING',
    vsCode && validations.variableSymbol(vsCode),
    ssCode && validations.specificSymbol(ssCode),
    ksCode && validations.constantSymbol(ksCode)
  ])
  if (validationErrors.length) {
    dispatch(SET_VALUE([...PATH, 'error'], validationErrors[0]))
    return
  }

  dispatch(SET_VALUE([...PATH, 'loading'], true))

  try {
    await apiRequest('/send-verification-code-to-user', {
      method: 'POST',
      body: {contactType: user.contactType, reason: 'REQUEST_MONEY'}
    })
    dispatch(SET_FORM_FIELD_VALUE(PATH, 'code', ''))
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], null))
    dispatch(
      openModal(
        openedModalType === RequestMoneyModalType.IbanPayment
          ? RequestMoneyModalType.EnterCodeIban
          : RequestMoneyModalType.EnterCodeSkpay
      )
    )
  } catch (error) {
    await dispatch(
      handleApiError(error, [
        ['ERR_INVALID_IBAN_FORMAT', 'ERR_RATELIMIT_EXCEEDED'],
        errorCode => dispatch(SET_VALUE([...PATH, 'error'], errorCode))
      ])
    )
  } finally {
    dispatch(SET_VALUE([...PATH, 'loading'], false))
  }
}

const resendCode = () => async (dispatch, getState) => {
  const user = userSelector(getState())

  if (!user) return

  try {
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], null))
    await apiRequest('/send-verification-code-to-user', {
      method: 'POST',
      body: {contactType: user.contactType, reason: 'REQUEST_MONEY'}
    })
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], {success: true}))
    dispatch(SET_VALUE([...PATH, 'error'], null))
  } catch (error) {
    await dispatch(
      handleApiError(error, [
        ['ERR_RATELIMIT_EXCEEDED'],
        errorCode => {
          dispatch(
            SET_VALUE([...PATH, 'codeResendRequest'], {
              success: false,
              error: errorCode
            })
          )
        }
      ])
    )
  }
}

const submitCodeAndRequestMoneyIban = accountId => async (
  dispatch,
  getState
) => {
  const {
    fields: {iban, amount, receiverName, vsCode, ksCode, ssCode, code},
    openedModalType,
    loading
  } = selector(getState())

  if (openedModalType !== RequestMoneyModalType.EnterCodeIban || loading) return

  dispatch(SET_VALUE([...PATH, 'loading'], true))

  const amountNumber = Math.round(getNumber(amount).number * 100) // in cents

  try {
    await apiRequest(`/accounts/${accountId}/request-money-iban`, {
      method: 'POST',
      body: {
        iban,
        amount: amountNumber,
        receiverName,
        vsCode,
        ksCode,
        ssCode,
        code
      }
    })
    dispatch(openModal(RequestMoneyModalType.RequestSent))
  } catch (error) {
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], null))
    await dispatch(
      handleApiError(error, [
        ['ERR_EXPIRED', 'ERR_INVALID_DATA', 'ERR_CODE_ENTER_LIMIT_EXCEEDED'],
        errorCode => dispatch(SET_VALUE([...PATH, 'error'], errorCode))
      ])
    )
  } finally {
    dispatch(SET_VALUE([...PATH, 'loading'], false))
  }
}

const submitCodeAndRequestMoneySkpay = accountId => async (
  dispatch,
  getState
) => {
  const {
    fields: {receiverSkpayAccountNo, amount, receiverName, code},
    openedModalType,
    loading
  } = selector(getState())

  if (openedModalType !== RequestMoneyModalType.EnterCodeSkpay || loading)
    return

  dispatch(SET_VALUE([...PATH, 'loading'], true))

  const amountNumber = Math.round(getNumber(amount).number * 100) // in cents

  try {
    await apiRequest(`/accounts/${accountId}/request-money-skpay`, {
      method: 'POST',
      body: {
        accountTo: receiverSkpayAccountNo,
        amount: amountNumber,
        receiverName,
        code
      }
    })
    dispatch(openModal(RequestMoneyModalType.RequestSent))
  } catch (error) {
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], null))
    await dispatch(
      handleApiError(error, [
        ['ERR_EXPIRED', 'ERR_INVALID_DATA', 'ERR_CODE_ENTER_LIMIT_EXCEEDED'],
        errorCode => dispatch(SET_VALUE([...PATH, 'error'], errorCode))
      ])
    )
  } finally {
    dispatch(SET_VALUE([...PATH, 'loading'], false))
  }
}

export const actions = {
  setField,
  openRequestMoneyModal,
  navigateToIbanPayment,
  navigateToSkpayPayment,
  closeModal,
  submitAndSendCode,
  resendCode,
  submitCodeAndRequestMoneyIban,
  submitCodeAndRequestMoneySkpay
}
