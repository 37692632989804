// @flow
import {parsePhoneNumberFromString} from 'libphonenumber-js/max'
import IBAN from 'iban'
import {NATIVE_APP_PIN_LENGTH} from '../constants'

// NOTE: similar logic is in server/validations.js, PLEASE KEEP IN SYNC
// here on frontend, each function should return null for valid input and error for invalid input

export const email = (email: string): ?string => {
  // copied from
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Basic_validation
  // eslint-disable-next-line max-len
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  return regex.test(email) ? null : 'ERR_INVALID_EMAIL_FORMAT'
}

export const phone = (phone: string): ?string => {
  const parsedPhone = parsePhoneNumberFromString(phone, 'SK')
  if (parsedPhone && parsedPhone.isValid()) return null
  return 'ERR_INVALID_PHONE_FORMAT'
}

//8+ characters, contains lowercase, uppercase letter and a digit.
export const password = (password: string): ?string => {
  if (
    !/^.{8,}$/.test(password) ||
    /.*\s.*/.test(password) ||
    !/.*[a-z].*/.test(password) ||
    !/.*[A-Z].*/.test(password) ||
    !/.*\d.*/.test(password)
  )
    return 'ERR_INVALID_PASSWORD_FORMAT'
  return null
}

export const pinCode = pin => {
  // check min length
  if (pin.length < NATIVE_APP_PIN_LENGTH) return false

  // check if all chars are the same
  if (pin.split(pin[0]).length - 1 === pin.length) return false

  let isAscending = true
  let isDescending = true

  for (let i = 1; i < pin.length; i++) {
    const prevCharCode = pin.charCodeAt(i - 1)
    const currentCharCode = pin.charCodeAt(i)

    // check if is not ascending
    if (prevCharCode + 1 !== currentCharCode) {
      isAscending = false
    }

    // check if is not descending
    if (prevCharCode - 1 !== currentCharCode) {
      isDescending = false
    }

    if (!isAscending && !isDescending) return true
  }

  return false
}

const isNumberOfMaxLength10 = testedString => {
  return /^\d{1,10}$/.test(testedString)
}

export const variableSymbol = (vs: string): ?string =>
  isNumberOfMaxLength10(vs) ? null : 'ERR_INVALID_VS_FORMAT'

export const constantSymbol = (vs: string): ?string =>
  isNumberOfMaxLength10(vs) ? null : 'ERR_INVALID_KS_FORMAT'

export const specificSymbol = (vs: string): ?string =>
  isNumberOfMaxLength10(vs) ? null : 'ERR_INVALID_SS_FORMAT'

export const nationality = (nationality: string): ?string => {
  const regex = /^[A-Z]{2}$/
  return regex.test(nationality) ? null : 'ERR_INVALID_NATIONALITY_FORMAT'
}

export const moneyNumber = numberString => {
  // positive number with max. 2 decimals
  return /^\d+((\.|,)\d{1,2})?$/.test(numberString)
    ? null
    : 'ERR_INVALID_MONEY_AMOUNT'
}

const ALLOWED_SEPA_IBAN_PREFIXES = [
  'AD',
  'BE',
  'BG',
  'HR',
  'CY',
  'DK',
  'EE',
  'FR',
  'FI',
  'GR',
  'NL',
  'IS',
  'LI',
  'LT',
  'LV',
  'LU',
  'MT',
  'HU',
  'MC',
  'DE',
  'NO',
  'PT',
  'PL',
  'AT',
  'RO',
  'SM',
  'SK',
  'SI',
  'GB',
  'IT',
  'IE',
  'CZ',
  'ES',
  'CH',
  'SE',
  'VA'
]

const ibanUsesAllowedPrefix = iban =>
  !!iban && ALLOWED_SEPA_IBAN_PREFIXES.includes(iban.slice(0, 2))

export const iban = (iban: string) => {
  if (!IBAN.isValid(iban)) return 'ERR_INVALID_IBAN_FORMAT'
  if (!ibanUsesAllowedPrefix(iban)) return 'ERR_IBAN_NOT_SEPA'
  return null
}

// Should we move this somewhere else ?
const COLLECTION_ACCOUNT = 'SK9865000000001414141414'

export const ibanCollectionAccount = iban => {
  if (iban.replace(/ /g, '') === COLLECTION_ACCOUNT)
    return 'ERR_IBAN_COLLECTION_ACCOUNT'
  return null
}
