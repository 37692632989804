// @flow
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {inviteSelector} from '../../../selectors'
import {apiRequest} from '../../apiRequest'
import handleApiError from '../../handleApiError'
import {formatMessage} from '../../../i18n'
import {showConfirmationDialog} from '../../../confirmationDialog'

export const useInvite = (inviteId: string) => {
  const dispatch = useDispatch()

  const invite = useSelector(state => inviteSelector(state, inviteId))

  const [inviteDeleteLoading, setInviteDeleteLoading] = useState(false)

  const deleteInvite = async () => {
    if (inviteDeleteLoading) return

    const confirmed = await showConfirmationDialog(
      formatMessage('confirmDialogDeleteInvite')
    )
    if (!confirmed) return

    setInviteDeleteLoading(true)
    try {
      await apiRequest('/invite', {method: 'DELETE', body: {inviteId}})
      dispatch({type: 'DELETE_VALUE', path: ['invites', inviteId]})
    } catch (e) {
      setInviteDeleteLoading(false)
      await dispatch(handleApiError(e))
    }
  }

  const [inviteResendRequest, setInviteResendRequest] = useState({})

  const resendInvite = async () => {
    if (inviteResendRequest.loading) return

    setInviteResendRequest({loading: true})

    try {
      await apiRequest('/invite/resend', {method: 'POST', body: {inviteId}})
      setInviteResendRequest({success: true})
    } catch (e) {
      await dispatch(
        handleApiError(e, [
          ['ERR_RATELIMIT_EXCEEDED', 'ERR_INVITE_ALREADY_ACCEPTED'],
          errorCode => {
            setInviteResendRequest({error: errorCode})
          }
        ])
      )
      if (inviteResendRequest.loading) setInviteResendRequest({})
    }
  }

  return {
    invite,
    deleteInvite,
    inviteDeleteLoading,
    resendInvite,
    inviteResendRequest
  }
}
