export default {
  // general
  dateValue:
    '{date, date} {showTime, select, false{} other{at {date, time, short}}}',
  ERR_LOCKED_OUT:
    'You have entered 3 invalid attempts. The login will be available after 5 minutes.',
  // backend errors
  ERR_INVALID_DATA: 'Invalid data. Please check and try again.',
  ERR_INVALID_VS:
    "Variable symbol doesn't exist. Processing of new card may take up to 1 business day.",
  ERR_INVALID_VS_FORMAT: 'Invalid format of variable symbol',
  ERR_INVALID_KS_FORMAT: 'Invalid format of constant symbol',
  ERR_INVALID_SS_FORMAT: 'Invalid format of specific symbol',
  ERR_INVALID_MONEY_AMOUNT: 'Invalid format of amount',
  ERR_INVALID_USERNAME: 'Invalid format of user name.',
  ERR_DUPLICATE_RECORD: 'User already exists',
  ERR_INVALID_EMAIL: "Email doesn't match the variable symbol",
  ERR_INVALID_PHONE: "Mobile number doesn't match the variable symbol",
  ERR_INVALID_PASSWORD: 'Invalid password',
  ERR_INVALID_PASSWORD_FORMAT:
    'Password must contain at least 8 characters with no whitespaces and must include a digit, a lowercase and an uppercase character',
  ERR_EXPIRED: 'Activation code expired.',
  ERR_USER_ALREADY_ACTIVATED: 'This user is already activated.',
  ERR_CANT_INVITE_MYSELF: "You can't invite yourself.",
  ERR_INVITE_ALREADY_EXIST: 'Invitation for this contact already exists',
  ERR_RATELIMIT_EXCEEDED: 'SMS limit exceeded. Try again later.',
  ERR_CODE_ENTER_LIMIT_EXCEEDED:
    'Code retry limit exceeded. Request for a new code.',
  ERR_UNAUTHORIZED: 'You are not authorised.',
  // frontend errors
  ERR_INVALID_EMAIL_FORMAT: 'Invalid email format',
  ERR_INVALID_PHONE_FORMAT: 'Invalid mobile phone number format',
  ERR_REQUIRED_IS_MISSING: 'Mandatory fields missing',
  ERR_PASSWORDS_DONT_MATCH: "Entered passwords don't match",
  ERR_TEXT_IS_REQUIRED: 'Mandatory text',
  ERR_INVALID_IBAN_FORMAT: 'Entered IBAN is not correct.',
  ERR_IBAN_COLLECTION_ACCOUNT: 'Select payment type: to other SKPAY card.',
  ERR_IBAN_NOT_SEPA: 'Entered IBAN is not SEPA. Transfer will not be realized.',
  ERR_INVALID_NATIONALITY_FORMAT: 'Invalid format of nationality',
  ERR_INVALID_BIRTH_NUMBER_FORMAT: 'Invalid ID',
  ERR_INVALID_PIN_CODE: 'Invalid PIN. Please try again.',
  ERR_TOO_MANY_PIN_ATTEMPTS: 'Too many PIN code attempts. Please log in again.',
  ERR_INVITE_ALREADY_ACCEPTED: 'Invitation was already accepted.',
  SERVER_ERROR: 'Server error',
  // operation types
  OP_TYPE_ATM: 'ATM',
  OP_TYPE_POS: 'POS',
  OP_TYPE_CASHLESS: 'POS',
  OP_TYPE_VOICE: 'MO/TO',
  OP_TYPE_ECOMMERCE: 'Ecommerce',
  OP_TYPE_DEFAULT: 'Other',
  // pin lock
  pinLockTitleCreate: 'Create your PIN',
  pinLockSubtitleCreate: 'Select your PIN to access your account.',
  pinLockTitleValidationFailed: 'Entered PIN is easy to guess',
  pinLockTitleConfirm: 'Re-enter PIN',
  pinLockTitleConfirmFailed: "Entered PINs don't match ",
  pinLockTitleEnter: 'Enter PIN',
  pinLockTitleChange: 'Enter old PIN',
  scanYourFinger: 'Place your finger on the scanner.',
  scanFailedTryAgain: 'Scanning failed. Please try again.',
  scanFailedLockout: 'Too many attempts. Use your PIN.',
  youWillBeLogout: 'Press OK to log out.',
  allowBiometricsAlert:
    'Allow login by {biometricsType, select, fingerprint {fingerprint} face {face recognition}}?',
  biometryAttemptExpired:
    'Biometrics login is not available right now. Use your PIN.',
  youCanChangeThisInProfile:
    'You can change these settings later in your profile',
  // other
  footer: 'SKPAY, a. s., Nám. SNP 35, 811 01 Bratislava | All rights reserved',
  accounts: 'Accounts',
  yourAccounts: 'Your accounts',
  noAccounts: 'No accounts in your profile.',
  noCards: 'No cards in your profile.',
  cardNeedsActivation: 'Card is not activated',
  cardBeingProduced: 'Card is being produced',
  EUR: '{amount, number, EUR}',
  topUpAccount: 'Account top-up',
  accountDetail: 'Account detail',
  transactions: 'Transactions',
  recentTransactions: 'Recent transactions',
  filterTransactions: 'Filtered transactions',
  noTransactionsInSelectedFilter: 'No transactions in selected filter',
  loadingTransactions: 'Loading',
  errorLoadingTransactions: 'Loading error',
  transactionDetail: 'Transaction detail',
  transactionsType: 'Transaction type',
  from: 'From',
  to: 'To',
  filter: 'Filter',
  changeFilter: 'Edit filter',
  date: 'Date',
  showResults: 'Show results',
  resetFilter: 'Reset filter',
  notAllTransactionsShownSpecifyFilter:
    'Not all transactions are displayed. Please review the criteria.',
  reserved: 'Reserved',
  realized: 'Settled',
  seller: 'Merchant',
  detail: 'Detail',
  originalAmount: 'Original amount',
  conversionRate: 'Exchange rate',
  contactInformation: 'Contact information',
  changePassword: 'Change password',
  privacy: 'Privacy',
  changeAppPin: 'Change App PIN',
  signInViaBiometrics:
    'Login using {biometricsType, select, fingerprint {fingerprint} face {face recognition}}',
  aboutApp: 'About app',
  rateApp: 'Rate us on {os, select, ios {App Store} android {Google Play}}',
  version: 'Version',
  reportTransaction: 'Report the transaction',
  reportOfTransaction: 'Claim the transaction',
  reportOfTransactionDesc:
    "If you didn't make this transaction, please report it using this form.",
  yourEmail: 'Your email',
  optional: 'Optional',
  errorDesc: 'Error description',
  optionalEmailInfo:
    'Email is an optional field. If you give us your email, we may contact you for additional details.',
  documents: 'Documents',
  businessConditions: 'Terms & Conditions',
  fees: 'Pricelist',
  dataProtection: 'Data protection',
  termsOfUseAndPrivacyPolicy: 'Terms of use and privacy policy',
  launchTutorial: 'Launch tutorial',
  appLanguage: 'App language',
  changeAppLanguage: 'Change app language',
  appSettings: 'App settings',
  signOut: 'Log out',
  deactivate: 'Deactivate',
  myProfile: 'My profile',
  profile: 'Profile',
  accountManagement: 'Account settings',
  cardsManagement: 'My cards',
  bankStatements: 'Monthly statements',
  noBankStatements: 'No statements found',
  selectYearLabel: 'Year {year}',
  bankStatementLabel: `{month, select,
    0 {January}
    1 {February}
    2 {March}
    3 {April}
    4 {May}
    5 {June}
    6 {July}
    7 {August}
    8 {September}
    9 {October}
    10 {November}
    11 {December}
  }/{year}`,
  guestsManagement: 'Invited friends',
  usersWithAccessToYourAccount: 'Users with access to your account',
  accountSettings: 'Account settings',
  invitesWaiting: 'Pending invites',
  noGuests: 'No friends invited yet.',
  inviteGuest: 'Invite friend',
  inviteFormType: 'Select contact detail for invitation:',
  inviteFormDescriptionEmail: 'Invite will be sent to this email.',
  inviteFormDescriptionMobile:
    'Invite will be sent to this mobile phone number.',
  inviteFormNoticeTitle: 'New user will have right to:',
  inviteFormNoticeFirst: '- block your card',
  inviteFormNoticeSecond: '- browse your transactions',
  cardPanMasked: '•••• {pan}',
  cardLimitMaxValue: '{label} (Max.value: {maxValue})',
  max: 'Max {maxValue} €',
  cardExpiration: 'VALID THRU',
  addToMyCards: 'Add to my cards',
  setLimits: 'Limit settings',
  limits: 'Limits',
  atmWithdrawal: 'Cash withdrawal',
  atmWithdrawalDayLimit: 'ATM limit',
  allowATMWithdrawal: 'Enable ATM withdrawals',
  dayLimit: 'Daily limit',
  cashlessPayments: 'POS payment',
  cashlessPaymentsDayLimit: 'POS limit',
  allowCashlessPayments: 'Enable POS payments',
  contactlessPayments: 'Contactless',
  allowContactlessPayments: 'Enable contactless',
  internetPayments: 'ECOM payments',
  internetPaymentDayLimit: 'ECOM limit',
  allowInternetPayments: 'Enable ECOM payments',
  reprintPin: 'PIN reprint',
  reprintPinDescription:
    'If you forget your PIN, you can request to reprint it. The PIN will be sent to you by post in 5 business days.',
  reprintPinModalTitle: 'PIN reprint',
  reprintPinModalInfo: 'PIN will be sent to you by post in 5 business days.',
  reprintPinAddressLabel: 'PIN will be sent to your address:',
  reprintPinSubmitButton: 'PIN reprint',
  reissueCard: 'Card replacement',
  reissueCardDescription:
    'Your card will be blocked on issuing a replacement card.',
  reissueCardModalTitle: 'Card replacement',
  reissueCardModalAlert:
    'Warning, if you confirm this request, your old card will be blocked!',
  issueNewCard: 'Confirm card replacement',
  reissueCardAddressLabel: 'Card will be sent to your address:',
  reissueCardReasonLabel: 'Reason for card replacement',
  chooseReason: 'Select reason',
  choose: 'Select',
  lossOrTheft: 'Lost or stolen',
  damage: 'Damage',
  lostOrStolenCardInfo:
    'Select this option, if you lost your card, it was stolen or you cannot find it. New card and PIN will be sent to you by post in 5 business days.',
  damagedCardInfo:
    'Select this option, if your card is broken or chipped. New card and PIN will be sent to you by post in 5 business days.',
  requestMoney: 'Transfer funds',
  currency: 'Currency',
  requestMoneyChooseTypeTitle: 'Choose payment type',
  toOtherSkpayCard: 'To other SKPay card',
  toIBAN: 'To IBAN',
  requestMoneyDescription:
    'You can request funds transfer to your bank account here. Transfers are supported to SEPA countries only!',
  requestMoneyModalTitle: 'Request funds transfer',
  requestMoneyMadeOnline: 'The payment will be made online!!!',
  requestMoneyWithin2Days: 'The payment will be made within 2 working days!!!',
  requestMoneyNeedMobileNo:
    'If you want to make transfer, you need to register your mobile number.',
  requestMoneyConfirmation: 'Confirmation of request for funds transfer',
  submitRequestMoney: 'Submit',
  receiverIban: 'Receiver IBAN',
  placeholderIban: 'SK09 1287 1234 1245 9812 7654',
  receiverSkpayAccountNo: 'SKPAY Card account no.',
  placeholderReceiverSkpayAccountNo: 'VS 10-digit number',
  receiverName: 'Payee name',
  vsCode: 'Variable symbol',
  ssCode: 'Specific symbol',
  ksCode: 'Constant symbol',
  referenceForPayer: 'Reference for payer',
  paymentDate: 'Date',
  accountInformation: 'Client information',
  addresses: 'Addresses',
  myCard: 'My card',
  myCards: 'My cards',
  otherCards: 'Other cards',
  invitationSentOn:
    'Invitation sent on {invitationDate, date} at {invitationDate, time, short}',
  name: 'Name',
  firstName: 'First name',
  lastName: 'Last name',
  permanentAddress: 'Permanent address',
  correspondenceAddress: 'Correspondence address',
  availableBalance: 'Available balance',
  iban: 'IBAN of the Pool account',
  variableSymbol: 'Variable symbol',
  variableSymbolWithAbbreviation: 'Variable symbol (VS)',
  variableSymbolAbbreviation: 'VS',
  topupAccountDescription:
    "You can top-up your account by payment order to the Pool account. Don't forget to fill in the variable symbol in the field End-to-end identification of your payment order using the following syntax: /VS{variableSymbol}/SS/KS.",
  topupAccountTitle: 'Account top-up',
  sendInvitationAgain: 'Send again',
  saveChanges: 'Save',
  transactionsTabLabel: 'Transactions',
  contactInfoValidation: 'Contact detail validation',
  verificationCode: 'Verification code',
  submit: 'Submit',
  delete: 'Delete',
  cancel: 'Cancel',
  signUp: 'Sign up',
  signIn: 'Log in',
  signInLong: 'Do you already have an user account? Log in.',
  emailOrPhone: 'Email or mobile phone number',
  minimalLength8Symbols: 'Min. length 8 characters',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  forgotPinCode: 'Forgot PIN?',
  dontHaveAnAccountYet: "Don't have an account yet?",
  dontHaveCardYet: "Don't have an SKPAY card yet?",
  fillOnlineApplication: 'Just fill-in the online application',
  variableSymbolOfAccount: 'Variable symbol of your SKPAY account',
  tenDigitContractNumber: '10-digit contract number',
  forSignupIWantToUse:
    'Select your contact detail from your SKPAY contract you want to use for sign up:',
  email: 'Email',
  phone: 'Mobile phone',
  placeholderContactValue:
    '{contactType, select, email {meno@email.sk} phone {+421 903 123 456}}',
  enterPasswordForCheck: 'Enter password for check',
  repeatPassword: 'Repeat password',
  iAgreeToProcessingOfPersonalData: 'I accept ',
  iAgreeToProcessingOfPersonalDataLinkText:
    'personal data being processed by SKPAY, a. s.',
  resendCode: 'Resend code',
  codeResentSuccessfully: 'Code successfully resent',
  inviteResentSuccessfully: 'Invite successfully resent',
  signUpUsingDataFromYourContract:
    'Sign up using data from your SKPAY contract.',
  whereCanIFindTheData: 'Where can I find this data?',
  registrationTitle: 'Sign up for SKPAY app',
  loginTitle: 'Log in to SKPAY app',
  aboutAppTitle1: 'Welcome to SKPAY app',
  aboutAppText1: 'Manage your SKPAY prepaid card easily from your cell phone.',
  aboutAppTitle2: 'Control your spending',
  aboutAppText2:
    'Your funds are secured due to online change of limits and possibility to block the card.',
  aboutAppTitle3: 'Transactions overview',
  aboutAppText3: 'View your transactions for better control of your spending.',
  requestCardTitle: 'Get the SKPAY card',
  requestCardText1: 'Just visit our website:',
  requestCardText2: 'fill in the online application',
  requestCardText3: 'sign and upload the contract',
  requestCardText4: 'pay 15 € with a fee at any post office in Slovakia',
  requestCardText5:
    "If you don't postpone it, the card can be delivered within 3 business days.",
  backToMainPage: 'Back to main page',
  mainPageUrl: '/en',
  mobilePhoneNumber: 'Mobile phone number',
  VSHintTitle: 'Where can I find the variable symbol?',
  VSHintDescription:
    'Variable symbol is in your contract on the title page in the upper right corner.',
  registrationVSLabel: 'Variable symbol (VS - 10-digit number from your card)',
  account: 'Account',
  card: 'Card',
  cards: 'Cards',
  period: 'Period',
  filetype: '{extension, select, pdf {PDF} zip {ZIP}}',
  limitMustBeANumber: 'Limit must be a number',
  limitMustBeAWholeNumber: 'Limit must be a whole number',
  limitMustBeUpTo: 'Limit must be up to {maxFieldValue}',
  limitMustBePositive: 'Limit must be a positive number',
  mobileApp: 'SKPAY in your cell phone',
  mobileAppPromoText:
    'Carry the tool to manage your cards always in your pocket.',
  contactUs: 'Contact us',
  contactUsDescription:
    'If you have any comments, questions or ideas for improvement, just send us a message.',
  contactUsSuccess: 'Your message was sent',
  yourMessage: 'Your message',
  appStore: 'App Store',
  playStore: 'Play Store',
  editCorrAddressTitle: 'Edit correspondence address',
  editCorrAddressConfirmation: 'Confirm the correspondence address',
  streetName: 'Street and number',
  postalCode: 'ZIP code',
  city: 'City',
  unknownErrorTitle: 'Unknown error',
  unknownErrorDescription: 'Unknown error. Please try again later.',
  networkErrorTitle: 'Network error',
  networkErrorDescription:
    'Network error. Please check your internet connection.',
  reloadPage: 'Reload page',
  credit: 'Credit',
  debit: 'Debit',
  creditShort: 'Credit',
  debitShort: 'Debit',
  forgotPasswordTitle: 'Forgot password',
  forgotPasswordDescription:
    'Please enter your registered contact detail - email or mobile number, to reset your password.',
  contactUsedForRegistration: 'Contact detail',
  continue: 'Continue',
  pleaseEnterVerificationCode:
    'Please enter the verification code sent to your {contactType, select, email {email} phone {mobile number}}.',
  pleaseEnterVerificationCodeContactValue:
    'Please enter the verification code sent to your {contactType, select, email {email} phone {mobile number}} {contactValue}.',
  enterVerificationCode: 'Enter verification code',
  verificationCodeFromContact:
    'Verification code from {contactType, select, email {email} phone {SMS}}',
  identitySuccessfullyConfirmed:
    'Your identity successfully verified. You can create new password now.',
  yourPassword: 'Your password',
  signInWithNewPassword: 'Log in with new password',
  changePasswordConfirmation: 'Password change confirmation',
  oldPassword: 'Old password',
  newPassword: 'New password',
  newPasswordRepeat: 'Repeat new password',
  changeContact:
    'Edit {contactType, select, email {email} phone {mobile phone number}}',
  yourNewContact:
    '{contactType, select, email {Your new email} phone {Your new mobile phone number}}',
  changeContactConfirmation:
    'Confirmation of change - {contactType, select, email {email} phone {mobile phone number}}',
  noMyCard: 'You have no cards',
  confirmDialogLimit: 'Confirm change of limit?',
  confirmDialogBlocked:
    'Do you really want to {value, select, true {block} false {unblock}} the card?',
  confirmDialogDeleteInvite: 'Confirm delete of invitation?',
  unsupportedAppVersionTitle: 'Unsupported version of the app',
  unsupportedAppVersionDescription: 'Please update your app to newest version',
  amount: 'Amount',
  cardStatus: `{status, select,
      requested {Requested}
      generated {Generated}
      inProduction {In production}
      inactive {Inactive}
      active {Active}
      closed {Closed}
      expired {Expired}
      incorrectProduction {Incorrect production}
      adminSuspended {Blocked by issuer}
      blocked {Blocked}
      reissued {Reissued}
      other {}}`,
  toUnblockContactUsOn:
    'To unblock your card contact our hotline {supportPhone}',
  block: 'Block',
  unblock: 'Unblock',
  statusOfCard: 'Card status',
  cardProductDescription:
    '{cardProduct, select, maestro {Maestro} mastercard {Mastercard} other {Unknown}} prepaid card',
  mandatory: 'Mandatory',
  allowed: 'Allowed',
  notAllowed: 'Not allowed',
  requestAccepted: 'Your request was accepted',
  requestSentModalTitle: 'Request sent',
  close: 'Close',
  loadingData: 'Loading data',
  loadingCards: 'Loading cards',
  ibanCopiedSuccess: 'IBAN copied',
  vsCopiedSuccess: 'Variable symbol copied',
  yes: 'Yes',
  no: 'No',
  confirm: 'Confirm',
  privacyPolicy: 'Privacy Policy', // in english on purpose, so that google can see and read it
  noFileLoaded: 'No file uploaded',
  filesLoaded: 'Files uploaded: {filenames}',
  refresh: 'Refresh',
  fillYourDataToRequestCard:
    'Enter your data to complete the application for SKPAY prepaid card',
  intendedForConsumers:
    'Reserved for consumers. If you are not a consumer, please send us email to ',
  intendedForConsumersEmail: 'skpay@skpay.sk',
  fullName: 'First name, surname, title',
  fullNamePlaceholder: 'Ing. Ján Novák',
  nationality: 'Nationality',
  otherNationalityHelper:
    'Enter your nationality as 2-letter code, e.g. US (The United States of America), BR (Brazil)',
  alreadyHaveContract:
    'Do you already have the application printed and signed?',
  birthNumber: 'Your identification number',
  birthNumberPlaceholder: 'Identification number no slash',
  birthNumberHelper:
    'If you were born in any other country than SK/CZ, enter your date of birth instead of identification number, use the syntax RRMMDD, e.g. 13.&nbsp;04.&nbsp;1992&nbsp;&rarr;&nbsp;920413',
  identityDocument: 'Identity document',
  ID: 'ID card',
  IDPlaceholder: 'ID card number',
  passport: 'Passport',
  passportPlaceholder: 'Passport number',
  backToPreviousStep: 'Back to previous step',
  same: 'Same',
  different: 'Different',
  agreementDataProtectionText: 'I accept',
  agreementDataProtectionLink: 'personal data being processed',
  agreementBusinessConditionsText: 'I accept',
  agreementBusinessConditionsLink:
    'Terms and Conditions for SKPAY prepaid cards',
  agreementFeesText: 'I accept',
  agreementFeesLink: 'SKPAY pricelist',
  agreementDistanceContractInfoText: 'I accept',
  agreementDistanceContractInfoLink:
    'consumer information before concluding the contract remotely',
  printYourApplicationAndSend:
    'Print your application, sign it and upload its scan or picture here.',
  downloadApplication: 'Download application',
  loadSignedApplication: 'Upload signed application',
  loadIdDocument: 'Upload identity document',
  uploadIdTooltipMyself: 'Upload identification card. ',
  uploadIdTooltipTeenager: 'Upload identification card. ',
  uploadIdTooltipOther:
    'Upload your identification card together with the card of the card receiver. ',
  uploadIdOrPassportTooltip:
    'It needs to be ID card (both sides) or passport (page with personal details). ',
  applicationBeingSent:
    'Application is being sent. This may take for a while ...',
  applicationSentSuccessfully:
    'Application sent successfully. We will send you instructions by email after we process it. Thank you for your interest in SKPAY card.',
  countryName: `{countryCode, select,
      SK {Slovakia}
      CZ {Czechia}
      BE {Belgium}
      BG {Bulgaria}
      HR {Croatia}
      CY {Cyprus}
      DK {Denmark}
      EE {Estonia}
      FI {Finland}
      FR {France}
      GR {Greece}
      NL {Netherlands}
      IE {Ireland}
      LT {Lithuania}
      LV {Latvia}
      LU {Luxembourg}
      HU {Hungary}
      MT {Malta}
      DE {Germany}
      PL {Poland}
      PT {Portugal}
      AT {Austria}
      RO {Romania}
      SI {Slovenia}
      ES {Spain}
      SE {Sweden}
      IT {Italy}
      GB {Great Britain}
      UA {Ukraine}
      other {}
    }`,
  otherCountry: 'Other',
  showEpin: 'Show ePIN',
  showEpinTitle: 'ePIN for internet payments',
  showEpinInfoMessageTitle:
    'Select your ePIN to approve internet transactions with your SKPAY card',
  showEpinInfoMessageDescription:
    'On Mastercard ID Check webpage displayed during ecommerce payment, please fill-in one time password sent by SMS and your ePIN.',
  changeEpin: 'Change ePIN',
  epinCreateTitle: 'Create new ePIN',
  epinConfirmTitle: 'Repeat new ePIN',
  epinLockTitleConfirmFailed: "Entered ePINs don't match",
  epinChangeSuccessMessage: 'Change of ePIN was successful.',
  epinPlaceholder: 'Enter 4 digit  number',
  newEpin: 'New ePIN',
  newEpinConfirm: 'Repeat new ePIN',
  ePinIsNotActive: 'ePIN is not activated',
  activateEpin: 'Activate ePIN',
  showCardPin: 'Show PIN',
  cardPinAppPinTitle: 'Enter the mobile application PIN',
  cardPinAppPinDescription:
    "To view the PIN for the card, you need to enter the mobile application PIN",
  cardPinAppPinForgot: 'Forgot your mobile application PIN?',
  cardPinTitle: 'Your card PIN',
  cardPinDescription: "Don't share your PIN with anyone.",
  cardPinChange: 'Change PIN',
  cardPinClose: 'Close ({seconds} s)',
  cardPinCreateTitle: 'Create new PIN',
  cardPinConfirmTitle: 'Repeat new PIN',
  cardPinLockTitleConfirmFailed: "Entered PINs don't match",
  cardPinChangeSuccessMessage: 'Your PIN has been changed.',
  requestCardSwitchTitle: 'Choose who you are requesting a new SKPAY card for',
  requestCardForMyself: 'For myself',
  requestCardForTeenager: 'For teenager',
  requestCardForOther: 'For other person',
  cardType: 'Type of card',
  placeholderCardType: 'Choose your card',
  cardTypeHelperMaestro:
    'The Maestro card is intended for payments, especially in brick-and-mortar stores, and for cash withdrawals.',
  cardTypeHelperMasterCard:
    'With a MasterCard you can also pay online as well as in brick-and-mortar shops and withdraw cash.',
  postCard: 'Post card',
  placeholderPostCard: 'Do you have a post card?',
  promoCode: 'Promo code',
  iHavePostCard: 'I have a post card',
  iDontHavePostCard: 'I do not have a post card',
  vsOfPostCardWithAbbr: 'Variable symbol of Post card (VS)',
  placeholderVsOfPostCard: '10-digit number',
  correspondenceAddressInfo: 'Your card will be delivered to this address',
  fillTeenagerDataToRequestCard:
    "Enter teenager's data to complete the application for SKPAY prepaid card",
  teenagerFullName: 'First name and surname of teenager',
  otherPersonAgreementText:
    'I declare that I am in such a relationship with the above-mentioned person that allows me, in accordance with the legal regulations of the Slovak Republic, to provide SKPAY, a. s. contact details of this person.',
  teenagerRelationship: 'Relation to teenager',
  placeholderOtherRelationship: 'Additional relationship info',
  relationships: `{code, select,
    PARENT {Parent}
    GRANDPARENT {Grandparent}
    AUNT {Aunt}
    UNCLE {Uncle}
    other {Other}
  }`,
  fillOtherPersonsDataToRequestCard:
    "Enter card holder's data to complete the application for SKPAY prepaid card",
  otherPersonFullName: 'First name and surname of card holder',
  wrongPinCode: 'Wrong PIN code',
  securityUpdateNoticeMessage:
    'We have updated the app with new security features. Please reset your password before logging in. You can change your password on the following link: '
}
