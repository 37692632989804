import {map, sumBy} from 'lodash'
import React from 'react'
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components'

import {formatMessage} from '../../i18n'
import Button from './Button'
import Link from './Link'
import Text from './Text'

const Dropzone = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InvisibleInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  z-index: -1;
  opacity: 0;
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 2;
  pointer-events: none;
`

const SpacedText = styled(Text)`
  margin-top: 8px;
  margin-bottom: 8px;
`

const RemoveLink = styled(Link)`
  margin-left: 8px;
`

type FileInputProps = {
  label: string
  onChangeFiles: (files: File[]) => void
  selectedFiles: File[]
  maxSize?: number
}

const FileInput = ({
  label,
  onChangeFiles,
  selectedFiles,
  maxSize
}: FileInputProps) => {
  const handleAddFiles = (fileList: File[]) => {
    const files = Array.from([...selectedFiles, ...fileList])
    if (!files.length) return
    if (maxSize && sumBy(files, 'size') > maxSize) {
      // eslint-disable-next-line no-alert
      alert('Súbory sú príliš veľké') // good enough for now
      return
    }
    onChangeFiles && onChangeFiles(files)
  }

  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
    noClick: true,
    onDrop: handleAddFiles
  })

  const handleRemoveFiles = () => {
    onChangeFiles && onChangeFiles([])
  }

  return (
    <Dropzone {...getRootProps()}>
      {isDragActive && <Overlay />}
      <InvisibleInput {...getInputProps()} />
      <SpacedText type="secondary">
        {selectedFiles[0] ? (
          <>
            {formatMessage('filesLoaded', {
              filenames: map(selectedFiles, 'name').join(', ')
            })}{' '}
            <RemoveLink
              small
              onClick={handleRemoveFiles}
              style={{marginLeft: 8}}
            >
              {formatMessage('delete')}
            </RemoveLink>
          </>
        ) : (
          formatMessage('noFileLoaded')
        )}
      </SpacedText>
      <Button block visual="secondary" onClick={open}>
        {label}
      </Button>
    </Dropzone>
  )
}

export default FileInput
