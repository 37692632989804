import type {State} from './state'

import {get, set, unset, isFunction} from 'lodash'
import produce from 'immer'
import getInitialState from './state'

const getValueOnPath = (state, path) =>
  path && path.length ? get(state, path) : state

const setValueOnPath = (state, path, value) => {
  const oldValue = getValueOnPath(state, path)
  const newValue = isFunction(value) ? produce(oldValue, value) : value
  if (newValue === oldValue) return state

  return produce(state, obj => {
    if (!path || !path.length) return newValue
    newValue === undefined ? unset(obj, path) : set(obj, path, newValue)
  })
}

const actions = {
  LOGIN: state => setValueOnPath(state, ['auth', 'loginStatus'], true),
  SET_VALUE: (state, {path, value}) => setValueOnPath(state, path, value),
  SET_FORM_FIELD_VALUE: (state, {path, fieldName, fieldValue}) => {
    const form = getValueOnPath(state, path)
    const oldFieldValue = form.fields[fieldName]
    if (oldFieldValue === fieldValue) return state
    const newForm = {
      ...form,
      error: null,
      fields: {
        ...form.fields,
        [fieldName]: fieldValue
      }
    }
    return setValueOnPath(state, path, newForm)
  },
  DELETE_VALUE: (state, {path}) => setValueOnPath(state, path, undefined)
}

const rootReducer = (state: State = getInitialState(), action) => {
  if (action.type in actions) return actions[action.type](state, action)
  else return state
}

export default rootReducer
