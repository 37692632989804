import React, {Fragment, useState} from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../i18n'
import {Modal, Button, Alert, GroupItem, Text, InputField} from '../ui'
import {actions} from '../../actions/cards/epinForm'
import {ChevronRight, X} from 'react-feather'
import {NATIVE_APP_EPIN_LENGTH} from '../../constants'
import {range} from 'lodash'
import {useAppDispatch, useAppSelector} from '../../reduxHooks'
import {CardWithSettings} from '../../state'

const PinRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

const Padding = styled.div`
  margin-top: 24px;
`

type EpinProps = {
  card: CardWithSettings
}

const EpinModal = (props: EpinProps) => {
  const form = useAppSelector(state => state.forms.epinForm)
  const dispatch = useAppDispatch()

  const [error, setError] = useState<string | null>(null)
  const ePin = props.card.settings.ePin
  const hasFilledEpin = !!ePin && ePin.length === NATIVE_APP_EPIN_LENGTH
  return (
    <Modal
      data-cy="enter-new-epin-modal"
      isOpen={form.isModalOpen}
      onRequestClose={() => dispatch(actions.closeModal())}
      title={
        hasFilledEpin
          ? formatMessage('changeEpin')
          : formatMessage('activateEpin')
      }
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          if (
            !form.fields.pin ||
            !form.fields.confirmPin ||
            form.fields.pin.length !== NATIVE_APP_EPIN_LENGTH
          ) {
            setError('epinPlaceholder')
          } else if (form.fields.pin !== form.fields.confirmPin) {
            setError('epinLockTitleConfirmFailed')
          } else {
            setError(null)
            dispatch(actions.submit(props.card.id, form.fields.pin))
          }
        }}
      >
        <InputField
          data-cy="epin"
          type="password"
          withVisibilityToggle
          label={formatMessage('newEpin')}
          placeholder={formatMessage('epinPlaceholder')}
          value={form.fields.pin}
          onChangeText={text => dispatch(actions.setField('pin', text))}
          withoutSpacing
          maxLength={4}
        />
        <Padding>
          <InputField
            data-cy="epin-again"
            type="password"
            withVisibilityToggle
            label={formatMessage('newEpinConfirm')}
            placeholder={formatMessage('epinPlaceholder')}
            value={form.fields.confirmPin}
            onChangeText={text =>
              dispatch(actions.setField('confirmPin', text))
            }
            maxLength={4}
          />
        </Padding>
        {error && (
          <Alert data-cy={error} type="error">
            {formatMessage(error)}
          </Alert>
        )}
        {form.error && (
          <Alert data-cy={form.error} type="error">
            {formatMessage(form.error)}
          </Alert>
        )}
        <Padding>
          <Button
            visual="secondary"
            block
            data-cy="submit-new-epin"
            disabled={form.loading}
          >
            {hasFilledEpin
              ? formatMessage('changeEpin')
              : formatMessage('activateEpin')}
          </Button>
        </Padding>
      </form>
    </Modal>
  )
}

const EpinDetail = (props: EpinProps) => {
  const form = useAppSelector(state => state.forms.epinForm)
  const dispatch = useAppDispatch()

  const ePin = props.card.settings.ePin
  const hasFilledEpin = ePin && ePin.length === NATIVE_APP_EPIN_LENGTH
  return (
    <>
      {hasFilledEpin ? (
        <PinRow>
          {range(NATIVE_APP_EPIN_LENGTH).map((position: number) => (
            <Text
              type="title"
              style={{marginLeft: 20, marginRight: 20, marginBottom: 20}}
            >
              {ePin[position]}
            </Text>
          ))}
        </PinRow>
      ) : (
        <PinRow>
          <Text style={{marginBottom: 20}}>
            {formatMessage('ePinIsNotActive')}
          </Text>
        </PinRow>
      )}
      <Text type="secondary">
        {formatMessage('showEpinInfoMessageDescription')}
      </Text>
      <Button
        block
        data-cy="submit-change-epin"
        disabled={form.loading}
        onClick={e => {
          e.stopPropagation()
          dispatch(actions.openModal())
        }}
        style={{marginTop: 20}}
      >
        {hasFilledEpin
          ? formatMessage('changeEpin')
          : formatMessage('activateEpin')}
      </Button>
      <EpinModal {...props} />
    </>
  )
}

const Epin = ({card}: {card: CardWithSettings}) => {
  const [openDetail, setOpenDetail] = useState(false)
  return (
    <Fragment>
      {!openDetail && (
        <GroupItem
          data-cy="open-change-epin-modal"
          label={formatMessage('showEpinInfoMessageTitle')}
          value={formatMessage('showEpin')}
          reverseContent
          onClick={() => setOpenDetail(!openDetail)}
          action={<ChevronRight color="#5D7083" />}
        />
      )}
      {openDetail && (
        <>
          <GroupItem
            data-cy="open-change-epin-modal"
            label={formatMessage('showEpinInfoMessageTitle')}
            value={formatMessage('showEpin')}
            reverseContent
            action={
              <X
                color="#5D7083"
                onClick={() => setOpenDetail(!openDetail)}
                style={{cursor: 'pointer'}}
              />
            }
          >
            <EpinDetail card={card} />
          </GroupItem>
        </>
      )}
    </Fragment>
  )
}

export default Epin
