// @flow
import type {Thunk} from '../types/reduxTypes'

import * as Sentry from '@sentry/browser'

import {changeLocaleIfProvided, getCurrentLocale, setLocale} from '../i18n'
import {loginStatusSelector} from '../selectors'
import storage from '../utils/storage'
import {isWeb} from '../utils/utils'
import {apiRequest, loadWebAccessToken} from './apiRequest'
import {SET_VALUE} from './commonActions'
import handleApiError from './handleApiError'

type PullUserQueryParams = {
  omitCardSettings: boolean
}

export const pullConfig = (): Thunk<Promise<boolean>> => async dispatch => {
  try {
    window.config = await apiRequest('/frontend-config')
    return true
  } catch (e) {
    await dispatch(handleApiError(e))
    return false
  }
}

export const pullDanubePayPubKey = (): Thunk<Promise<string | undefined>> => async (dispatch, getState) => {
  try {
    const {rsaPubKeyDP} = getState()
    if (rsaPubKeyDP) return rsaPubKeyDP
    const responseBody = await apiRequest('/dp/rsa-public-key')
    dispatch(SET_VALUE(['rsaPubKeyDP'], responseBody.publicKey))
    return responseBody.publicKey
  } catch (e) {
    await dispatch(handleApiError(e))
    return undefined
  }
}

export const pullStorageData = (): Thunk<> => async (dispatch, getState) => {
  await storage.pullData()
  await changeLocaleIfProvided()
  const locale = storage.getItem('locale')
  if (locale) {
    if (locale !== getCurrentLocale()) setLocale(locale)
  } else {
    await storage.setItem('locale', getCurrentLocale())
  }

  if (isWeb()) {
    const isLoggedIn = await loadWebAccessToken()
    dispatch(SET_VALUE(['auth', 'loginStatus'], isLoggedIn))
  } else {
    dispatch(
      SET_VALUE(['auth', 'loginStatus'], !!storage.getItem('deviceToken'))
    )
  }
}

// option to omit card settings - skips 2 DP calls per card == may be much quicker
export const pullUserData = (params: ?PullUserQueryParams): Thunk<> => async (
  dispatch,
  getState
) => {
  // no-op if already loading
  // TODO: there may be cases where we want to load newer (or with cards) data
  // even if there is request in flight
  if (getState().userDataLoading) return
  try {
    dispatch(SET_VALUE(['userDataLoading'], true))
    const userData = await apiRequest('/client', {
      queryParams: {
        ...(params && params.omitCardSettings ? {omitCardSettings: '1'} : {})
      }
    })
    dispatch(SET_VALUE(['userDataLoading'], false))
    // in case user logged out in the meantime, do nothing
    if (!loginStatusSelector(getState())) return
    Sentry.configureScope(scope => {
      scope.setUser({
        id: userData.user.id,
        username: userData.user[userData.user.contactType]
      })
    })
    dispatch(SET_VALUE(null, {...getState(), ...userData}))
  } catch (e) {
    await dispatch(handleApiError(e))
    dispatch(SET_VALUE(['userDataLoading'], false))
  }
}
