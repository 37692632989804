// @flow
import type {Path} from '../types/reduxTypes'

// EVERYTHING DEPRECATED

export const SET_VALUE = (path: ?Path, value: any) => ({
  type: 'SET_VALUE',
  path,
  value
})

export const SET_FORM_FIELD_VALUE = (
  path: ?Path,
  fieldName: string,
  fieldValue: any
) => ({
  type: 'SET_FORM_FIELD_VALUE',
  path,
  fieldName,
  fieldValue
})
