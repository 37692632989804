type StorageData = {
  deviceToken?: string
  username?: string
  pin?: string
  allowBiometrics?: boolean
  locale?: string
}

let storageData: StorageData = {}
let _storageGetData: () => string | null | Promise<string | null>
let _storageSetData: (data: string) => void | Promise<void>

export const initStorage = (
  storageGetData: typeof _storageGetData,
  storageSetData: typeof _storageSetData
) => {
  _storageGetData = storageGetData
  _storageSetData = storageSetData
}

const pullData = async (): Promise<void> => {
  try {
    storageData = JSON.parse((await _storageGetData()) || '{}')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Pulling data from storage failed', e)
  }
}

type StorageDataKey = keyof StorageData

type SetItem = <K extends StorageDataKey>(
  key: K,
  value: StorageData[K]
) => Promise<void>
const setItem: SetItem = async (key, value) => {
  try {
    // $FlowFixMe
    storageData[key] = value
    const newData = JSON.stringify(storageData)
    await _storageSetData(newData)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Saving data to storage failed')
  }
}

type RemoveItem = <K extends StorageDataKey>(key: K) => Promise<void>
const removeItem: RemoveItem = key => setItem(key, undefined)

type GetItem = <K extends StorageDataKey>(key: K) => StorageData[K]
const getItem: GetItem = key => {
  return storageData ? storageData[key] : undefined
}

// Clear storage data, preserve locale
const clearStorage = async () => {
  try {
    storageData = {locale: storageData.locale}
    const newData = JSON.stringify(storageData)
    await _storageSetData(newData)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Clearing storage data failed')
  }
}

export default {
  pullData,
  setItem,
  removeItem,
  getItem,
  clearStorage
}
