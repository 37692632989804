// @flow
import type {ContextRouter} from 'react-router-dom'

import React from 'react'
import {useSelector} from 'react-redux'

import {accountClientSelector} from '../../selectors'
import {Group, GroupItem, SectionTitle} from '../ui'
import {formatMessage} from '../../i18n'
import CorrespondenceAddress from '../profile/CorrespondenceAddress'
import ContactDetail from '../profile/ContactDetail'

const AccountManagement = ({match}: ContextRouter) => {
  const accountId = match.params.accountId
  const accountClient = useSelector(state =>
    accountClientSelector(state, accountId || '')
  )

  if (!accountClient) return null

  return (
    <div style={{flex: 1}}>
      <SectionTitle>{formatMessage('accountInformation')}</SectionTitle>
      <Group>
        <GroupItem
          label={formatMessage('name')}
          value={accountClient.name}
          data-cy="name"
        />
      </Group>
      <SectionTitle>{formatMessage('contactInformation')}</SectionTitle>
      <Group>
        <ContactDetail client={accountClient} contactType="email" />
        <ContactDetail client={accountClient} contactType="phone" />
      </Group>
      <SectionTitle>{formatMessage('addresses')}</SectionTitle>
      <Group>
        <GroupItem
          label={formatMessage('permanentAddress')}
          value={accountClient.permanentAddress.joined}
          data-cy="permanent-address"
        />
        <CorrespondenceAddress client={accountClient} />
      </Group>
    </div>
  )
}

export default AccountManagement
