// @flow
import type {Element} from 'react'
import type {State} from './state'

import {connect} from 'react-redux'
import {compose, branch, lifecycle, renderComponent, withState} from 'recompose'

import {
  loginStatusSelector,
  userSelector,
  areUserAndCardSettingsNotLoaded,
  pinLockStatusSelector
} from './selectors'
import {pullConfig, pullStorageData, pullUserData} from './actions/pullData'

type WithDataFunctionParams = {
  loadingComponent: () => Element<any>
}

export const withConfig = (params: {
  loadingComponent: () => Element<any>,
  onConfigPulled: Function
}) => {
  return compose(
    connect(null, {pullConfig}),
    // NOTE just for being able to do rerender
    withState('isConfigPulled', 'setIsConfigPulled', false),
    branch(
      props => !props.isConfigPulled,
      compose(
        lifecycle({
          async componentDidMount() {
            const isConfigPulled =
              typeof window.config === 'object'
                ? true
                : await this.props.pullConfig()
            // still may be false if request failed
            if (isConfigPulled) {
              params.onConfigPulled()
              this.props.setIsConfigPulled(true)
            }
          }
        }),
        renderComponent(params.loadingComponent)
      )
    )
  )
}

export const withStorageData = (params: WithDataFunctionParams) => {
  return compose(
    connect((state: State) => ({loginStatus: loginStatusSelector(state)}), {
      pullStorageData
    }),
    branch(
      props => props.loginStatus === undefined,
      compose(
        lifecycle({
          componentDidMount() {
            this.props.pullStorageData()
          }
        }),
        renderComponent(params.loadingComponent)
      )
    )
  )
}

// omits card settings - screens needing those won't work
export const withUserDataOnly = (params: WithDataFunctionParams) => {
  return compose(
    connect(
      state => ({
        user: userSelector(state),
        pinLockStatus: pinLockStatusSelector(state)
      }),
      {pullUserData}
    ),
    branch(
      props => !props.user,
      compose(
        lifecycle({
          async componentDidMount() {
            if (
              !params ||
              !params.pinCodeComponent ||
              this.props.pinLockStatus === 'unlocked'
            ) {
              await this.props.pullUserData({omitCardSettings: true})
            }
          },
          async componentDidUpdate(prevProps, prevState) {
            if (
              !params ||
              !params.pinCodeComponent ||
              (prevProps.pinLockStatus === 'locked' &&
                this.props.pinLockStatus === 'unlocked')
            ) {
              await this.props.pullUserData({omitCardSettings: true})
            }
          }
        }),
        renderComponent(params.loadingComponent)
      )
    )
  )
}

export const withUserAndCardData = (params: ?WithDataFunctionParams) => {
  return compose(
    connect(
      state => {
        return {
          userAndCardSettingsNotLoaded: areUserAndCardSettingsNotLoaded(state),
          pinLockStatus: pinLockStatusSelector(state)
        }
      },
      {pullUserData}
    ),
    // branched only on renderComponent and not here, because of cypress issues
    // with switching out the wrapping lifecycle HOC when app is already loaded
    lifecycle({
      async componentDidMount() {
        if (!this.props.userAndCardSettingsNotLoaded) return
        if (
          !params ||
          !params.pinCodeComponent ||
          this.props.pinLockStatus === 'unlocked'
        ) {
          await this.props.pullUserData()
        }
      },
      async componentDidUpdate(prevProps, prevState) {
        if (!this.props.userAndCardSettingsNotLoaded) return
        if (
          !params ||
          !params.pinCodeComponent ||
          (prevProps.pinLockStatus === 'locked' &&
            this.props.pinLockStatus === 'unlocked')
        ) {
          await this.props.pullUserData()
        }
      }
    }),
    // if no renderComponent is supplied, do load on background
    branch(
      props =>
        props.userAndCardSettingsNotLoaded && params && params.loadingComponent,
      // passed in as a func, so that it is resolved only when the branch test passes
      renderComponent(() => params.loadingComponent())
    ),

    branch(
      props =>
        props.pinLockStatus === 'locked' && params && params.pinCodeComponent,
      // passed in as a func, so that it is resolved only when the branch test passes
      renderComponent(() => params.pinCodeComponent())
    )
  )
}
