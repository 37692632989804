export type User = {
  id: string
  clientId?: string
  contactType: 'email' | 'phone'
  email?: string
  phone?: string
}

export type Client = {
  id: string
  name: string
  firstName: string
  lastName: string
  email: string
  phone: string
  permanentAddress: {
    joined: string
    street: string
    zip: string
    city: string
  }
  correspondenceAddress: {
    joined: string
    street: string
    zip: string
    city: string
  }
}

export type CardSettingsBool = {
  favorite: boolean
  blocked: boolean
  allowATMWithdrawal: boolean
  allowCashlessPayments: boolean
  allowInternetPayments: boolean
  allowContactlessPayments: boolean
}

export type CardSettingsNumber = {
  atmWithdrawalDayLimit: number
  cashlessPaymentsDayLimit: number
  internetPaymentDayLimit: number
}

type CardSettingsString = {
  ePin?: string
}

export type CardSettings = CardSettingsBool &
  CardSettingsNumber &
  CardSettingsString

type CommonCardFields = {
  id: string
  accountId: string
  cardProduct: string
  cardOrganization: string
  pan?: string
  cardExpiration: string
  type: string
  status: string
  holder: string
}

type CardWithoutSettings = CommonCardFields & {
  openable: false
  settings: undefined
}

export type CardWithSettings = CommonCardFields & {
  openable: true
  status: string
  settings: CardSettings
  // this is all optional because server is not sending it and undefined is correct value
  // for not manipulating (editing) any setting field
  settingsEditingFieldName?: keyof CardSettingsNumber
  settingsEditingFieldValue?: string // this is number, but type is string
  settingsLoading?: keyof CardSettings
  settingsError?: {
    fieldName: keyof CardSettings
    errorText: string
    errorData: any
  }
}

export type Card = CardWithoutSettings | CardWithSettings

export type BankStatement = {
  id: string
  year: number
  month: number
  url: string
  extension: 'pdf' | 'zip'
}

export type Account = {
  id: string
  variableSymbol: string
  clientName: string
  currency: string
  balance: number
  clientId: string
  isMine: boolean
  bankStatements: Record<string, BankStatement>
}

export type CardAndAccountType = {
  card: Card
  account: Account
}

export type Transaction = {
  id: string
  accountId: string
  type?: 'CREDIT' | 'DEBIT'
  amount?: number
  description: string
  date: number
  isReservation: boolean
  seller?: string
  showSellerLabel: boolean
  detail?: string
  originalAmount?: number | null
  originalCurrency?: string
  conversionRate?: string
}

export type CodeResendRequestType =
  | {success: true}
  | {success: false; error: string}

export type InviteType = {
  id: string
  accountId: string
  email?: string
  phone?: string
  date: number
  accepted: boolean
}

export type FormStateType = {
  loading: boolean
  error?: string | null
}

export type UsernameType = 'email' | 'phone'

export type ReissueCardFormType = {
  fields: {
    reason: string
  }
  isModalOpen: boolean
  success: boolean
} & FormStateType

export const getInitialReissueCardForm = (): ReissueCardFormType => ({
  fields: {
    reason: 'none'
  },
  isModalOpen: false,
  success: false,
  loading: false,
  error: null
})

export type ReprintPinFormType = {
  isModalOpen: boolean
  success: boolean
} & FormStateType

export const getInitialReprintPinForm = (): ReprintPinFormType => ({
  isModalOpen: false,
  success: false,
  loading: false,
  error: null
})

export type EpinFormType = {
  isModalOpen: boolean
  success: boolean
  fields: {
    pin: string
    confirmPin: string
  }
  loading: boolean
} & FormStateType

export const getInitialEpinForm = (): EpinFormType => ({
  isModalOpen: false,
  success: false,
  fields: {
    pin: '',
    confirmPin: ''
  },
  loading: false,
  error: null
})

export type RequestMoneyModalType =
  | ''
  | 'ChoosePaymentType'
  | 'IbanPayment'
  | 'SkpayPayment'
  | 'EnterCodeIban'
  | 'EnterCodeSkpay'
  | 'RequestSent'
  | 'RequestSent'

export type RequestMoneyFormType = {
  fields: {
    receiverSkpayAccountNo: string
    iban: string
    amount: string
    receiverName: string
    code: string
    vsCode: string
    ssCode: string
    ksCode: string
  }
  openedModalType: RequestMoneyModalType
  codeResendRequest: CodeResendRequestType | null
} & FormStateType

export const getInitialRequestMoneyForm = (): RequestMoneyFormType => ({
  fields: {
    receiverSkpayAccountNo: '',
    iban: '',
    amount: '',
    receiverName: '',
    code: '',
    vsCode: '',
    ssCode: '',
    ksCode: ''
  },
  openedModalType: '',
  codeResendRequest: null,
  loading: false,
  error: null
})

export type ContactUsFormType = {
  fields: {
    email: string
    text: string
  }
  loading: boolean
  error: string | null
  showModal: boolean
}

export const getInitialContactUsFormState = (): ContactUsFormType => ({
  fields: {
    email: '',
    text: ''
  },
  loading: false,
  error: null,
  showModal: false
})

export type EditContactDetailFormType = {
  fields: {
    contactValue: string
    codeForCurrent: string
    codeForNew: string
  }
  data: {
    validatedContactValue: string
  }
  loading: boolean
  error: string | null
  editingContactType: 'email' | 'phone' | null
  editingFlowStep: 'EnterContactDetail' | 'EnterCodes' | null
  codeForCurrentResend: CodeResendRequestType | null
  codeForNewResend: CodeResendRequestType | null
}

export const getInitialEditContactDetailFormState = (): EditContactDetailFormType => ({
  fields: {
    contactValue: '',
    codeForCurrent: '',
    codeForNew: ''
  },
  data: {
    validatedContactValue: ''
  },
  loading: false,
  error: null,
  editingContactType: null,
  editingFlowStep: null,
  codeForCurrentResend: null,
  codeForNewResend: null
})

export type ChangePasswordFormType = {
  fields: {
    oldPassword: string
    newPassword: string
    newPasswordRepeat: string
  }
  loading: boolean
  error: string | null
}

export const getInitialChangePasswordForm = (): ChangePasswordFormType => ({
  fields: {
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: ''
  },
  loading: false,
  error: null
})

export type TransactionFilterFields = {
  credit: boolean
  debit: boolean
  amountFrom: number | null
  amountTo: number | null
  dateFrom: Date | null
  dateTo: Date | null
}

export type TransactionsData = {
  filterFields: TransactionFilterFields
  request: {
    requestId: string
    isDefaultFilter: boolean
    loading: boolean
    error: string | null
    success?: boolean
  }
  result?: {
    endReached: boolean
    filteredList: Transaction[]
  }
}

export type PinLockStatusType = 'locked' | 'unlocked' | 'change'

export type State = {
  auth: {
    loginStatus?: boolean | null
  }
  pinLock: {
    status: PinLockStatusType
  }
  user?: User | null
  clients: Record<string, Client>
  accounts: Record<string, Account>
  transactionsByAccount: {
    [accountId: string]: TransactionsData
  }
  cards: Record<string, Card>
  invites: Record<string, InviteType>
  forms: {
    reissueCardForm: ReissueCardFormType
    reprintPinForm: ReprintPinFormType
    requestMoneyForm: RequestMoneyFormType
    contactUsForm: ContactUsFormType
    editContactDetailForm: EditContactDetailFormType
    changePasswordForm: ChangePasswordFormType
    epinForm: EpinFormType
  }
  visibleGeneralError: null | 'UNKNOWN_ERROR' | 'NETWORK_ERROR'
  unsupportedAppVersionErrorVisible: boolean
  userDataLoading: boolean
  resetWholeApp: boolean
  rsaPubKeyDP?: string
}

const getInitialState = (): State => ({
  auth: {
    loginStatus: undefined
  },
  pinLock: {
    status: 'locked'
  },
  user: undefined,
  clients: {},
  accounts: {},
  transactionsByAccount: {},
  cards: {},
  invites: {},
  forms: {
    reissueCardForm: getInitialReissueCardForm(),
    reprintPinForm: getInitialReprintPinForm(),
    requestMoneyForm: getInitialRequestMoneyForm(),
    contactUsForm: getInitialContactUsFormState(),
    editContactDetailForm: getInitialEditContactDetailFormState(),
    epinForm: getInitialEpinForm(),
    changePasswordForm: getInitialChangePasswordForm()
  },
  visibleGeneralError: null,
  unsupportedAppVersionErrorVisible: false,
  userDataLoading: false,
  resetWholeApp: false,
  rsaPubKeyDP: undefined
})

export default getInitialState
