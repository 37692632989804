// @flow
import {useState, useMemo, useEffect} from 'react'
import {compact, mapValues} from 'lodash'
import {useSelector, useDispatch} from 'react-redux'

import {userSelector} from '../../selectors'
import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import * as validations from '../../utils/validations'
import {usePrevious} from '../../utils/hooks'
import {hashAuthData} from '../../utils/auth'

export type ChangePasswordModalProps = {
  isOpen: boolean,
  close: Function,
  continueFlow: Function
}

export const useChangePasswordForm = ({
  isOpen,
  continueFlow
}: ChangePasswordModalProps) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('')

  const fieldSetters = useMemo(
    () =>
      mapValues(
        {
          oldPassword: setOldPassword,
          newPassword: setNewPassword,
          newPasswordRepeat: setNewPasswordRepeat
        },
        fieldSetter => value => {
          setError(null)
          fieldSetter(value)
        }
      ),
    []
  )

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      setLoading(false)
      setError(null)
      setOldPassword('')
      setNewPassword('')
      setNewPasswordRepeat('')
    }
  }, [isOpen, prevIsOpen])

  const submit = async () => {
    if (loading) return

    const validationErrors = compact([
      validations.password(newPassword),
      newPassword !== newPasswordRepeat && 'ERR_PASSWORDS_DONT_MATCH'
    ])
    if (validationErrors.length) {
      setError(validationErrors[0])
      return
    }

    setLoading(true)
    try {
      await apiRequest('/change-pw/validate-pw', {
        method: 'POST',
        body: {
          oldPassword: hashAuthData(oldPassword),
          newPassword: hashAuthData(newPassword)
        }
      })
      // send code
      const user = dispatch((_, getState) => userSelector(getState()))
      await apiRequest('/send-verification-code-to-user', {
        method: 'POST',
        body: {contactType: user.contactType, reason: 'CHANGE_PASSWORD'}
      })
      continueFlow(oldPassword, newPassword)
    } catch (err) {
      setLoading(false)
      dispatch(
        handleApiError(err, [
          ['ERR_INVALID_PASSWORD', 'ERR_RATELIMIT_EXCEEDED'],
          errorCode => setError(errorCode)
        ])
      )
    }
  }

  return {
    loading,
    error,
    fields: {oldPassword, newPassword, newPasswordRepeat},
    fieldSetters,
    submit
  }
}

type OpenedModal = null | 'ChangePassword' | 'Code'

export const useChangePasswordFlow = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => userSelector(state))

  const [openedModal, setOpenedModal] = useState<OpenedModal>(null)

  const open = () => {
    if (openedModal) return
    setOpenedModal('ChangePassword')
  }

  const [oldPassword, setOldPassword] = useState(null)
  const [newPassword, setNewPassword] = useState(null)

  const changePasswordModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'ChangePassword',
      close: () => setOpenedModal(null),
      continueFlow: (oldPassword: string, newPassword: string) => {
        setOldPassword(oldPassword)
        setNewPassword(newPassword)
        setOpenedModal('Code')
      }
    }),
    [openedModal, setOpenedModal]
  )

  const codeModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'Code',
      close: () => setOpenedModal(null),
      contactType: user.contactType,
      resendCodeApiRequest: () =>
        apiRequest('/send-verification-code-to-user', {
          method: 'POST',
          body: {contactType: user.contactType, reason: 'CHANGE_PASSWORD'}
        }),
      validateCodeAndContinue: async (
        code: string,
        errorCallback: Function
      ) => {
        try {
          await apiRequest('/change-pw/validate-code', {
            method: 'POST',
            body: {
              oldPassword: hashAuthData(oldPassword),
              newPassword: hashAuthData(newPassword),
              code
            }
          })
          setOpenedModal(null)
        } catch (err) {
          dispatch(
            handleApiError(err, [
              [
                'ERR_EXPIRED',
                'ERR_INVALID_DATA',
                'ERR_CODE_ENTER_LIMIT_EXCEEDED'
              ],
              errorCode => errorCallback(errorCode)
            ])
          )
        }
      }
    }),
    [
      dispatch,
      newPassword,
      oldPassword,
      openedModal,
      setOpenedModal,
      user.contactType
    ]
  )

  return {open, changePasswordModalProps, codeModalProps}
}
