// @flow
import type {Node} from 'react'

import React from 'react'
import styled, {css} from 'styled-components'

import {formatMessage} from '../../i18n'
import {Logo, Link, Button, LanguageSwitch} from '../ui'
import Footer from '../Footer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: linear-gradient(90deg, #f3f5f6 47%, #fff 47%);
`

const FooterWrap = styled.div`
  margin-top: auto;
  border-top: 1px solid #dadcdd;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 43% 50%;
  grid-template-rows: 48px 1fr;
  grid-column-gap: 7%;
  grid-row-gap: 60px;
  align-items: start;
  justify-content: center;
  width: 100%;
  padding: 44px 16px;
  margin: 0 auto;
  ${({
    theme: {
      app: {sizes}
    }
  }) => css`
    max-width: ${sizes.maxWidth};
    min-width: ${sizes.minWidth};
  `};
`

const LeftNav = styled.div`
  display: flex;
  flex-direction: row;
`

const Nav = styled.nav`
  text-align: right;
`

const NavButton = styled(Button)`
  margin-left: 24px;
`

type Props = {
  children: Node,
  screen: 'login' | 'register' | 'requestCard'
}

const Layout = (props: Props) => {
  const loginButtonParams = {
    'data-cy': 'login-button',
    to: '/auth/login',
    message: 'signIn'
  }
  const registerButtonParams = {
    'data-cy': 'register-button',
    to: '/auth/registration',
    message: 'signUp'
  }
  const buttonParams = ['login', 'requestCard'].includes(props.screen)
    ? registerButtonParams
    : loginButtonParams
  return (
    <Container>
      <Content>
        <LeftNav>
          <Logo to="/" />
          <LanguageSwitch />
        </LeftNav>
        <Nav>
          <Link href={formatMessage('mainPageUrl')} sameWindow>
            {formatMessage('backToMainPage')}
          </Link>
          <Link data-cy={buttonParams['data-cy']} to={buttonParams.to}>
            <NavButton visual="ghost" size="small">
              {formatMessage(buttonParams.message)}
            </NavButton>
          </Link>
        </Nav>
        {props.children}
      </Content>
      <FooterWrap>
        <Footer />
      </FooterWrap>
    </Container>
  )
}

export default Layout
