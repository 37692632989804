// @flow
import type {Node} from 'react'
import type {InviteModalProps} from '../../../actions/accounts/guests/inviteForm'

import React from 'react'
import styled from 'styled-components'

import {
  useInviteFlow,
  useInviteForm
} from '../../../actions/accounts/guests/inviteForm'
import {formatMessage} from '../../../i18n'
import {Alert, Button, InputField, Modal, Text, ToggleButton} from '../../ui'

const StyledText = styled(Text)`
  display: block;
  margin-bottom: ${props => (props.bold ? '16px' : 0)};
`

const Notice = styled(Alert)`
  margin-bottom: 24px;
`

const InviteModal = (props: InviteModalProps) => {
  const {loading, error, fields, fieldSetters, submitInvite} = useInviteForm(
    props
  )
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.close}
      title={formatMessage('inviteGuest')}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          submitInvite()
        }}
      >
        <StyledText bold>{formatMessage('inviteFormType')}</StyledText>
        <ToggleButton
          items={[
            {
              title: formatMessage('email'),
              name: 'email',
              'data-cy': 'select-email'
            },
            {
              title: formatMessage('mobilePhoneNumber'),
              name: 'phone',
              'data-cy': 'select-phone'
            }
          ]}
          activeName={fields.usernameType}
          onClick={fieldSetters.usernameType}
        />
        {fields.usernameType === 'email' ? (
          <InputField
            data-cy="email"
            label={formatMessage('email')}
            placeholder={formatMessage('placeholderContactValue', {
              contactType: 'email'
            })}
            value={fields.username}
            onChangeText={fieldSetters.username}
          />
        ) : (
          <InputField
            data-cy="phone"
            label={formatMessage('mobilePhoneNumber')}
            placeholder={formatMessage('placeholderContactValue', {
              contactType: 'phone'
            })}
            value={fields.username}
            onChangeText={fieldSetters.username}
          />
        )}
        <Text type="secondary">
          {fields.usernameType === 'email'
            ? formatMessage('inviteFormDescriptionEmail')
            : formatMessage('inviteFormDescriptionMobile')}
        </Text>
        <Notice>
          <StyledText bold>{formatMessage('inviteFormNoticeTitle')}</StyledText>
          <StyledText type="secondary">
            {formatMessage('inviteFormNoticeFirst')}
          </StyledText>
          <StyledText type="secondary">
            {formatMessage('inviteFormNoticeSecond')}
          </StyledText>
        </Notice>
        <Button
          visual="secondary"
          data-cy="invite-send"
          block
          disabled={loading}
        >
          {formatMessage('inviteGuest')}
        </Button>
        {error && (
          <Alert data-cy={error} type="error">
            {formatMessage(error)}
          </Alert>
        )}
      </form>
    </Modal>
  )
}

const InviteBlock = (props: {
  accountId: string,
  render: (open: Function) => Node
}) => {
  const {open, inviteModalProps} = useInviteFlow(props.accountId)

  return (
    <>
      {props.render(open)}
      <InviteModal {...inviteModalProps} />
    </>
  )
}

export default InviteBlock
