// @flow

import {useState, useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import handleApiError from './handleApiError'
import {usePrevious} from '../utils/hooks'

export type CodeModalProps = {
  isOpen: boolean,
  contactType: string,
  close: Function,
  resendCodeApiRequest: Function,
  validateCodeAndContinue: Function
}

const useCodeModalForm = ({
  isOpen,
  contactType,
  close,
  resendCodeApiRequest,
  validateCodeAndContinue
}: CodeModalProps) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [code, setCode] = useState('')

  const [codeResendRequest, setCodeResendRequest] = useState(null)

  const wrapFieldSetter = fieldSetter => value => {
    setError(null)
    fieldSetter(value)
  }

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    // reset state after opening
    if (!prevIsOpen && isOpen) {
      setLoading(false)
      setError(null)
      setCode('')
      setCodeResendRequest(null)
    }
  }, [isOpen, prevIsOpen])

  const resendCode = async () => {
    setError(null)
    setCodeResendRequest(null)
    try {
      await resendCodeApiRequest()
      setCodeResendRequest({success: true})
    } catch (err) {
      await dispatch(
        handleApiError(err, [
          ['ERR_RATELIMIT_EXCEEDED'],
          errorCode =>
            setCodeResendRequest({
              success: false,
              error: errorCode
            })
        ])
      )
    }
  }

  const isMounted = useRef(true)
  useEffect(() => () => (isMounted.current = false), [])

  const submitCode = async () => {
    if (loading) return false
    setLoading(true)
    setError(null)
    setCodeResendRequest(null)
    await validateCodeAndContinue(code, setError)
    if (isMounted.current) setLoading(false)
  }

  return {
    loading,
    error,
    code,
    setCode: wrapFieldSetter(setCode),
    resendCode,
    codeResendRequest,
    submitCode
  }
}

export default useCodeModalForm
