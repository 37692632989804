import type {Transaction} from '../../../state'

import {useState, useMemo, useEffect} from 'react'
import {compact} from 'lodash'

import {accountSelector, emailSelector} from '../../../selectors'
import {apiRequest} from '../../apiRequest'
import handleApiError from '../../handleApiError'
import * as validations from '../../../utils/validations'
import {usePrevious} from '../../../utils/hooks'
import {useAppDispatch, useAppSelector} from '../../../reduxHooks'

export type ReportTransactionModalProps = {
  isOpen: boolean
  close: () => void
  transaction: Transaction
  continueFlow: () => void
}

export const useReportTransactionForm = ({
  isOpen,
  transaction,
  continueFlow
}: ReportTransactionModalProps) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [email, setEmail] = useState('')
  const [text, setText] = useState('')

  const defaultEmail = useAppSelector(state => emailSelector(state))
  const account = useAppSelector(state =>
    accountSelector(state, transaction.accountId)
  )

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      setLoading(false)
      setError(null)
      setEmail(defaultEmail)
      setText('')
    }
  }, [prevIsOpen, isOpen, defaultEmail])

  const wrapFieldSetter = fieldSetter => (value: string) => {
    setError(null)
    fieldSetter(value)
  }

  const submit = async () => {
    if (loading) return

    const validationErrors = compact([
      validations.email(email),
      !text && 'ERR_TEXT_IS_REQUIRED'
    ])
    if (validationErrors.length) {
      setError(validationErrors[0])
      return
    }
    setLoading(true)
    try {
      await apiRequest(
        `/accounts/${account.id}/transactions/${transaction.id}/report`,
        {
          method: 'POST',
          body: {
            email,
            text,
            vs: account.variableSymbol,
            transaction
          }
        }
      )
      continueFlow()
    } catch (err) {
      setLoading(false)
      await dispatch(handleApiError(err as Error))
    }
  }

  return {
    loading,
    error,
    fields: {email, text},
    fieldSetters: {
      email: wrapFieldSetter(setEmail),
      text: wrapFieldSetter(setText)
    },
    submit
  }
}

export const useReportTransactionFlow = (transaction: Transaction) => {
  const [reportTransactionModalOpen, setReportTransactionModalOpen] = useState(
    false
  )

  const open = () => setReportTransactionModalOpen(true)

  const reportTransactionModalProps = useMemo(
    () => ({
      isOpen: reportTransactionModalOpen,
      close: () => setReportTransactionModalOpen(false),
      transaction,
      continueFlow: () => setReportTransactionModalOpen(false)
    }),
    [reportTransactionModalOpen, transaction]
  )

  return {open, reportTransactionModalProps}
}
