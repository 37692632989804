import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {BrowserRouter, Redirect} from 'react-router-dom'
import App from './components/App'
import {initConfirmationDialog} from './confirmationDialog'
import {initContainers} from './containers/utils'
import {initHandleNotFound} from './handleNotFound'
import {setLocale} from './i18n'
import addIdleTimer from './idleTimer'
import './index.css'
import store from './store'
import {initEnvWeb} from './utils/env'
import {initPushToken} from './utils/pushToken'
import {initStorage} from './utils/storage'

// Always use fetch polyfill on Edge
// See bug: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/14320968/
// @ts-ignore next-line
if (window.navigator.userAgent.indexOf('Edge') > -1) window.fetch = undefined
require('whatwg-fetch')

initStorage(
  () => localStorage.getItem('data'),
  data => localStorage.setItem('data', data)
)
initEnvWeb()
window.platform = 'web'
initContainers(props => props.match.params)
// eslint-disable-next-line no-alert
initConfirmationDialog(
  title => new Promise(resolve => resolve(window.confirm(title)))
)
initHandleNotFound(() => <Redirect to="/404" />)
initPushToken(() => null)
addIdleTimer()

setLocale('sk')

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
// NOTE commented out because Cypress tests were not working in CircleCI
// registerServiceWorker()
