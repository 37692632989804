import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled.span`
  color: #5d7083;
  font-size: ${props => (props.small ? '14px' : '16px')};
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: none;

  &:hover {
    color: #3d5063;
  }
`

const StyledButton = styled(StyledLink).attrs({as: 'button'})``
const StyledRouterLink = styled(StyledLink).attrs({as: RouterLink})``
const StyledOutsideLink = styled(StyledLink).attrs({as: 'a'})``

const Link = ({to, onClick, href, sameWindow, ...props}) => {
  if (href) {
    return (
      <StyledOutsideLink
        href={href}
        rel="noopener noreferrer"
        target={sameWindow ? '_self' : '_blank'}
        {...props}
      />
    )
  }
  if (to) {
    return <StyledRouterLink to={to} {...props} />
  } else {
    return <StyledButton onClick={onClick} {...props} />
  }
}

export default Link
