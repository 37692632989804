export const isInactive = card => card.status === 'inactive'

export const isInProduction = card =>
  ['requested', 'generated', 'inProduction'].includes(card.status)

export const isDisplayable = card =>
  !isInProduction(card) && !['reissued', 'inactive'].includes(card.status)

export const isBlocked = card =>
  ['blocked', 'adminSuspended'].includes(card.status)
