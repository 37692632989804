// @flow
import type {ReissueCardProps} from '../../containers/cards/reissueCardContainer'

import React, {Fragment} from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../i18n'
import {
  Modal,
  Button,
  Alert,
  Group,
  GroupItem,
  Select,
  Text,
  CardLogo
} from '../ui'
import reissueCardContainer from '../../containers/cards/reissueCardContainer'
import CorrespondenceAddress from '../profile/CorrespondenceAddress'

const TypeLabel = styled(Text).attrs({type: 'secondary'})`
  position: absolute;
  right: 24px;
  bottom: 16px;
`

const RequestReissueCard = (props: ReissueCardProps) => (
  <Modal
    isOpen={props.isModalOpen}
    onRequestClose={props.closeModal}
    title={formatMessage('reissueCardModalTitle')}
    data-cy="reissue-card-modal"
  >
    <Group>
      <GroupItem
        data-card-id={props.card.id}
        reverseContent
        value={formatMessage('cardPanMasked', {
          pan: props.card.pan.slice(props.card.pan.length - 4)
        })}
        label={props.card.cardExpiration}
        beforeContent={
          <CardLogo
            cardProduct={props.card.cardProduct}
            cardOrganization={props.card.cardOrganization}
          />
        }
      >
        {props.card.cardOrganization === 'skpay' && (
          <TypeLabel>
            {formatMessage('cardProductDescription', {
              cardProduct: props.card.cardProduct
            })}
          </TypeLabel>
        )}
      </GroupItem>
    </Group>
    <Alert type="error">{formatMessage('reissueCardModalAlert')}</Alert>
    <Group spaced>
      <CorrespondenceAddress label={formatMessage('reissueCardAddressLabel')} />
    </Group>
    <Select
      label={formatMessage('reissueCardReasonLabel')}
      data-cy="reason"
      value={props.fields.reason}
      onChange={e => props.setReason(e.target.value)}
      options={[
        {value: 'none', label: formatMessage('chooseReason')},
        {value: 'loss', label: formatMessage('lossOrTheft')},
        {value: 'damage', label: formatMessage('damage')}
      ]}
    />

    {props.fields.reason !== 'none' && (
      <Alert type="info">
        {formatMessage(
          props.fields.reason === 'loss'
            ? 'lostOrStolenCardInfo'
            : 'damagedCardInfo'
        )}
      </Alert>
    )}
    {props.error && (
      <Alert data-cy={props.error} type="error">
        {formatMessage(props.error)}
      </Alert>
    )}
    {props.success ? (
      <>
        <Alert type="success">{formatMessage('requestAccepted')}</Alert>
        <Button
          block
          data-cy="request-sent-dismiss"
          disabled={props.loading}
          onClick={props.closeModal}
        >
          {formatMessage('close')}
        </Button>
      </>
    ) : (
      <Button
        block
        data-cy="submit-reissue-card"
        disabled={props.loading || props.fields.reason === 'none'}
        onClick={() => props.submit(props.card.id)}
      >
        {formatMessage('issueNewCard')}
      </Button>
    )}
  </Modal>
)

const ReissueCard = (props: ReissueCardProps) => (
  <Fragment>
    <props.Link openModal={props.openModal} />
    <RequestReissueCard {...props} />
  </Fragment>
)

export default reissueCardContainer(ReissueCard)
