// @flow
import type {HOC} from 'recompose'
import type {State} from '../../state'

import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {withRouter} from 'react-router-dom'

import {logout} from '../../actions/auth/logout'
import {formatMessage} from '../../i18n'
import NavLink from './NavLink'
import {Link, Logo, LanguageSwitch} from '../ui'
import {usernameSelector} from '../../selectors'

const StyledHeader = styled.header`
  height: 80px;
  flex-shrink: 0;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #f3f5f6;
  z-index: 10;
`

const Wrap = styled.div`
  max-width: 868px;
  min-width: 768px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  padding: 0 1rem;
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  margin-left: auto;
`

type HeaderProps = {
  logout: () => Promise<void>,
  singleAccount: boolean,
  singleCard: boolean,
  loadingUser: boolean,
  firstAccountId: string,
  username: string
}

const Header = ({
  singleAccount,
  firstAccountId,
  singleCard,
  loadingUser,
  username,
  logout
}: HeaderProps) => (
  <StyledHeader>
    <Wrap>
      <Logo to="/" />
      <LanguageSwitch />
      <Nav>
        {!loadingUser && (
          <>
            {singleAccount ? (
              <NavLink
                data-cy="header-account"
                to={`/accounts/${firstAccountId}`}
              >
                {formatMessage('account')}
              </NavLink>
            ) : (
              <NavLink data-cy="header-accounts" to="/accounts">
                {formatMessage('accounts')}
              </NavLink>
            )}
            {!singleAccount && (
              <NavLink data-cy="header-cards" to="/cards">
                {formatMessage(singleCard ? 'card' : 'cards')}
              </NavLink>
            )}
            <NavLink data-cy="header-profile" to="/profile">
              {singleAccount
                ? formatMessage('profile')
                : `${username} (${formatMessage('profile')})`}
            </NavLink>
          </>
        )}
        <Link onClick={logout}>{formatMessage('signOut')}</Link>
      </Nav>
    </Wrap>
  </StyledHeader>
)

const enhance: HOC<HeaderProps, *> = compose(
  // withRouter is needed to force rerender when route changes
  withRouter,
  connect(
    (state: State) => ({
      username: usernameSelector(state)
    }),
    {logout}
  )
)

export default enhance(Header)
