// @flow
import React from 'react'
import styled from 'styled-components'
import {
  Smartphone,
  Lock,
  Calendar,
  Edit,
  Upload,
  CreditCard
} from 'react-feather'

import {formatMessage} from '../../i18n'
import {Text, Paragraph} from '../ui'

const TextInParagraph = styled(Text)`
  font-size: 14px;
  line-height: 24px;
`

const Item = styled.div`
  display: flex;
`

const Icon = styled.div`
  margin-right: 24px;
  flex-shrink: 0;
  color: #002f5e;
  display: flex;
  flex-direction: column;
`

const About = ({screen}: {screen: 'login' | 'register' | 'requestCard'}) => {
  const aboutApplicationSections = [
    {
      title: formatMessage('aboutAppTitle1'),
      text: formatMessage('aboutAppText1'),
      icon: <Smartphone />
    },
    {
      title: formatMessage('aboutAppTitle2'),
      text: formatMessage('aboutAppText2'),
      icon: <Lock />
    },
    {
      title: formatMessage('aboutAppTitle3'),
      text: formatMessage('aboutAppText3'),
      icon: <Calendar />
    }
  ]

  const requestCardSections = [
    {
      text: formatMessage('requestCardText1')
    },
    {
      text: formatMessage('requestCardText2'),
      icon: <Edit />
    },
    {
      text: formatMessage('requestCardText3'),
      icon: <Upload />
    },
    {
      text: formatMessage('requestCardText4'),
      icon: <CreditCard />
    },
    {
      text: formatMessage('requestCardText5')
    }
  ]

  const screens = {
    login: {
      title: formatMessage('loginTitle'),
      sections: aboutApplicationSections
    },
    register: {
      title: formatMessage('registrationTitle'),
      sections: aboutApplicationSections
    },
    requestCard: {
      title: formatMessage('requestCardTitle'),
      sections: requestCardSections
    }
  }
  const data = screens[screen]

  return (
    <div>
      <Text type="title">{data.title}</Text>
      {data.sections.map((section, index) => (
        <Item key={index}>
          {section.icon && <Icon>{section.icon}</Icon>}
          <div>
            {section.title && <Text type="subheading">{section.title}</Text>}
            <Paragraph>
              <TextInParagraph>{section.text}</TextInParagraph>
            </Paragraph>
          </div>
        </Item>
      ))}
    </div>
  )
}

export default About
