// @flow

export const getNumber = (value: string) => {
  const formattedValue = value.replace(',', '.')
  if (isNaN(Number(formattedValue)))
    return {isValid: false, isEmpty: false, number: null}
  return {
    isValid: true,
    isEmpty: formattedValue.trim() === '',
    number: Number(formattedValue)
  }
}

export const isWeb = () => window.platform === 'web'
