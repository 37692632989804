// @flow
import type {ComponentType} from 'react'
import type {HOC} from 'recompose'

import React from 'react'
import {branch, renderComponent} from 'recompose'

let NotFoundComponent: ComponentType<{}>

export const initHandleNotFound = (Component: ComponentType<{}>) => {
  NotFoundComponent = Component
}

function handleNotFound<Props>(
  isNotFound: (props: Props) => boolean
): HOC<Props, Props> {
  return branch(
    isNotFound,
    renderComponent(() => <NotFoundComponent />)
  )
}

export default handleNotFound

export function handleShortNotFound<Props>(
  isNotFound: (props: Props) => boolean
): HOC<Props, Props> {
  return branch(
    isNotFound,
    renderComponent(() => null)
  )
}
