import styled from 'styled-components'

import Link from './Link'

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  align-self: center;
  color: #002f5e;
  background-image: url('/logo.svg');
  background-repeat: no-repeat;
  color: transparent;
  background-color: transparent;
  width: 99px;
  height: 48px;
  background-size: 100%;
  display: inline-block;

  &:hover {
    color: #002f5e;
    color: transparent;
  }
`

export default Logo
