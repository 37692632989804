// @flow
import type {UsernameModalProps} from '../../../../actions/auth/forgotPasswordActions'

import React from 'react'
import styled from 'styled-components'

import {useUsernameForm} from '../../../../actions/auth/forgotPasswordActions'
import {formatMessage} from '../../../../i18n'
import {Text, Modal, Paragraph, InputField, Alert, Button} from '../../../ui'

const Action = styled.div`
  margin-top: 24px;
`

const UsernameModal = (props: UsernameModalProps) => {
  const {
    loading,
    error,
    username,
    setUsername,
    submitUsernameAndContinue
  } = useUsernameForm(props)
  return (
    <Modal
      data-cy="enter-username-modal"
      isOpen={props.isOpen}
      onRequestClose={props.close}
      title={formatMessage('forgotPasswordTitle')}
      small
    >
      <Paragraph>
        <Text>{formatMessage('forgotPasswordDescription')}</Text>
      </Paragraph>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          submitUsernameAndContinue()
        }}
      >
        <InputField
          data-cy="forgot-pw-username"
          label={formatMessage('emailOrPhone')}
          placeholder={formatMessage('contactUsedForRegistration')}
          value={username}
          onChangeText={setUsername}
        />
        {error && (
          <Alert data-cy={error} type="error">
            {formatMessage(error)}
          </Alert>
        )}
        <Action>
          <Button
            block
            visual="secondary"
            data-cy="submit-username"
            disabled={loading}
          >
            {formatMessage('continue')}
          </Button>
        </Action>
      </form>
    </Modal>
  )
}

export default UsernameModal
