import styled, {css} from 'styled-components'

type CommonAlertProps = {
  type: 'info' | 'success' | 'warning' | 'error'
}

const Alert = styled.div<CommonAlertProps>`
  padding: 16px;
  border-radius: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;

  ${({
    type,
    theme: {
      alert: {colors}
    }
  }) => css`
    background-color: ${colors[type].background};
    color: ${colors[type].color};
  `};
`

Alert.defaultProps = {
  type: 'info'
}

export default Alert
