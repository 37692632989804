// @flow
import type {HOC} from 'recompose'

import {connect} from 'react-redux'
import {
  compose,
  hoistStatics,
  withState,
  withHandlers,
  withStateHandlers,
  withProps
} from 'recompose'
import {compact, toPath, cloneDeep, set} from 'lodash'
import {parsePhoneNumberFromString} from 'libphonenumber-js/max'
import {formatMessage} from '../../i18n'

import {apiRequest} from '../../actions/apiRequest'
import handleApiError from '../../actions/handleApiError'
import parseBirthNumber from '../../actions/auth/parseBirthNumber'
import * as validations from '../../utils/validations'

export const flowStepIds = {
  basicInfo: 1,
  documents: 2,
  addresses: 3,
  agreements: 4,
  downloadAndSend: 5,
  otherPersonBasicInfo: 6,
  otherPersonDocuments: 7,
  otherPersonAddresses: 8,
  successfullySent: 9
}

export const ApplicationType = {
  Myself: 'MYSELF',
  Teenager: 'TEENAGER',
  Other: 'OTHER'
}

export const flowStepsFor = applicationType => {
  switch (applicationType) {
    case ApplicationType.Teenager:
      return [
        flowStepIds.otherPersonBasicInfo,
        flowStepIds.otherPersonDocuments,
        flowStepIds.otherPersonAddresses,
        flowStepIds.basicInfo,
        flowStepIds.documents,
        flowStepIds.addresses,
        flowStepIds.agreements,
        flowStepIds.downloadAndSend,
        flowStepIds.successfullySent
      ]
    case ApplicationType.Other:
      return [
        flowStepIds.basicInfo,
        flowStepIds.documents,
        flowStepIds.addresses,
        flowStepIds.otherPersonBasicInfo,
        flowStepIds.otherPersonDocuments,
        flowStepIds.otherPersonAddresses,
        flowStepIds.agreements,
        flowStepIds.downloadAndSend,
        flowStepIds.successfullySent
      ]
    default:
      // else applicationType === ApplicationType.Myself
      return [
        flowStepIds.basicInfo,
        flowStepIds.documents,
        flowStepIds.addresses,
        flowStepIds.agreements,
        flowStepIds.downloadAndSend,
        flowStepIds.successfullySent
      ]
  }
}

const downloadAndSendStepIndex = applicationType =>
  flowStepsFor(applicationType).indexOf(flowStepIds.downloadAndSend)

export type Address = {
  street: string,
  zip: string,
  city: string
}

export type Addresses = {
  permanentAddress: Address,
  correspondenceAddress: Address & {sameAsPermanent: boolean}
}

type BasicInfo = {
  name: string,
  nationalitySelect: string,
  nationality: string,
  email: string,
  phone: string
}

type Documents = {
  birthNumber: string,
  identityDocument: {
    type: string,
    number: string
  }
}

export type RequestCardProps = {
  applicationType: string, // MYSELF, TEENAGER, OTHER
  flowStepIndex: number,
  formSkipped: boolean,
  error: string,
  note: string,
  // flow step basicInfo
  basicInfo: BasicInfo & {
    cardType: string,
    hasPostCard: string,
    vsOfPostCard: string,
    promoCode: string,
    relationshipSelect: string,
    relationship: string
  },
  otherPersonBasicInfo: BasicInfo & {
    agreement: boolean
  },
  // flow step documents
  documents: Documents,
  otherPersonDocuments: Documents & {
    teenagerHasId: boolean
  },
  // flow step addresses
  addresses: Addresses,
  otherPersonAddresses: Addresses,
  // flow step agreements
  dataProtectionAgreement: boolean,
  businessConditionsAgreement: boolean,
  feesAgreement: boolean,
  distanceContractInfoAgreement: boolean,
  // flow step downloadAndSend
  contractFields: any,
  signedContractFiles: File[],
  idDocumentFiles: File[],
  sendingContract: boolean,
  // handlers
  setField: Function,
  handleBirthNumberChange: Function,
  fieldSetter: Function,
  checkboxSetter: Function,
  handleContinue: Function,
  handleGoBack: Function,
  handleSkipForm: Function
}

// #region VALIDATIONS
// TODO Share same validations in BasicInfo, Documents and Addresses
const validationErrorsGetters: {
  [number]: (props: RequestCardProps) => string[]
} = {
  [flowStepIds.basicInfo]: props => [
    (!props.basicInfo.name ||
      !props.basicInfo.nationality ||
      !props.basicInfo.email ||
      !props.basicInfo.phone ||
      !props.basicInfo.cardType ||
      !props.basicInfo.hasPostCard ||
      (props.basicInfo.hasPostCard === 'true' &&
        !props.basicInfo.vsOfPostCard) ||
      (props.applicationType === ApplicationType.Teenager &&
        (!props.basicInfo.relationshipSelect ||
          (props.basicInfo.relationshipSelect === 'OTHER' &&
            !props.basicInfo.relationship)))) &&
      'ERR_REQUIRED_IS_MISSING',
    validations.nationality(props.basicInfo.nationality),
    validations.email(props.basicInfo.email),
    validations.phone(props.basicInfo.phone),
    props.basicInfo.hasPostCard === 'true' &&
      props.basicInfo.vsOfPostCard &&
      validations.variableSymbol(props.basicInfo.vsOfPostCard)
  ],

  [flowStepIds.otherPersonBasicInfo]: props => [
    (!props.otherPersonBasicInfo.name ||
      !props.otherPersonBasicInfo.nationality ||
      !props.otherPersonBasicInfo.email ||
      !props.otherPersonBasicInfo.phone) &&
      'ERR_REQUIRED_IS_MISSING',
    validations.nationality(props.otherPersonBasicInfo.nationality),
    validations.email(props.otherPersonBasicInfo.email),
    validations.phone(props.otherPersonBasicInfo.phone)
  ],

  [flowStepIds.documents]: props => [
    (!props.documents.birthNumber ||
      !props.documents.identityDocument.type ||
      !props.documents.identityDocument.number) &&
      'ERR_REQUIRED_IS_MISSING',
    // very loose validation for birthNumber and none for identityDocument because
    // it wouldn't add much benefit
    !props.documents.birthNumber.match(/^\d{6}(\d{3,4})?$/) &&
      'ERR_INVALID_BIRTH_NUMBER_FORMAT'
  ],

  [flowStepIds.otherPersonDocuments]: props => [
    (!props.otherPersonDocuments.birthNumber ||
      ((props.applicationType !== ApplicationType.Teenager ||
        props.otherPersonDocuments.teenagerHasId) &&
        (!props.otherPersonDocuments.identityDocument.type ||
          !props.otherPersonDocuments.identityDocument.number))) &&
      'ERR_REQUIRED_IS_MISSING',
    // very loose validation for birthNumber and none for identityDocument because
    // it wouldn't add much benefit
    !props.otherPersonDocuments.birthNumber.match(/^\d{6}(\d{3,4})?$/) &&
      'ERR_INVALID_BIRTH_NUMBER_FORMAT'
  ],

  [flowStepIds.addresses]: props => {
    const pAddr = props.addresses.permanentAddress
    const cAddr = props.addresses.correspondenceAddress
    return [
      (!pAddr.street || !pAddr.zip || !pAddr.city) && 'ERR_REQUIRED_IS_MISSING',
      cAddr.sameAsPermanent === false &&
        (!cAddr.street || !cAddr.zip || !cAddr.city) &&
        'ERR_REQUIRED_IS_MISSING'
    ]
  },

  [flowStepIds.otherPersonAddresses]: props => {
    const pAddr = props.otherPersonAddresses.permanentAddress
    const cAddr = props.otherPersonAddresses.correspondenceAddress
    return [
      (!pAddr.street || !pAddr.zip || !pAddr.city) && 'ERR_REQUIRED_IS_MISSING',
      cAddr.sameAsPermanent === false &&
        (!cAddr.street || !cAddr.zip || !cAddr.city) &&
        'ERR_REQUIRED_IS_MISSING'
    ]
  },

  [flowStepIds.agreements]: props => [
    (!props.dataProtectionAgreement ||
      !props.businessConditionsAgreement ||
      !props.feesAgreement ||
      !props.distanceContractInfoAgreement) &&
      'ERR_REQUIRED_IS_MISSING'
  ],

  [flowStepIds.downloadAndSend]: props => [
    !props.signedContractFiles.length && 'ERR_REQUIRED_IS_MISSING',
    !props.idDocumentFiles.length && 'ERR_REQUIRED_IS_MISSING'
  ]
}

const getValidationErrors = (flowStepId: number, props: RequestCardProps) => {
  const getErrors = validationErrorsGetters[flowStepId] || (() => [])
  return compact(getErrors(props))
}

// #endregion VALIDATIONS

// local functions for contract formatting
const prepareBirthNumber = (birthNumber: string) => {
  return `${birthNumber.slice(0, 6)}/${birthNumber.slice(6) || '0000'}`
}

const prepareIdentityDocument = (type: string, number: string) => {
  return type && number ? `${type === 'ID' ? 'OP' : 'PAS'} ${number}` : ''
}
const parseAddress = (addr: Address) =>
  `${addr.street}, ${addr.zip} ${addr.city}`

const myselfContractFields = props => {
  const phone = parsePhoneNumberFromString(props.basicInfo.phone, 'SK').format(
    'INTERNATIONAL'
  )
  const birthNumber = prepareBirthNumber(props.documents.birthNumber)
  const identityDocument = prepareIdentityDocument(
    props.documents.identityDocument.type,
    props.documents.identityDocument.number
  )

  const permanentAddress = parseAddress(props.addresses.permanentAddress)
  const correspondenceAddress = props.addresses.correspondenceAddress
    .sameAsPermanent
    ? permanentAddress
    : parseAddress(props.addresses.correspondenceAddress)

  const contractFields = {
    name: props.basicInfo.name,
    nationality: props.basicInfo.nationality,
    email: props.basicInfo.email,
    cardType: props.basicInfo.cardType,
    promoCode: props.basicInfo.promoCode,
    note: props.note,
    phone,
    birthNumber,
    identityDocument,
    permanentAddress,
    correspondenceAddress
  }
  return {contractFields}
}

const teenagerContractFields = props => {
  const phone = parsePhoneNumberFromString(props.basicInfo.phone, 'SK').format(
    'INTERNATIONAL'
  )
  const teenagerPhone = parsePhoneNumberFromString(
    props.otherPersonBasicInfo.phone,
    'SK'
  ).format('INTERNATIONAL')
  const birthNumber = prepareBirthNumber(props.documents.birthNumber)
  const teenagerBirthNumber = prepareBirthNumber(
    props.otherPersonDocuments.birthNumber
  )
  const identityDocument = prepareIdentityDocument(
    props.documents.identityDocument.type,
    props.documents.identityDocument.number
  )
  const teenagerIdentityDocument = prepareIdentityDocument(
    props.otherPersonDocuments.identityDocument.type,
    props.otherPersonDocuments.identityDocument.number
  )

  const permanentAddress = parseAddress(props.addresses.permanentAddress)
  const teenagerPermanentAddress = parseAddress(
    props.otherPersonAddresses.permanentAddress
  )
  const correspondenceAddress = props.addresses.correspondenceAddress
    .sameAsPermanent
    ? permanentAddress
    : parseAddress(props.addresses.correspondenceAddress)
  const teenagerCorrespondenceAddress = props.otherPersonAddresses
    .correspondenceAddress.sameAsPermanent
    ? permanentAddress
    : parseAddress(props.otherPersonAddresses.correspondenceAddress)

  const contractFields = {
    teenager: {
      name: props.otherPersonBasicInfo.name,
      nationality: props.otherPersonBasicInfo.nationality,
      permanentAddress: teenagerPermanentAddress,
      identityDocument: teenagerIdentityDocument || 'Nemá',
      birthNumber: teenagerBirthNumber,
      email: props.otherPersonBasicInfo.email,
      phone: teenagerPhone,
      correspondenceAddress: teenagerCorrespondenceAddress
    },
    representant: {
      name: props.basicInfo.name,
      nationality: props.basicInfo.nationality,
      identityDocument,
      birthNumber,
      email: props.basicInfo.email,
      phone,
      permanentAddress,
      correspondenceAddress,
      relationship: formatMessage('relationships', {
        code: props.basicInfo.relationship
      })
    },
    cardType: props.basicInfo.cardType,
    promoCode: props.basicInfo.promoCode,
    note: props.note
  }
  return {contractFields}
}

const otherPersonContractFields = props => {
  const phone = parsePhoneNumberFromString(props.basicInfo.phone, 'SK').format(
    'INTERNATIONAL'
  )
  const otherPersonPhone = parsePhoneNumberFromString(
    props.otherPersonBasicInfo.phone,
    'SK'
  ).format('INTERNATIONAL')
  const birthNumber = prepareBirthNumber(props.documents.birthNumber)
  const otherPersonBirthNumber = prepareBirthNumber(
    props.otherPersonDocuments.birthNumber
  )
  const identityDocument = prepareIdentityDocument(
    props.documents.identityDocument.type,
    props.documents.identityDocument.number
  )
  const otherPersonIdentityDocument = prepareIdentityDocument(
    props.otherPersonDocuments.identityDocument.type,
    props.otherPersonDocuments.identityDocument.number
  )

  const permanentAddress = parseAddress(props.addresses.permanentAddress)
  const otherPersonPermanentAddress = parseAddress(
    props.otherPersonAddresses.permanentAddress
  )
  const correspondenceAddress = props.addresses.correspondenceAddress
    .sameAsPermanent
    ? permanentAddress
    : parseAddress(props.addresses.correspondenceAddress)
  const otherPersonCorrespondenceAddress = props.otherPersonAddresses
    .correspondenceAddress.sameAsPermanent
    ? permanentAddress
    : parseAddress(props.otherPersonAddresses.correspondenceAddress)

  const contractFields = {
    orderer: {
      name: props.basicInfo.name,
      nationality: props.basicInfo.nationality,
      permanentAddress,
      identityDocument,
      birthNumber,
      email: props.basicInfo.email,
      phone,
      correspondenceAddress
    },
    holder: {
      name: props.otherPersonBasicInfo.name,
      nationality: props.otherPersonBasicInfo.nationality,
      permanentAddress: otherPersonPermanentAddress,
      identityDocument: otherPersonIdentityDocument,
      birthNumber: otherPersonBirthNumber,
      email: props.otherPersonBasicInfo.email,
      phone: otherPersonPhone,
      correspondenceAddress: otherPersonCorrespondenceAddress
    },
    cardType: props.basicInfo.cardType,
    promoCode: props.basicInfo.promoCode,
    note: props.note
  }
  return {contractFields}
}

const withContractFields = withProps((props: RequestCardProps) => {
  if (
    props.flowStepIndex !== downloadAndSendStepIndex(props.applicationType) ||
    props.formSkipped
  )
    return {}

  if (props.applicationType === ApplicationType.Myself)
    return myselfContractFields(props)
  else if (props.applicationType === ApplicationType.Teenager)
    return teenagerContractFields(props)
  else return otherPersonContractFields(props)
})

const container: HOC<RequestCardProps, {}> = compose(
  withState('applicationType', 'setApplicationType', null),
  withState('flowStepIndex', 'setFlowStepIndex', 0),
  withState('formSkipped', 'setFormSkipped', false),
  withState('error', 'setError', null),
  withState('sendingContract', 'setSendingContract', false),
  withStateHandlers(
    {
      note: '',
      cardType: 'MasterCard',
      // flow step basicInfo
      basicInfo: {
        name: '',
        nationalitySelect: '',
        nationality: '',
        email: '',
        phone: '',
        vsOfPostCard: '',
        promoCode: '',
        relationshipSelect: '',
        relationship: '',
        cardType: 'MasterCard'
      },
      otherPersonBasicInfo: {
        name: '',
        nationalitySelect: '',
        nationality: '',
        email: '',
        phone: '',
        agreement: false,
        cardType: 'MasterCard'
      },
      // flow step documents
      documents: {
        birthNumber: '',
        identityDocument: {
          type: 'ID',
          number: ''
        }
      },
      otherPersonDocuments: {
        birthNumber: '',
        teenagerHasId: false,
        identityDocument: {
          type: 'ID',
          number: ''
        }
      },
      // flow step addresses
      addresses: {
        permanentAddress: {
          street: '',
          zip: '',
          city: ''
        },
        correspondenceAddress: {
          sameAsPermanent: true,
          street: '',
          zip: '',
          city: ''
        }
      },
      otherPersonAddresses: {
        permanentAddress: {
          street: '',
          zip: '',
          city: ''
        },
        correspondenceAddress: {
          sameAsPermanent: true,
          street: '',
          zip: '',
          city: ''
        }
      },
      // flow step agreements
      dataProtectionAgreement: false,
      businessConditionsAgreement: false,
      feesAgreement: false,
      distanceContractInfoAgreement: false,
      // flow step downloadAndSend
      signedContractFiles: [],
      idDocumentFiles: []
    },
    {
      setField: (state, props) => (rawPath, value) => {
        props.setError(null)
        const path = toPath(rawPath) // path to array
        const [fieldSource, ...pathRest] = path
        const fieldName = pathRest[0]
        const fieldValue = pathRest.length
          ? set(cloneDeep(state[fieldSource]), pathRest, value)
          : value

        const fieldChanges = cloneDeep(state)
        fieldChanges[fieldSource] = fieldValue

        // reset identityDocument number when changing type
        if (fieldName === 'identityDocument' && pathRest[1] === 'type') {
          set(fieldValue, 'number', '')
        }

        if (fieldName === 'nationalitySelect') {
          if (value === 'OTHER') {
            // reset nationality after choosing OTHER in select
            fieldChanges[fieldSource].nationality = ''
          } else {
            // copy value from nationalitySelect to nationality after choosing a country in select
            fieldChanges[fieldSource].nationality = (value: string)
          }
        }

        if (fieldName === 'hasPostCard' && value === 'false') {
          // reset vsOfPostCard after choosing no in select
          fieldChanges[fieldSource].vsOfPostCard = ''
        }

        if (fieldName === 'vsOfPostCard') {
          fieldChanges.note = value ? `Mám Poštovú kartu s VS ${value}` : ''
        }

        if (fieldName === 'otherPersonNationalitySelect') {
          if (value === 'OTHER') {
            // reset nationality after choosing OTHER in select
            fieldChanges[fieldSource].otherPersonNationality = ''
          } else {
            // copy value from nationalitySelect to nationality after choosing a country in select
            fieldChanges[fieldSource].otherPersonNationality = (value: string)
          }
        }

        if (fieldName === 'relationshipSelect') {
          if (value === 'OTHER') {
            // reset nationality after choosing OTHER in select
            fieldChanges[fieldSource].relationship = ''
          } else {
            // copy value from nationalitySelect to nationality after choosing a country in select
            fieldChanges[fieldSource].relationship = (value: string)
          }
        }

        return {...fieldChanges}
      },
      handleBirthNumberChange: (state, props) => (rawPath, value) => {
        if (value.match(/^\d{6}(\d{3,4})?$/)) {
          const parsed = parseBirthNumber(value)
          // Birth date + 15 years + 2 months
          const shiftedBirthDate = cloneDeep(parsed.birthDate)
          shiftedBirthDate.setYear(parsed.birthDate.getFullYear() + 15)
          shiftedBirthDate.setMonth(parsed.birthDate.getMonth() + 2)
          // person over 15 + 2 months
          state.otherPersonDocuments.teenagerHasId =
            shiftedBirthDate <= Date.now()
        }
      }
    }
  ),
  withHandlers({
    fieldSetter: props => path => value => props.setField(path, value),
    checkboxSetter: props => path => ({target: {checked}}) =>
      props.setField(path, checked),
    onBirthNumberChange: props => path => value => {
      props.setField(path, value)
      const flowStepId = flowStepsFor(props.applicationType)[
        props.flowStepIndex
      ]
      if (
        props.applicationType === ApplicationType.Teenager &&
        flowStepId === flowStepIds.otherPersonDocuments
      ) {
        props.handleBirthNumberChange(path, value)
      }
    }
  }),
  // generate contract fields
  withContractFields,
  // handlers
  connect(
    null,
    {handleApiError}
  ),
  withHandlers({
    handleSendFile: props => async () => {
      props.setSendingContract(true)
      try {
        await apiRequest('/send-signed-contract', {
          method: 'POST',
          formFields: {
            signedContractFiles: props.signedContractFiles,
            idDocumentFiles: props.idDocumentFiles
          }
        })
        props.setFlowStepIndex(props.flowStepIndex + 1)
      } catch (e) {
        await props.handleApiError(e)
      }
      props.setSendingContract(false)
    }
  }),
  withHandlers({
    handleContinue: props => () => {
      props.setError(null)
      const flowStepsForApplication = flowStepsFor(props.applicationType)
      const flowStepId = flowStepsForApplication[props.flowStepIndex]
      const validationErrors = getValidationErrors(flowStepId, props)
      if (validationErrors.length) {
        props.setError(validationErrors[0])
        return
      }
      if (
        props.flowStepIndex !== downloadAndSendStepIndex(props.applicationType)
      ) {
        props.setFlowStepIndex(props.flowStepIndex + 1)
      } else {
        props.handleSendFile()
      }
    },
    handleGoBack: props => () => {
      props.setError(null)
      if (props.flowStepIndex === 0) {
        //Back from the first application step = back to application type
        props.setApplicationType(null)
      } else {
        if (props.formSkipped) {
          props.setFormSkipped(false)
          props.setFlowStepIndex(0)
        } else {
          props.setFlowStepIndex(props.flowStepIndex - 1)
        }
      }
    },
    handleSkipForm: props => () => {
      props.setError(null)
      props.setFormSkipped(true)
      props.setFlowStepIndex(downloadAndSendStepIndex(props.applicationType))
    }
  })
)

export default hoistStatics(container)
