import React from 'react'
import styled from 'styled-components'

import Loader from './Loader'
import Text from './Text'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(47, 47, 47, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s;
  pointer-events: 'all';
  color: white;
`

const StyledText = styled(Text)`
  color: white;
`

type Props = {
  isOpen: boolean
  delayTime: number
  title: string
}

const LoadingOverlay = ({isOpen, title}: Props) => {
  return (
    isOpen && (
      <Overlay>
        <div>
          <Loader />
          {!!title && <StyledText>{title}</StyledText>}
        </div>
      </Overlay>
    )
  )
}

export default LoadingOverlay
