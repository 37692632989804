// @flow
import type {CodeModalProps} from '../../actions/codeModalForm'

import React from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../i18n'
import Modal from './Modal'
import Link from './Link'
import Button from './Button'
import InputField from './InputField'
import Text from './Text'
import Alert from './Alert'
import Paragraph from './Paragraph'
import useCodeModalForm from '../../actions/codeModalForm'

const Action = styled.div`
  margin-top: 24px;
`
type Props = {
  small?: boolean,
  title: string
} & CodeModalProps

const CodeModal = (props: Props) => {
  const {small, title, contactType, close, isOpen} = props
  const {
    loading,
    error,
    code,
    setCode,
    resendCode,
    codeResendRequest,
    submitCode
  } = useCodeModalForm(props)

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      title={title}
      small={!!small}
      data-cy="enter-code-modal"
    >
      <Paragraph>
        <Text>
          {formatMessage('pleaseEnterVerificationCode', {
            contactType
          })}
        </Text>
      </Paragraph>

      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          submitCode(code)
        }}
      >
        <InputField
          data-cy="verification-code"
          label={formatMessage('enterVerificationCode')}
          placeholder={formatMessage('verificationCodeFromContact', {
            contactType
          })}
          value={code}
          onChangeText={setCode}
          action={
            <Link data-cy="resend-code" onClick={resendCode} type="button">
              {formatMessage('resendCode')}
            </Link>
          }
        />
        {codeResendRequest &&
          (codeResendRequest.success ? (
            <Alert type="success" data-cy="code-resent-successfully">
              {formatMessage('codeResentSuccessfully')}
            </Alert>
          ) : (
            <Alert type="error" data-cy="code-resend-error">
              {formatMessage(codeResendRequest.error)}
            </Alert>
          ))}
        {error && (
          <Alert data-cy={error} type="error">
            {formatMessage(error)}
          </Alert>
        )}
        <Action>
          <Button
            block
            data-cy="submit-code"
            disabled={loading}
            visual="secondary"
          >
            {formatMessage('submit')}
          </Button>
        </Action>
      </form>
    </Modal>
  )
}

export default CodeModal
