// @flow
import type {Client} from '../../state'

import {useState, useMemo, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {mapValues, compact} from 'lodash'

import {userSelector, clientSelector} from '../../selectors'
import {usePrevious} from '../../utils/hooks'
import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'

export type EditCorrespondenceAddressModalProps = {
  isOpen: boolean,
  close: Function,
  continueFlow: Function
}

export const useEditCorrespondenceAddressForm = ({
  isOpen,
  continueFlow
}: EditCorrespondenceAddressModalProps) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [street, setStreet] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')

  const client = useSelector(state => clientSelector(state))

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      setLoading(false)
      setError(null)
      setStreet(client.correspondenceAddress.street)
      setZip(client.correspondenceAddress.zip)
      setCity(client.correspondenceAddress.city)
    }
  }, [client, isOpen, prevIsOpen])

  const fieldSetters = useMemo(
    () =>
      mapValues(
        {
          street: setStreet,
          zip: setZip,
          city: setCity
        },
        fieldSetter => value => {
          setError(null)
          fieldSetter(value)
        }
      ),
    []
  )

  const submit = async () => {
    if (loading) return

    const validationErrors = compact([
      (!street || !zip || !city) && 'ERR_REQUIRED_IS_MISSING'
    ])
    if (validationErrors.length) {
      setError(validationErrors[0])
      return
    }

    setLoading(true)
    try {
      const user = dispatch((_, getState) => userSelector(getState()))
      await apiRequest('/send-verification-code-to-user', {
        method: 'POST',
        body: {contactType: user.contactType, reason: 'UPDATE_ADDRESS'}
      })
      continueFlow({street, zip, city})
    } catch (err) {
      setLoading(false)
      dispatch(
        handleApiError(err, [
          ['ERR_RATELIMIT_EXCEEDED'],
          errorCode => setError(errorCode)
        ])
      )
    }
  }

  return {loading, error, fields: {street, zip, city}, fieldSetters, submit}
}

type OpenedModal = null | 'EditAddress' | 'Code'

export const useEditCorrespondenceAddressFlow = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => userSelector(state))

  const [openedModal, setOpenedModal] = useState<OpenedModal>(null)
  const [newAddress, setNewAddress] = useState(null)

  const open = () => {
    if (openedModal) return
    setOpenedModal('EditAddress')
  }

  const editAddressModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'EditAddress',
      close: () => setOpenedModal(null),
      continueFlow: newAddress => {
        setNewAddress(newAddress)
        setOpenedModal('Code')
      }
    }),
    [openedModal, setOpenedModal]
  )

  const codeModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'Code',
      close: () => setOpenedModal(null),
      contactType: user.contactType,
      resendCodeApiRequest: () =>
        apiRequest('/send-verification-code-to-user', {
          method: 'POST',
          body: {contactType: user.contactType, reason: 'UPDATE_ADDRESS'}
        }),
      validateCodeAndContinue: async (
        code: string,
        errorCallback: Function
      ) => {
        try {
          await apiRequest('/client/update-correspondence-address', {
            method: 'POST',
            body: {correspondenceAddress: newAddress, code}
          })

          // set client correspondence address in redux
          const {street, zip, city} = newAddress
          const joined = `${street}, ${zip} ${city}`
          dispatch({
            type: 'SET_VALUE',
            path: ['clients', user.clientId, 'correspondenceAddress'],
            value: {...newAddress, joined}
          })
          setOpenedModal(null)
        } catch (err) {
          dispatch(
            handleApiError(err, [
              [
                'ERR_EXPIRED',
                'ERR_INVALID_DATA',
                'ERR_CODE_ENTER_LIMIT_EXCEEDED'
              ],
              errorCode => errorCallback(errorCode)
            ])
          )
        }
      }
    }),
    [
      dispatch,
      newAddress,
      openedModal,
      setOpenedModal,
      user.clientId,
      user.contactType
    ]
  )

  return {open, editAddressModalProps, codeModalProps}
}

export const useCorrespondenceAddress = (
  client?: Client
): {notFound?: boolean, editable?: boolean, address?: string} => {
  const userClient = useSelector(state => clientSelector(state))

  const isCurrentUser = userClient && (!client || client.id === userClient.id)

  if (isCurrentUser) {
    return {editable: true, address: userClient.correspondenceAddress.joined}
  } else if (client) {
    return {editable: false, address: client.correspondenceAddress.joined}
  } else {
    return {notFound: true}
  }
}
