// @flow
import type {HOC} from 'recompose'
import type {State, CardAndAccountType} from '../../state'

import {connect} from 'react-redux'
import {compose, hoistStatics, withProps} from 'recompose'
import {cardSelector, accountSelector} from '../../selectors'
import {withParamsProp} from '../utils'
import handleNotFound from '../../handleNotFound'

const container: HOC<CardAndAccountType, *> = compose(
  withParamsProp(),
  withProps(({params, card}) => ({
    // if cardId is not in params from router or navigation
    params: params && params.cardId ? params : {...params, cardId: card.id}
  })),
  connect((state: State, props) => ({
    card: cardSelector(state, props.params.cardId)
  })),
  handleNotFound(({card}) => !card),
  connect((state: State, {card}) => ({
    account: accountSelector(state, card.accountId)
  })),
  handleNotFound(({account}) => !account)
)

export default hoistStatics(container)
