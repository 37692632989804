// @flow
import {throttle} from 'lodash'
import {apiRequest, hasAccessToken} from './actions/apiRequest'
import handleApiError from './actions/handleApiError'
import store from './store'
import {PING_COOLDOWN} from './constants'

export const checkAccessTokenAvailability = async () => {
  await apiRequest('/auth/check-activity')
}

const addIdleTimer = () => {
  const pingBackend = async () => {
    if (hasAccessToken) {
      try {
        await checkAccessTokenAvailability()
      } catch (e) {
        store.dispatch(handleApiError(e))
      }
    }
  }

  const throttledPingBackend = throttle(pingBackend, PING_COOLDOWN, {
    trailing: false
  })

  document.addEventListener('DOMContentLoaded', throttledPingBackend)
  document.addEventListener('mousedown', throttledPingBackend)
  document.addEventListener('mousemove', throttledPingBackend)
  document.addEventListener('scroll', throttledPingBackend)
  document.addEventListener('keypress', throttledPingBackend)
}

export default addIdleTimer
