// parsing date format YYMMDD
const parseBirthDate = birthNumber => {
  if (birthNumber.length !== 6) return {}
  const parsedYear = parseInt(birthNumber.slice(0, 2), 10)
  const year = parsedYear > 54 ? parsedYear + 1900 : parsedYear + 2000

  const month = parseInt(birthNumber.slice(2, 4), 10)
  const day = parseInt(birthNumber.slice(4, 6), 10)
  const birthDate = new Date(year, month - 1, day)

  return {
    birthDate
  }
}

const parseBirthNumber = birthNumber => {
  if (birthNumber.length === 6) return parseBirthDate(birthNumber)
  if (birthNumber.length < 9 && birthNumber.length > 10) return {}
  const parsedYear = parseInt(birthNumber.slice(0, 2), 10)
  const year =
    // old birth number before 1954 or year sufficiently big
    birthNumber.length === 9 || parsedYear > 54
      ? parsedYear + 1900
      : parsedYear + 2000

  const parsedMonth = parseInt(birthNumber.slice(2, 4), 10)
  const month =
    parsedMonth > 70 // W after 2004
      ? parsedMonth - 70
      : parsedMonth > 50 // W before 2004
      ? parsedMonth - 50
      : parsedMonth > 20 // M after 2004
      ? parsedMonth - 20
      : parsedMonth // M before 2004

  const day = parseInt(birthNumber.slice(4, 6), 10)
  const gender = parsedMonth > 50 ? 'W' : 'M'
  const birthDate = new Date(year, month - 1, day)

  return {
    gender,
    birthDate
  }
}

export default parseBirthNumber
