export default {
  // general
  dateValue:
    '{date, date} {showTime, select, false{} other{o {date, time, short}}}',
  ERR_LOCKED_OUT:
    'Zaznamenali sme 3 nesprávne pokusy. Prihlasovanie bude obnovené o 5 minút.',
  // backend errors
  ERR_INVALID_DATA:
    'Údaje, ktoré si zadal(a) sú nesprávne. Prosím skontroluj ich a skús to znova.',
  ERR_INVALID_VS:
    'Variabilný symbol neexistuje. Pri založení účtu môže spracovanie trvať aj deň.',
  ERR_INVALID_VS_FORMAT: 'Variabilný symbol je v nesprávnom tvare',
  ERR_INVALID_KS_FORMAT: 'Konštantný symbol je v nesprávnom tvare',
  ERR_INVALID_SS_FORMAT: 'Špecifický symbol je v nesprávnom tvare',
  ERR_INVALID_MONEY_AMOUNT: 'Suma je v nesprávnom tvare',
  ERR_INVALID_USERNAME: 'Používateľské meno je v nesprávnom tvare.',
  ERR_DUPLICATE_RECORD: 'Používateľ už existuje',
  ERR_INVALID_EMAIL:
    'Email nepatrí k účtu s daným variabilným symbolom a ani k nemu nebol pozvaný',
  ERR_INVALID_PHONE:
    'Mobil nepatrí k účtu s daným variabilným symbolom a ani k nemu nebol pozvaný',
  ERR_INVALID_PASSWORD: 'Nesprávne heslo',
  ERR_INVALID_PASSWORD_FORMAT:
    'Heslo musí obsahovať aspoň 8 znakov bez medzier, z toho aspoň 1 veľké písmeno, 1 malé písmeno a 1 číslicu.',
  ERR_EXPIRED: 'Registračný kód expiroval.',
  ERR_USER_ALREADY_ACTIVATED: 'Používateľ je už aktivovaný.',
  ERR_CANT_INVITE_MYSELF: 'Nemôžeš pozvať seba.',
  ERR_INVITE_ALREADY_EXIST: 'Pozvánka na zadaný kontakt už existuje',
  ERR_RATELIMIT_EXCEEDED: 'Prekročil(a) si limit pre SMS. Skús znovu neskôr.',
  ERR_CODE_ENTER_LIMIT_EXCEEDED:
    'Prekročil(a) si maximálny počet pokusov na zadanie kódu. Požiadaj o znovuposlanie kódu.',
  ERR_UNAUTHORIZED: 'Nie si autorizovaný(á).',
  // frontend errors
  ERR_INVALID_EMAIL_FORMAT: 'Zadaný email je v nesprávnom tvare',
  ERR_INVALID_PHONE_FORMAT: 'Mobilné tel. číslo má nesprávny formát',
  ERR_REQUIRED_IS_MISSING: 'Nevyplnil(a) si všetky povinné polia',
  ERR_PASSWORDS_DONT_MATCH: 'Zadané heslá sa nezhodujú',
  ERR_TEXT_IS_REQUIRED: 'Text je povinný',
  ERR_INVALID_IBAN_FORMAT: 'Vami zadaný účet nie je správny.',
  ERR_IBAN_COLLECTION_ACCOUNT: 'Zvoľ typ platby: z karty na inú kartu SKPAY.',
  ERR_IBAN_NOT_SEPA: 'Vami zadaný účet nie je SEPA. Prevod nebude vykonaný.',
  ERR_INVALID_NATIONALITY_FORMAT:
    'Zadaná štátna príslušnosť je v nesprávnom tvare',
  ERR_INVALID_BIRTH_NUMBER_FORMAT: 'Zadané rodné číslo je v nesprávnom tvare',
  ERR_INVALID_PIN_CODE: 'Nesprávny PIN. Prosím skús znova.',
  ERR_TOO_MANY_PIN_ATTEMPTS:
    'Príliš veľa pokusov o zadanie PIN kódu. Prihlás sa znova.',
  ERR_INVITE_ALREADY_ACCEPTED: 'Pozvánka už bola prijatá.',
  SERVER_ERROR: 'Chyba serveru',
  // operation types
  OP_TYPE_ATM: 'Bankomat',
  OP_TYPE_POS: 'Bezhotovostná platba',
  OP_TYPE_CASHLESS: 'Bezhotovostná platba',
  OP_TYPE_VOICE: 'Hlasová autorizácia',
  OP_TYPE_ECOMMERCE: 'Ecommerce',
  OP_TYPE_DEFAULT: 'Iné',
  // pin lock
  pinLockTitleCreate: 'Vytvor si PIN',
  pinLockSubtitleCreate: 'Zvoľ si PIN na odomykanie účtu.',
  pinLockTitleValidationFailed: 'Zadaný PIN je ľahko uhádnuteľný',
  pinLockTitleConfirm: 'Zopakuj PIN',
  pinLockTitleConfirmFailed: 'Zadané PINy sa nezhodujú',
  pinLockTitleEnter: 'Zadaj PIN',
  pinLockTitleChange: 'Zadaj pôvodný PIN',
  scanYourFinger: 'Prilož prst ku skeneru.',
  scanFailedTryAgain: 'Skenovanie zlyhalo. Prosím skús znova.',
  scanFailedLockout: 'Príliš veľa pokusov. Použi PIN.',
  youWillBeLogout: 'Po stlačení OK budeš odhlásený(á).',
  allowBiometricsAlert:
    'Povoliť prihlásenie pomocou {biometricsType, select, fingerprint {odtlačku prstu} face {skenu tváre}}?',
  biometryAttemptExpired:
    'Prihlásenie pomocou biometrie teraz nie je možné. Použi svoj PIN kód.',
  youCanChangeThisInProfile:
    'Toto nastavenie môžte neskôr zmeniť vo svojom profile',
  // other
  footer:
    'SKPAY, a. s., Nám. SNP 35, 811 01 Bratislava | Všetky práva vyhradené',
  accounts: 'Účty',
  yourAccounts: 'Tvoje účty',
  noAccounts: 'K tvojmu profilu nie je naviazaný žiadny účet.',
  noCards: 'K tvojmu profilu nie je naviazaná žiadna karta.',
  cardNeedsActivation: 'Posielame ti novú kartu. Aktivuj si ju.',
  cardBeingProduced: 'Novú kartu vyrábame',
  EUR: '{amount, number, EUR}',
  topUpAccount: 'Dobiť účet',
  accountDetail: 'Detail účtu',
  transactions: 'Pohyby',
  recentTransactions: 'Posledné pohyby na účte',
  filterTransactions: 'Filtrované pohyby na účte',
  noTransactionsInSelectedFilter: 'Žiadne pohyby nevyhovujú zadanému filtru',
  loadingTransactions: 'Načítavam pohyby',
  errorLoadingTransactions: 'Chyba pri načítavaní pohybov',
  transactionDetail: 'Detail transakcie',
  transactionsType: 'Typ transakcií',
  from: 'Od',
  to: 'Do',
  filter: 'Filter',
  changeFilter: 'Zmeniť filter',
  date: 'Dátum',
  showResults: 'Zobraziť výsledky',
  resetFilter: 'Resetovať filter',
  notAllTransactionsShownSpecifyFilter:
    'Nie sú zobrazené všetky transakcie. Špecifikuj filter.',
  reserved: 'Rezervovaná',
  realized: 'Zrealizovaná',
  seller: 'Obchodník',
  detail: 'Detail',
  originalAmount: 'Pôvodná suma',
  conversionRate: 'Kurz',
  contactInformation: 'Kontaktné údaje',
  changePassword: 'Zmeniť heslo',
  privacy: 'Súkromie',
  changeAppPin: 'Zmena PIN kódu aplikácie',
  signInViaBiometrics:
    'Prihlásenie pomocou {biometricsType, select, fingerprint {odtlačku prstu} face {skenu tváre}}',
  aboutApp: 'O aplikácii',
  rateApp: 'Ohodnoť nás na {os, select, ios {App Store} android {Google Play}}',
  version: 'Verzia',
  reportTransaction: 'Reklamovať transakciu',
  reportOfTransaction: 'Reklamácia transakcie',
  reportOfTransactionDesc:
    'Ak nesúhlasíš s transakciou, môžeš nám ju oznámiť prostredníctvom tohto formulára.',
  yourEmail: 'Tvoj email',
  optional: 'Nepovinné',
  errorDesc: 'Opis chyby',
  optionalEmailInfo:
    'Tvoj email nie je povinný údaj. Ak nám však svoj email necháš, budeme mať možnosť ťa kontaktovať v prípade potreby upresnenia informácií.',
  documents: 'Dokumenty',
  businessConditions: 'Obchodné podmienky',
  fees: 'Sadzobník poplatkov',
  dataProtection: 'Ochrana osobných údajov',
  termsOfUseAndPrivacyPolicy: 'Podmienky používania a ochrana súkromia',
  launchTutorial: 'Spustiť tutoriál',
  appLanguage: 'Jazyk aplikácie',
  changeAppLanguage: 'Zmeniť jazyk aplikácie',
  appSettings: 'Nastavenia aplikácie',
  signOut: 'Odhlásiť sa',
  deactivate: 'Deaktivovať',
  myProfile: 'Môj profil',
  profile: 'Profil',
  accountManagement: 'Nastavenia účtu',
  cardsManagement: 'Moje karty',
  bankStatements: 'Výpisy',
  noBankStatements: 'Pre tento účet sa nenašli žiadne výpisy',
  selectYearLabel: 'Rok {year}',
  bankStatementLabel: `{month, select,
    0 {Január}
    1 {Február}
    2 {Marec}
    3 {Apríl}
    4 {Máj}
    5 {Jún}
    6 {Júl}
    7 {August}
    8 {September}
    9 {Október}
    10 {November}
    11 {December}
  }/{year}`,
  guestsManagement: 'Pozvaní priatelia',
  usersWithAccessToYourAccount: 'Používatelia s prístupom k tvojmu účtu',
  accountSettings: 'Nastavenia účtu',
  invitesWaiting: 'Odoslané pozvánky',
  noGuests: 'Do účtu si ešte nikoho nepozval(a).',
  inviteGuest: 'Pozvať používateľa',
  inviteFormType: 'Vyber si údaj, na ktorý chceš odoslať pozvánku:',
  inviteFormDescriptionEmail: 'Pozvánku k účtu odošleme na tento email.',
  inviteFormDescriptionMobile:
    'Pozvánku k účtu odošleme na toto mobilné telefónne číslo.',
  inviteFormNoticeTitle: 'Nový používateľ bude mať nasledujúce práva:',
  inviteFormNoticeFirst: '- blokovanie kariet',
  inviteFormNoticeSecond: '- prezeranie všetkých transakcií na tvojom účte',
  cardPanMasked: '•••• {pan}',
  cardLimitMaxValue: '{label} (Maximálna hodnota: {maxValue})',
  max: 'Max {maxValue} €',
  cardExpiration: 'PLATNÁ DO',
  addToMyCards: 'Pridať medzi obľúbené karty',
  setLimits: 'Nastavenie limitov',
  limits: 'Limity',
  atmWithdrawal: 'Výber z bankomatu',
  atmWithdrawalDayLimit: 'Denný limit pre výber z bankomatu',
  allowATMWithdrawal: 'Povoliť výber z bankomatu',
  dayLimit: 'Denný limit',
  cashlessPayments: 'Bezhotovostné platby',
  cashlessPaymentsDayLimit: 'Denný limit pre bezhotovostné platby',
  allowCashlessPayments: 'Povoliť bezhotovostné platby',
  contactlessPayments: 'Bezkontaktné platby',
  allowContactlessPayments: 'Povoliť bezkontaktné platby',
  internetPayments: 'Platby cez internet',
  internetPaymentDayLimit: 'Denný limit pre platby cez internet',
  allowInternetPayments: 'Povoliť platby cez internet',
  reprintPin: 'Znovu vytlačiť PIN',
  reprintPinDescription:
    'Ak si zabudol/zabudla PIN, môžeš požiadať o jeho opätovné vytlačenie a my ti ho pošleme do 5 pracovných dní poštou',
  reprintPinModalTitle: 'Znovu vytlačenie PIN',
  reprintPinModalInfo: 'PIN ti pošleme poštou do 5 pracovných dní.',
  reprintPinAddressLabel: 'PIN pošleme na adresu:',
  reprintPinSubmitButton: 'Znovu vytlačiť PIN',
  reissueCard: 'Prevydanie karty',
  reissueCardDescription: 'Vydaním novej karty sa pôvodná karta znefunkční.',
  reissueCardModalTitle: 'Vydanie novej karty',
  reissueCardModalAlert:
    'Pozor, po potvrdení požiadavky na novú kartu pôvodná karta prestane fungovať!',
  issueNewCard: 'Vydať novú kartu',
  reissueCardAddressLabel: 'Kartu pošleme na nasledujúcu adresu:',
  reissueCardReasonLabel: 'Dôvod vydania novej karty',
  chooseReason: 'Vyber dôvod',
  choose: 'Vybrať',
  lossOrTheft: 'Strata alebo krádež',
  damage: 'Poškodenie',
  lostOrStolenCardInfo:
    'Zvoľ túto možnosť, ak si stratil(a) kartu, odcudzili ti ju alebo ju nevieš nájsť. Novú kartu a PIN ti pošleme do 5 pracovných dní poštou.',
  damagedCardInfo:
    'Zvoľ túto možnosť, ak je tvoja karta fyzicky poškodená, napr. nalomená. Novú kartu a PIN ti pošleme do 5 pracovných dní poštou.',
  requestMoney: 'Previesť peniaze na účet',
  currency: 'Mena',
  requestMoneyChooseTypeTitle: 'Zvoľte typ platby',
  toOtherSkpayCard: 'Z karty na inú kartu SKPAY',
  toIBAN: 'Na účet IBAN',
  requestMoneyDescription:
    'Tu môžeš požiadať o prevod peňazí na tvoj bankový účet.',
  requestMoneyModalTitle: 'Žiadosť o prevod peňazí',
  requestMoneyMadeOnline: 'Prevod bude vykonaný ihneď!!!',
  requestMoneyWithin2Days: 'Prevod bude vykonaný do 2 pracovných dní!!!',
  requestMoneyNeedMobileNo:
    'Ak chcete urobiť prevod, musíte mať zaevidované mobilné číslo.',
  requestMoneyConfirmation: 'Potvrdenie žiadosti o prevod peňazí',
  submitRequestMoney: 'Odoslať',
  receiverIban: 'Účet v tvare IBAN',
  placeholderIban: 'SK09 1287 1234 1245 9812 7654',
  receiverSkpayAccountNo: 'Číslo kartového účtu SKPAY',
  placeholderReceiverSkpayAccountNo: 'VS 10-miestne číslo',
  receiverName: 'Názov príjemcu',
  vsCode: 'Variabilný symbol',
  ssCode: 'Špecifický symbol',
  ksCode: 'Konštantný symbol',
  referenceForPayer: 'Referencia pre platiteľa',
  paymentDate: 'Dátum',
  accountInformation: 'Informácie o klientovi',
  addresses: 'Adresy',
  myCard: 'Moja karta',
  myCards: 'Moje karty',
  otherCards: 'Ostatné karty',
  invitationSentOn:
    'Pozvánka vytvorená {invitationDate, date} o {invitationDate, time, short}',
  name: 'Meno',
  firstName: 'Meno',
  lastName: 'Priezvisko',
  permanentAddress: 'Adresa trvalého bydliska',
  correspondenceAddress: 'Korešpondenčná adresa',
  availableBalance: 'Disponibilný zostatok',
  iban: 'Zberný účet',
  variableSymbol: 'Variabilný symbol',
  variableSymbolWithAbbreviation: 'Variabilný symbol (VS)',
  variableSymbolAbbreviation: 'VS',
  topupAccountDescription:
    'Dobitie môžeš urobiť bezhotovostným prevodom na Zberný účet. Nezabudni uviesť správny Variabilný symbol.',
  topupAccountTitle: 'Dobitie účtu',
  sendInvitationAgain: 'Poslať znova',
  saveChanges: 'Uložiť zmeny',
  transactionsTabLabel: 'Transakcie',
  contactInfoValidation: 'Overenie kontaktného údaju',
  verificationCode: 'Overovací kód',
  submit: 'Odoslať',
  delete: 'Vymazať',
  cancel: 'Zrušiť',
  signUp: 'Zaregistrovať sa',
  signIn: 'Prihlásiť sa',
  signInLong: 'Už máš používateľský účet? Prihlás sa.',
  emailOrPhone: 'Email alebo mobilné telefónne číslo',
  minimalLength8Symbols: 'Minimálna dĺžka 8 znakov',
  password: 'Heslo',
  forgotPassword: 'Zabudol si heslo?',
  forgotPinCode: 'ZABUDNUTÝ KÓD?',
  dontHaveAnAccountYet: 'Nemáš ešte účet a chceš sa zaregistrovať?',
  dontHaveCardYet: 'Nemáš ešte kartu SKPay?',
  fillOnlineApplication: 'Stačí vyplniť online žiadosť',
  variableSymbolOfAccount: 'Variabilný symbol účtu',
  tenDigitContractNumber: '10-miestne číslo zmluvy',
  forSignupIWantToUse:
    'Vyber si údaj zo zmluvy pomocou ktorého sa chceš zaregistrovať:',
  email: 'Email',
  phone: 'Mobil',
  placeholderContactValue:
    '{contactType, select, email {meno@email.sk} phone {+421 903 123 456}}',
  enterPasswordForCheck: 'Zadaj heslo pre kontrolu',
  repeatPassword: 'Zopakuj heslo',
  iAgreeToProcessingOfPersonalData: 'Beriem na vedomie ',
  iAgreeToProcessingOfPersonalDataLinkText:
    'spôsob spracovania a ochranu osobných údajov v SKPAY, a. s.',
  resendCode: 'Znova poslať kód',
  codeResentSuccessfully: 'Kód bol odoslaný',
  inviteResentSuccessfully: 'Pozvánka bola znovu odoslaná',
  signUpUsingDataFromYourContract:
    'Registruj sa pomocou údajov vo svojej zmluve.',
  whereCanIFindTheData: 'Kde nájdem tieto údaje?',
  registrationTitle: 'Zaregistruj sa do aplikácie SKPAY',
  loginTitle: 'Prihlás sa do aplikácie SKPAY',
  aboutAppTitle1: 'Vitaj v aplikácii SKPAY',
  aboutAppText1:
    'Predplatené karty SKPAY teraz môžeš spravovať pohodlne z tvojho smartfónu.',
  aboutAppTitle2: 'Tvoje karty pod kontrolou',
  aboutAppText2:
    'Tvoje financie sú v bezpečí vďaka okamžitej zmene limitov a možnosti zablokovať kartu.',
  aboutAppTitle3: 'Prehľad v pohyboch',
  aboutAppText3: 'Vďaka histórii pohybov vieš viac o tvojich výdavkoch.',
  requestCardTitle: 'Získaj kartu SKPAY',
  requestCardText1: 'Stačí, keď na našej webovej stránke:',
  requestCardText2: 'vyplníš žiadosť',
  requestCardText3: 'podpíšeš a nahráš zmluvu',
  requestCardText4: 'uhradíš 15 € s poplatkom na ktorejkoľvek pošte',
  requestCardText5:
    'Ak to nebudeš odkladať, karta môže byť do 3 dní v tvojej poštovej schránke.',
  backToMainPage: 'Späť na úvodnú stránku',
  mainPageUrl: '/',
  mobilePhoneNumber: 'Mobilné telefónne číslo',
  VSHintTitle: 'Kde nájdem variabilný symbol?',
  VSHintDescription:
    'Variabilný symbol nájdeš vo svojej zmluve na úvodnej strane v pravom hornom rohu.',
  registrationVSLabel: 'Variabilný symbol (VS - 10-miestne číslo z karty)',
  account: 'Účet',
  card: 'Karta',
  cards: 'Karty',
  period: 'Obdobie',
  filetype: '{extension, select, pdf {PDF} zip {ZIP}}',
  limitMustBeANumber: 'Zadaný limit musí byť číslo',
  limitMustBeAWholeNumber: 'Zadaný limit musí byť celé číslo',
  limitMustBeUpTo: 'Zadaný limit nemôže byť väčší ako {maxFieldValue}',
  limitMustBePositive: 'Zadaný limit musí byť kladné číslo',
  mobileApp: 'SKPAY v tvojom mobile',
  mobileAppPromoText: 'Nos aktuálny prehľad o kartách stále vo svojom vrecku.',
  contactUs: 'Kontaktuj nás',
  contactUsDescription:
    'Máš postrehy, otázky alebo nápady na zlepšenie? Napíš nám správu.',
  contactUsSuccess: 'Tvoja správa bola odoslaná',
  yourMessage: 'Tvoja správa pre nás',
  appStore: 'App Store',
  playStore: 'Play Store',
  editCorrAddressTitle: 'Zmeniť korešpondenčnú adresu',
  editCorrAddressConfirmation: 'Potvrdiť zmenu korešpondenčnej adresy',
  streetName: 'Ulica a číslo domu',
  postalCode: 'PSČ',
  city: 'Mesto',
  unknownErrorTitle: 'Neznáma chyba',
  unknownErrorDescription:
    'Nastala neznáma chyba. Prosím skús to zopakovať neskôr.',
  networkErrorTitle: 'Sieťová chyba',
  networkErrorDescription:
    'Nastala sieťová chyba. Prosím skontroluj svoje internetové pripojenie',
  reloadPage: 'Znovu načítať stránku',
  credit: 'Kreditné operácie',
  debit: 'Debetné operácie',
  creditShort: 'Kreditné',
  debitShort: 'Debetné',
  forgotPasswordTitle: 'Zabudol som heslo',
  forgotPasswordDescription:
    'Prosím vyplň kontaktný údaj, s ktorým si sa zaregistroval(a), pre obnovu tvojho hesla.',
  contactUsedForRegistration: 'Kontaktný údaj',
  continue: 'Pokračovať',
  pleaseEnterVerificationCode:
    'Prosím, zadaj overovací kód, ktorý sme ti zaslali na {contactType, select, email {tvoj email} phone {tvoje mobilné telefónne číslo}}.',
  pleaseEnterVerificationCodeContactValue:
    'Prosím, zadaj overovací kód, ktorý sme ti zaslali na {contactType, select, email {tvoj email} phone {tvoje mobilné telefónne číslo}} {contactValue}.',
  enterVerificationCode: 'Zadaj overovací kód',
  verificationCodeFromContact:
    'Overovací kód z {contactType, select, email {email-u} phone {SMS}}',
  identitySuccessfullyConfirmed:
    'Úspešne sme overili tvoju identitu. Môžeš si vytvoriť nové heslo.',
  yourPassword: 'Tvoje heslo',
  signInWithNewPassword: 'Prihlásiť sa s novým heslom',
  changePasswordConfirmation: 'Potvrdenie zmeny hesla',
  oldPassword: 'Staré heslo',
  newPassword: 'Nové heslo',
  newPasswordRepeat: 'Zopakuj nové heslo',
  changeContact:
    'Zmeniť {contactType, select, email {email} phone {mobilné telefónne číslo}}',
  yourNewContact:
    '{contactType, select, email {Tvoj nový email} phone {Tvoje nové mobilné telefónne číslo}}',
  changeContactConfirmation:
    'Potvrdenie zmeny {contactType, select, email {email-u} phone {mobilného telefónneho čísla}}',
  noMyCard: 'Nemáš žiadne karty',
  confirmDialogLimit: 'Potvrdiť zmenu limitu?',
  confirmDialogBlocked:
    'Naozaj chceš {value, select, true {zablokovat} false {odblokovať}} kartu?',
  confirmDialogDeleteInvite: 'Potvrdiť zmazanie používateľa?',
  unsupportedAppVersionTitle: 'Nepodporovaná verzia aplikácie',
  unsupportedAppVersionDescription:
    'Prosím aktualizuj si aplikáciu na novšiu verziu',
  amount: 'Suma',
  cardStatus: `{status, select,
      requested {Žiadaná}
      generated {Vyrobená}
      inProduction {Distribuovaná}
      inactive {Neaktívna}
      active {Aktívna}
      closed {Zrušená}
      expired {Expirovaná}
      incorrectProduction {Chybná výroba}
      adminSuspended {Blokovaná vydavateľom}
      blocked {Blokovaná}
      reissued {Prevydaná}
      other {}}`,
  toUnblockContactUsOn:
    'Pre odblokovanie karty nás kontaktuj na tel. čísle {supportPhone}',
  block: 'Zablokovať',
  unblock: 'Odblokovať',
  statusOfCard: 'Stav karty',
  cardProductDescription:
    '{cardProduct, select, maestro {Maestro} mastercard {Mastercard} other {Neznáma}} predplatená karta',
  mandatory: 'Povinné',
  allowed: 'Povolené',
  notAllowed: 'Nepovolené',
  requestAccepted: 'Tvoju žiadosť sme prijali',
  requestSentModalTitle: 'Žiadosť bola odoslaná',
  close: 'Zavrieť',
  loadingData: 'Načítavam údaje',
  loadingCards: 'Načítavam karty',
  ibanCopiedSuccess: 'IBAN skopírovaný',
  vsCopiedSuccess: 'Variabilný symbol skopírovaný',
  yes: 'Áno',
  no: 'Nie',
  confirm: 'Potvrdiť',
  privacyPolicy: 'Privacy Policy', // in english on purpose, so that google can see and read it
  noFileLoaded: 'Ešte si nenahral(a) žiaden súbor',
  filesLoaded: 'Nahrané súbory: {filenames}',
  refresh: 'Znovu načítať',
  fillYourDataToRequestCard:
    'Zadaj svoje osobné údaje pre vytvorenie žiadosti o vydanie predplatnej karty SKPAY',
  intendedForConsumers:
    'Tento spôsob je určený pre spotrebiteľov. Ak nie ste spotrebiteľ napíšte nám na',
  intendedForConsumersEmail: 'skpay@skpay.sk',
  fullName: 'Meno, priezvisko, titul',
  fullNamePlaceholder: 'Ing. Ján Novák',
  nationality: 'Štátna príslušnosť',
  otherNationalityHelper:
    'Zadaj svoju štátnu príslušnosť vo formáte XX, napríklad US (Spojené štáty americké), BR (Brazília)',
  alreadyHaveContract: 'Už máš vytlačenú a podpísanú žiadosť?',
  birthNumber: 'Rodné číslo',
  birthNumberPlaceholder: 'Rodné číslo bez lomky',
  birthNumberHelper:
    'Ak si narodený mimo SK/CZ, namiesto rodného čísla zadaj svoj dátum narodenia v tvare RRMMDD, napríklad 13.&nbsp;04.&nbsp;1992&nbsp;&rarr;&nbsp;920413',
  identityDocument: 'Doklad totožnosti',
  ID: 'Občiansky preukaz',
  IDPlaceholder: 'Číslo občianskeho preukazu',
  passport: 'Pas',
  passportPlaceholder: 'Číslo pasu',
  backToPreviousStep: 'Späť na predošlý krok',
  same: 'Rovnaká',
  different: 'Iná',
  agreementDataProtectionText: 'Súhlasím so',
  agreementDataProtectionLink: 'spracovaním osobných údajov',
  agreementBusinessConditionsText: 'Súhlasím s',
  agreementBusinessConditionsLink:
    'Obchodnými podmienkami pre predplatené karty SKPAY',
  agreementFeesText: 'Súhlasím so',
  agreementFeesLink: 'Sadzobníkom poplatkov',
  agreementDistanceContractInfoText: 'Súhlasím s',
  agreementDistanceContractInfoLink:
    'Informáciami pre spotrebiteľa pred uzavretím zmluvy na diaľku',
  printYourApplicationAndSend:
    'Vytlač si svoju žiadosť, podpíš ju a naskenovanú alebo odfotenú ju nahraj naspäť pre odoslanie',
  downloadApplication: 'Stiahnuť žiadosť',
  loadSignedApplication: 'Nahrať podpísanú žiadosť',
  loadIdDocument: 'Nahrať doklad totožnosti',
  uploadIdTooltipMyself: 'Vložte svoj preukaz totožnosti. ',
  uploadIdTooltipTeenager: 'Vložte svoj preukaz totožnosti. ',
  uploadIdTooltipOther:
    'Vložte Váš preukaz totožnosti spolu s preukazom osoby, pre ktorú kartu objednávate. ',
  uploadIdOrPassportTooltip:
    'Treba vložiť občiansky preukaz (obe strany) alebo cestovný pas (strana s osobnými údajmi). ',
  applicationBeingSent: 'Žiadosť sa odosiela. Môže to chvíľu trvať ...',
  applicationSentSuccessfully:
    'Žiadosť bola úspešne odoslaná. Po spracovaní ti pošleme email s ďalšími pokynmi. Ďakujeme za tvoj záujem o SKPAY kartu.',
  countryName: `{countryCode, select,
    SK {Slovensko}
    CZ {Česko}
    BE {Belgicko}
    BG {Bulharsko}
    HR {Chorvátsko}
    CY {Cyprus}
    DK {Dánsko}
    EE {Estónsko}
    FI {Fínsko}
    FR {Francúzsko}
    GR {Grécko}
    NL {Holandsko}
    IE {Írsko}
    LT {Litva}
    LV {Lotyšsko}
    LU {Luxembursko}
    HU {Maďarsko}
    MT {Malta}
    DE {Nemecko}
    PL {Poľsko}
    PT {Portugalsko}
    AT {Rakúsko}
    RO {Rumunsko}
    SI {Slovinsko}
    ES {Španielsko}
    SE {Švédsko}
    IT {Taliansko}
    GB {Veľká Británia}
    UA {Ukrajina}
    other {}
  }`,
  otherCountry: 'Iné',
  showEpin: 'Zobraziť ePIN',
  showEpinTitle: 'ePIN pre internetové platby',
  showEpinInfoMessageTitle:
    'ePIN slúži na potvrdenie internetových transakcií SKPAY kartou',
  showEpinInfoMessageDescription:
    'Počas platby sa zobrazí stránka Mastercard ID Check na overenie transakcie. Tam zadaj jednorazový kód z SMS správy a svoj ePIN.',
  changeEpin: 'Zmeniť ePIN',
  epinCreateTitle: 'Vytvorte si nový ePIN',
  epinConfirmTitle: 'Zopakujte nový ePIN',
  epinLockTitleConfirmFailed: 'Zadané ePINy sa nezhodujú',
  epinChangeSuccessMessage: 'Zmena ePIN prebehla úspešne.',
  epinPlaceholder: 'Zadajte 4-miestne číslo',
  newEpin: 'Nový ePIN',
  newEpinConfirm: 'Zopakujte nový ePIN',
  ePinIsNotActive: 'ePIN nie je aktivovaný',
  activateEpin: 'Aktivovať ePIN',
  showCardPin: 'Zobraziť PIN',
  cardPinAppPinTitle: 'Zadajte PIN kód mobilnej aplikácie',
  cardPinAppPinDescription:
    'Pre zobrazenie PIN ku karte je potrebné zadať PIN kód mobilnej aplikácie.',
  cardPinAppPinForgot: 'Zabudli ste PIN kód mobilnej aplikácie?',
  cardPinTitle: 'PIN ku karte',
  cardPinDescription: 'Svoj PIN nikomu neoznamujte.',
  cardPinChange: 'Zmeniť PIN',
  cardPinClose: 'Zavrieť ({seconds} s)',
  cardPinCreateTitle: 'Vytvorte si nový PIN',
  cardPinConfirmTitle: 'Zopakujte nový PIN',
  cardPinLockTitleConfirmFailed: 'Zadané PINy sa nezhodujú',
  cardPinChangeSuccessMessage: 'Zmena PIN prebehla úspešne.',
  requestCardSwitchTitle: 'Vyber si pre koho žiadaš kartu SKPAY',
  requestCardForMyself: 'Pre seba',
  requestCardForTeenager: 'Pre teenagera',
  requestCardForOther: 'Pre inú osobu',
  cardType: 'Typ karty',
  placeholderCardType: 'Vyber si kartu',
  cardTypeHelperMaestro:
    'Karta Maestro je určená na platby najmä v kamenných obchodoch a na výbery hotovosti.',
  cardTypeHelperMasterCard:
    'Kartou MasterCard môžete platiť aj na internete. Samozrejmosťou sú platby v kamenných obchodoch a výbery hotovosti.',
  postCard: 'Poštová karta',
  placeholderPostCard: 'Máš Poštovú kartu?',
  promoCode: 'Promo kód',
  iHavePostCard: 'Mám Poštovú kartu',
  iDontHavePostCard: 'Nemám Poštovú kartu',
  vsOfPostCardWithAbbr: 'Variabilný symbol Poštovej karty (VS)',
  placeholderVsOfPostCard: '10 miestne číslo',
  correspondenceAddressInfo: 'Na túto adresu vám zašleme kartu',
  fillTeenagerDataToRequestCard:
    'Zadaj osobné údaje teenagera pre vytvorenie žiadosti o vydanie predplatnej karty SKPAY.',
  teenagerFullName: 'Meno a priezvisko teenagera',
  otherPersonAgreementText:
    'Vyhlasujem, že som s hore uvedenou osobou v takom vzťahu, ktorý mi umožňuje v súlade s právnymi predpismi Slovenskej republiky poskytnúť SKPAY, a. s. kontaktné údaje tejto osoby.',
  teenagerRelationship: 'Vzťah k teenagerovi',
  placeholderOtherRelationship: 'Upresnenie vzťahu',
  relationships: `{code, select,
    PARENT {Rodič}
    GRANDPARENT {Starý rodič}
    AUNT {Teta}
    UNCLE {Ujo}
    other {Iné}
  }`,
  fillOtherPersonsDataToRequestCard:
    'Zadaj osobné údaje Držiteľa – pre neho žiadaš vydať predplatnú kartu SKPAY.',
  otherPersonFullName: 'Meno a priezvisko držiteľa',
  wrongPinCode: 'Nesprávny PIN kód',
  securityUpdateNoticeMessage:
    'V záujme zvyšovania bezpečnosti aplikácie sme vykonali aktualizáciu nastavení, ktorá pred prvým prihlásením vyžaduje zmenu hesla. Svoje heslo môžeš zmeniť na odkaze '
}
