import {withProps} from 'recompose'

let _getParamsFromProps

//TODO find some better way to type the func
// eslint-disable-next-line no-undef
export function withParamsProp() {
  return withProps(props => ({
    params: _getParamsFromProps(props)
  }))
}

export const initContainers = getParamsFromProps => {
  _getParamsFromProps = getParamsFromProps
}
