import styled, {css} from 'styled-components'

type ButtonVisual = 'primary' | 'secondary' | 'ghost' | 'disabled'
type ButtonSize = 'small' | 'medium' | 'large'
type CommonButtonProps = {
  visual?: ButtonVisual
  size?: ButtonSize
  disabled?: boolean
  block?: boolean
  style?: any // TODO: better type
}

const defaultVisual: ButtonVisual = 'primary'
const defaultSize: ButtonSize = 'medium'

const Button = styled.button<CommonButtonProps>`
  transition: background-color 0.25s;
  line-height: 1;
  outline: none;
  font-family: 'basier_circle', sans-serif;
  font-weight: ${props => (props.visual === 'ghost' ? '500' : '400')};

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: ${props => (props.block ? '100%' : 'auto')};

  ${({
    disabled,
    visual = defaultVisual,
    theme: {
      button: {colors}
    }
  }) => {
    const calculatedVisual: ButtonVisual = disabled ? 'disabled' : visual

    return css`
      color: ${colors[calculatedVisual].color};
      background-color: ${colors[calculatedVisual].normal};
      border: ${colors[calculatedVisual].border
        ? `1px solid ${colors[calculatedVisual].border}`
        : '0'};

      &:hover {
        background-color: ${colors[calculatedVisual].hover};
      }
    `
  }};

  ${({
    size = defaultSize,
    theme: {
      button: {sizes}
    }
  }) => css`
    font-size: ${sizes[size].fontSize};
    height: ${sizes[size].height};
    padding: ${sizes[size].padding};
    border-radius: ${sizes[size].borderRadius};
  `};

  ${props => props.style}
`

Button.defaultProps = {
  visual: defaultVisual,
  size: defaultSize
}

export default Button
