// @flow
import type {ContextRouter} from 'react-router-dom'

import React, {Fragment} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import {
  accountClientSelector,
  accountCardsListSelector
} from '../../../selectors'
import CardTab from '../../cards/CardTab'
import {withUserAndCardData} from '../../../withData'
import {Alert, Group, LoadingPlaceholder} from '../../ui'
import {formatMessage} from '../../../i18n'
import {
  isInactive,
  isInProduction,
  isDisplayable
} from '../../../containers/cards/utils'

const Layout = styled.div`
  flex: 1;
`

const AccountCardsManagement = ({match}: ContextRouter) => {
  const accountId = match.params.accountId || ''
  const accountClient = useSelector(state =>
    accountClientSelector(state, accountId)
  )

  const cards = useSelector(state => accountCardsListSelector(state, accountId))
  const someCardInactive = cards.some(isInactive)
  const someCardInProduction = cards.some(isInProduction)
  const displayableCards = cards.filter(isDisplayable)

  if (!accountClient) return null

  return (
    <Layout>
      {someCardInProduction && (
        <Alert type="info" data-cy="card-in-production">
          {formatMessage('cardBeingProduced')}
        </Alert>
      )}
      {someCardInactive && (
        <Alert type="info" data-cy="card-reissued">
          {formatMessage('cardNeedsActivation')}
        </Alert>
      )}
      {displayableCards.length > 0 && (
        <Fragment>
          <Group data-cy="my-cards">
            {displayableCards.map(card => (
              <CardTab
                key={card.id}
                card={card}
                holder={accountClient.name}
                url={`/accounts/${card.accountId}/cards/${card.id}`}
              />
            ))}
          </Group>
        </Fragment>
      )}
    </Layout>
  )
}

export default withUserAndCardData({
  loadingComponent: () => (
    <LoadingPlaceholder title={formatMessage('loadingCards')} />
  )
})(AccountCardsManagement)
