// @flow
import type {HOC} from 'recompose'
import type {State, Client} from '../../state'

import {compose, hoistStatics} from 'recompose'
import {connect} from 'react-redux'
import {clientSelector, usernameSelector} from '../../selectors'
import {logout} from '../../actions/auth/logout'

export type ProfileProps = {
  client?: Client,
  username: string,
  logout: () => Promise<void>
}

const container: HOC<ProfileProps, *> = compose(
  connect(
    (state: State) => ({
      client: clientSelector(state),
      username: usernameSelector(state)
    }),
    {logout}
  )
)

export default hoistStatics(container)
