// @flow
import type {Thunk} from '../../types/reduxTypes'
import type {State} from '../../state'

import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import {SET_VALUE, SET_FORM_FIELD_VALUE} from '../commonActions'
import {getInitialReissueCardForm} from '../../state'

const PATH = ['forms', 'reissueCardForm']

export const selector = (state: State) => state.forms.reissueCardForm

const setReason = (reason: string) =>
  SET_FORM_FIELD_VALUE(PATH, 'reason', reason)

const openModal = (): Thunk<void> => (dispatch, getState) => {
  dispatch(SET_VALUE(PATH, getInitialReissueCardForm()))
  dispatch(SET_VALUE([...PATH, 'isModalOpen'], true))
}

const closeModal = (): Thunk<void> => (dispatch, getState) => {
  if (selector(getState()).loading) return
  dispatch(SET_VALUE(PATH, getInitialReissueCardForm()))
}

const submit = (cardId: string): Thunk<> => async (dispatch, getState) => {
  const {
    fields: {reason},
    loading
  } = selector(getState())
  const card = getState().cards[cardId]

  if (loading) return

  dispatch(SET_VALUE([...PATH, 'loading'], true))
  try {
    await apiRequest(`/cards/${cardId}/reissue`, {
      method: 'POST',
      body: {accountId: card.accountId, reason, type: 'card'}
    })
    dispatch(SET_VALUE(['cards', cardId, 'settings', 'blocked'], true))
    dispatch(SET_VALUE([...PATH, 'success'], true))
  } catch (e) {
    await dispatch(handleApiError(e))
  } finally {
    dispatch(SET_VALUE([...PATH, 'loading'], false))
  }
}

export const actions = {setReason, openModal, closeModal, submit}
