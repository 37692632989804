// @flow
import type {ObjectMap} from '../types/commonTypes'

import 'intl'
import IntlMessageFormat from 'intl-messageformat'
import {mapValues} from 'lodash'
import queryString from 'qs'

import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/sk'
import storage from '../utils/storage'
import en from './en'
import sk from './sk'

// https://github.com/expo/expo/issues/6536#issuecomment-574661759
if (typeof Intl.__disableRegExpRestore === 'function') {
  Intl.__disableRegExpRestore()
}

// See, for example, https://github.com/yahoo/intl-messageformat/releases/tag/v1.0.2
window.IntlMessageFormat = IntlMessageFormat
require('intl-messageformat/dist/locale-data/en')
require('intl-messageformat/dist/locale-data/sk')

const locales = {en, sk}
let currentLocale
export const getCurrentLocale = () => currentLocale

export const languages = [
  {
    locale: 'sk',
    shortCode: 'SK',
    name: 'Slovensky',
    versionName: 'Slovenská verzia'
  },
  {
    locale: 'en',
    shortCode: 'EN',
    name: 'English',
    versionName: 'English version'
  }
]

const formats = {
  number: {
    EUR: {
      style: 'currency',
      currency: 'EUR'
    }
  }
}

type Message = {format: (values?: ObjectMap<*>) => string}

let messages: ObjectMap<Message>
let defaultMessages: ObjectMap<Message>

const getMessages = (locale: string): ObjectMap<Message> => {
  if (!locales[locale]) {
    console.error(`Unknown locale ${locale}`) // eslint-disable-line no-console
    return {}
  }
  return mapValues(locales[locale], (rawMessage, messageKey) => {
    try {
      return new IntlMessageFormat(rawMessage, locale, formats)
    } catch (e) {
      throw new Error(
        `INTL Error when parsing ${messageKey}: '${rawMessage}': ${e.message}`
      )
    }
  })
}

export const setLocale = (locale: string) => {
  messages = getMessages(locale)
  defaultMessages = locale === 'sk' ? messages : getMessages('sk')
  currentLocale = locale
}

export const formatMessage = (key: string, values?: ObjectMap<*>) => {
  try {
    let message = messages[key]
    if (!message) {
      message = defaultMessages[key]
      if (message) {
        // eslint-disable-next-line no-console
        console.warn(
          `Message with key ${key} not found, using default locale instead`
        )
      } else {
        // eslint-disable-next-line no-console
        throw new Error(`Message with key ${key} not found`)
      }
    }
    return message.format(values)
  } catch (e) {
    throw new Error(
      `INTL Error when formatting ${key} with values: ${JSON.stringify(
        values
      )}: ${e.message}`
    )
  }
}

// - checks for "locale" query string, changes locale if present
// and removes the query string from the url to not make trouble in the app further
// - does not work properly in logged app (but no need to use it there)
export const changeLocaleIfProvided = async () => {
  const parsedQuery =
    !!window &&
    !!window.location &&
    !!window.location.search &&
    queryString.parse(window.location.search, {ignoreQueryPrefix: true})
  if (!!parsedQuery && Object.keys(locales).includes(parsedQuery.locale)) {
    setLocale(parsedQuery.locale)
    await storage.setItem('locale', parsedQuery.locale)
    delete parsedQuery.locale
    let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    if (Object.keys(parsedQuery).length)
      newUrl = `${newUrl}?${queryString.stringify(parsedQuery)}`
    window.history.replaceState({path: newUrl}, null, newUrl)
  }
}
