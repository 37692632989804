// @flow
import type {ComponentType} from 'react'
import type {HOC} from 'recompose'
import type {ReprintPinFormType, Card} from '../../state'

import {hoistStatics} from 'recompose'
import {connect} from 'react-redux'

import * as reprintPinForm from '../../actions/cards/reprintPinForm'

type InputReprintPinProps = {
  card: Card,
  Link: ComponentType<{openModal: typeof reprintPinForm.actions.openModal}>
}

export type ReprintPinProps = InputReprintPinProps &
  ReprintPinFormType &
  typeof reprintPinForm.actions

const container: HOC<ReprintPinProps, InputReprintPinProps> = connect(
  reprintPinForm.selector,
  reprintPinForm.actions
)

export default hoistStatics(container)
