// @flow
import type {GeneralErrorProps} from '../containers/generalErrorContainer'

import React from 'react'
import {compose, withState, lifecycle} from 'recompose'
import {uniqueId} from 'lodash'

import {formatMessage} from '../i18n'
import {Text, Modal, Paragraph, Button} from './ui'
import generalErrorContainer from '../containers/generalErrorContainer'

const GeneralError = (
  props: GeneralErrorProps & {visible: boolean, modalKey: string}
) => (
  <Modal
    isOpen={props.visible}
    onRequestClose={props.dismissGeneralError}
    title={
      props.visibleGeneralError === 'NETWORK_ERROR'
        ? formatMessage('networkErrorTitle')
        : formatMessage('unknownErrorTitle')
    }
    data-cy="general-error-modal"
    key={props.modalKey}
  >
    <Paragraph>
      <Text>
        {props.visibleGeneralError === 'NETWORK_ERROR'
          ? formatMessage('networkErrorDescription')
          : formatMessage('unknownErrorDescription')}
      </Text>
    </Paragraph>
    <Button block onClick={() => window.location.reload()}>
      {formatMessage('reloadPage')}
    </Button>
  </Modal>
)

const enhance = compose(
  generalErrorContainer,
  withState('visible', 'setVisible', props => !!props.visibleGeneralError),
  withState('modalKey', 'setModalKey', uniqueId().toString()),
  lifecycle({
    componentDidUpdate(prevProps) {
      //HACK: we need to remount the modal every time it appears
      // so that it is on top of other modals
      // we can achieve this by changing its key
      // but we can't change its key when it's about to disappear because
      // the animation would be cut off
      if (
        !!prevProps.visibleGeneralError !== !!this.props.visibleGeneralError
      ) {
        const visible = !!this.props.visibleGeneralError
        this.props.setVisible(visible)
        if (visible) this.props.setModalKey(uniqueId().toString())
      }
    }
  })
)

export default enhance(GeneralError)
