import React from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../i18n'
import {
  InputField,
  Modal,
  Button,
  Alert,
  EnterCodeModalOld,
  MaskedInputField
} from '../ui'
import requestMoneyContainer from '../../containers/cards/requestMoneyContainer'
import {RequestMoneyModalType} from '../../actions/cards/requestMoneyForm'
import * as d from 'date-fns'
import {DATE_FORMAT} from '../../constants'

const Action = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`

const ChoosePaymentTypeModal = props => (
  <Modal
    isOpen={props.openedModalType === RequestMoneyModalType.ChoosePaymentType}
    onRequestClose={props.closeModal}
    title={formatMessage('requestMoneyModalTitle')}
    data-cy="choose-payment-type-modal"
  >
    {props.error && (
      <Alert data-cy={props.error} type="error">
        {formatMessage(props.error)}
      </Alert>
    )}
    <Action>
      <Button
        block
        data-cy="submit-request-money"
        disabled={props.loading}
        onClick={props.navigateToSkpayPayment}
      >
        {formatMessage('toOtherSkpayCard')}
      </Button>
    </Action>
    <Action>
      <Button
        block
        data-cy="submit-request-money"
        disabled={props.loading}
        onClick={props.navigateToIbanPayment}
      >
        {formatMessage('toIBAN')}
      </Button>
    </Action>
  </Modal>
)

const SkpayPaymentModal = props => (
  <Modal
    isOpen={props.openedModalType === RequestMoneyModalType.SkpayPayment}
    onRequestClose={props.closeModal}
    title={formatMessage('requestMoneyModalTitle')}
    warning={formatMessage('requestMoneyMadeOnline')}
    warning2={formatMessage('requestMoneyNeedMobileNo')}
    data-cy="enter-iban-modal"
  >
    <form
      onSubmit={e => {
        e.preventDefault()
        props.submitAndSendCode()
      }}
    >
      <InputField
        required
        data-cy="receiver-acc-no"
        label={formatMessage('receiverSkpayAccountNo')}
        placeholder={formatMessage('placeholderReceiverSkpayAccountNo')}
        value={props.fields.receiverSkpayAccountNo}
        onChangeText={text => props.setField('receiverSkpayAccountNo', text)}
        maxLength="10"
      />
      <InputField
        required
        data-cy="receiver-name"
        label={formatMessage('receiverName')}
        value={props.fields.receiverName}
        onChangeText={text => props.setField('receiverName', text)}
        maxLength="100"
      />
      <InputField
        required
        data-cy="amount"
        label={formatMessage('amount')}
        value={props.fields.amount}
        onChangeText={text => props.setField('amount', text)}
        maxLength="13"
      />
      <InputField label={formatMessage('currency')} value="EUR" disabled />
      <InputField
        label={formatMessage('paymentDate')}
        value={d.format(new Date(), DATE_FORMAT)}
        disabled
      />

      {props.error && (
        <Alert data-cy={props.error} type="error">
          {formatMessage(props.error)}
        </Alert>
      )}
      <Action>
        <Button block data-cy="submit-request-money" disabled={props.loading}>
          {formatMessage('submitRequestMoney')}
        </Button>
      </Action>
    </form>
  </Modal>
)

const IbanPaymentModal = props => (
  <Modal
    isOpen={props.openedModalType === RequestMoneyModalType.IbanPayment}
    onRequestClose={props.closeModal}
    title={formatMessage('requestMoneyModalTitle')}
    warning={formatMessage('requestMoneyWithin2Days')}
    warning2={formatMessage('requestMoneyNeedMobileNo')}
    data-cy="enter-iban-modal"
  >
    <form
      onSubmit={e => {
        e.preventDefault()
        props.submitAndSendCode()
      }}
    >
      <MaskedInputField
        mask="aa99 9999 9999 9999 9999 9999 9999 9999 99"
        required
        data-cy="iban"
        label={formatMessage('receiverIban')}
        placeholder={formatMessage('placeholderIban')}
        value={props.fields.iban}
        onChangeText={text => props.setField('iban', text.toUpperCase())}
      />
      <InputField
        required
        data-cy="receiver-name"
        label={formatMessage('receiverName')}
        value={props.fields.receiverName}
        onChangeText={text => props.setField('receiverName', text)}
        maxLength="100"
      />
      <InputField
        required
        data-cy="amount"
        label={formatMessage('amount')}
        value={props.fields.amount}
        onChangeText={text => props.setField('amount', text)}
        maxLength="13"
      />
      <InputField label={formatMessage('currency')} value="EUR" disabled />
      <InputField
        data-cy="vs-code"
        label={formatMessage('vsCode')}
        value={props.fields.vsCode}
        onChangeText={text => props.setField('vsCode', text)}
        maxLength="10"
      />
      <InputField
        data-cy="ss-code"
        label={formatMessage('ssCode')}
        value={props.fields.ssCode}
        onChangeText={text => props.setField('ssCode', text)}
        maxLength="10"
      />
      <InputField
        data-cy="ks-code"
        label={formatMessage('ksCode')}
        value={props.fields.ksCode}
        onChangeText={text => props.setField('ksCode', text)}
        maxLength="10"
      />
      <InputField
        label={formatMessage('paymentDate')}
        value={d.format(new Date(), DATE_FORMAT)}
        disabled
      />
      {props.error && (
        <Alert data-cy={props.error} type="error">
          {formatMessage(props.error)}
        </Alert>
      )}
      <Action>
        <Button block data-cy="submit-request-money" disabled={props.loading}>
          {formatMessage('submitRequestMoney')}
        </Button>
      </Action>
    </form>
  </Modal>
)

const RequestSentModal = props => (
  <Modal
    isOpen={props.openedModalType === 'RequestSent'}
    onRequestClose={props.closeModal}
    title={formatMessage('requestSentModalTitle')}
    data-cy="request-sent-modal"
  >
    <Alert type="success">{formatMessage('requestAccepted')}</Alert>
    <Button onClick={props.closeModal} block data-cy="request-sent-dismiss">
      {formatMessage('close')}
    </Button>
  </Modal>
)

const EnterCodeModalSkpay = props => (
  <EnterCodeModalOld
    title={formatMessage('requestMoneyConfirmation')}
    contactType="phone"
    isOpen={props.openedModalType === RequestMoneyModalType.EnterCodeSkpay}
    submit={() => props.submitCodeAndRequestMoneySkpay(props.account.id)}
    {...props}
  />
)

const EnterCodeModalIban = props => (
  <EnterCodeModalOld
    title={formatMessage('requestMoneyConfirmation')}
    contactType="phone"
    isOpen={props.openedModalType === RequestMoneyModalType.EnterCodeIban}
    submit={() => props.submitCodeAndRequestMoneyIban(props.account.id)}
    {...props}
  />
)

const RequestMoney = ({Link, ...props}) => (
  <>
    <Link openModal={props.openRequestMoneyModal} />
    <ChoosePaymentTypeModal {...props} />
    <SkpayPaymentModal {...props} />
    <IbanPaymentModal {...props} />
    <EnterCodeModalSkpay {...props} />
    <EnterCodeModalIban {...props} />
    <RequestSentModal {...props} />
  </>
)

export default requestMoneyContainer(RequestMoney)
