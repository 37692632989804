import {useEffect, useRef} from 'react'

export const usePrevious = <T>(value: T): T | undefined => {
  // console.log('usePrev first line, "value"', value)
  const ref = useRef<T>()
  // console.log('ref', ref)
  useEffect(() => {
    // console.log('usePrev useEffect before assing, "value"', value)
    // console.log('usePrev useEffect before assing, "current"', ref.current)
    ref.current = value
    // console.log('usePrev useEffect after assing, "current"', ref.current)
  })
  // console.log('usePrev before return, "value"', value)
  // console.log('usePrev before return, "current"', ref.current)
  return ref.current
}
