// @flow
import {useState} from 'react'
import {useDispatch} from 'react-redux'

import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import {hashAuthData, saveCredentials} from '../../utils/auth'

export const useLoginForm = (platform = 'mobile') => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const wrapFieldSetter = fieldSetter => (newValue: string) => {
    setError(null)
    fieldSetter(newValue)
  }

  const submit = async () => {
    if (loading) return
    setLoading(true)
    try {
      setError(null)
      const {token} = await apiRequest('/auth/login', {
        method: 'POST',
        body: {
          username,
          password: hashAuthData(password)
        }
      })

      await saveCredentials(token, username)

      dispatch({type: 'LOGIN'})
    } catch (e) {
      setLoading(false)
      dispatch(
        handleApiError(e, [
          ['ERR_INVALID_DATA', 'ERR_LOCKED_OUT'],
          code => setError(code)
        ])
      )
    }
  }

  return {
    loading,
    error,
    fields: {username, password},
    fieldSetters: {
      username: wrapFieldSetter(setUsername),
      password: wrapFieldSetter(setPassword)
    },
    submit
  }
}
