// @flow
import type {Node} from 'react'
import type {Client} from '../../state'
import type {EditCorrespondenceAddressModalProps} from '../../actions/profile/correspondenceAddressActions'

import React from 'react'
import {Edit} from 'react-feather'

import {
  useCorrespondenceAddress,
  useEditCorrespondenceAddressFlow,
  useEditCorrespondenceAddressForm
} from '../../actions/profile/correspondenceAddressActions'
import {formatMessage} from '../../i18n'
import {
  Modal,
  Button,
  InputField,
  Alert,
  GroupItem,
  Link,
  CodeModal
} from '../ui'

const EditCorrespondenceAddressModal = (
  props: EditCorrespondenceAddressModalProps
) => {
  const {
    loading,
    error,
    fields,
    fieldSetters,
    submit
  } = useEditCorrespondenceAddressForm(props)
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.close}
      title={formatMessage('editCorrAddressTitle')}
      data-cy="edit-correspondence-address-modal"
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          submit()
        }}
      >
        <InputField
          required
          label={formatMessage('streetName')}
          value={fields.street}
          onChangeText={fieldSetters.street}
          data-cy="street-input"
        />
        <InputField
          required
          label={formatMessage('postalCode')}
          value={fields.zip}
          onChangeText={fieldSetters.zip}
          data-cy="zip-input"
        />
        <InputField
          required
          label={formatMessage('city')}
          value={fields.city}
          onChangeText={fieldSetters.city}
          data-cy="city-input"
        />
        {error && <Alert type="error">{formatMessage(error)}</Alert>}
        <Button
          visual="secondary"
          block
          disabled={loading}
          data-cy="save-correspondence-address"
        >
          {formatMessage('saveChanges')}
        </Button>
      </form>
    </Modal>
  )
}

const EditCorrespondenceAddressBlock = (props: {
  render: (open: Function) => Node
}) => {
  const {
    open,
    editAddressModalProps,
    codeModalProps
  } = useEditCorrespondenceAddressFlow()
  return (
    <>
      {props.render(open)}
      <EditCorrespondenceAddressModal {...editAddressModalProps} />
      <CodeModal
        title={formatMessage('editCorrAddressConfirmation')}
        {...codeModalProps}
      />
    </>
  )
}

const CorrespondenceAddress = (props: {client?: Client, label?: string}) => {
  const {notFound, editable, address} = useCorrespondenceAddress(props.client)

  if (notFound) return null
  return (
    <GroupItem
      data-cy="correspondence-address"
      label={props.label || formatMessage('correspondenceAddress')}
      value={address}
      action={
        editable && (
          <EditCorrespondenceAddressBlock
            render={open => (
              <Link onClick={open} data-cy="edit-correspondence-address">
                <Edit color="#5D7083" />
              </Link>
            )}
          />
        )
      }
    />
  )
}

export default CorrespondenceAddress
