// @flow
import type {Node} from 'react'
import type {CardsManagementProps} from '../../containers/cards/cardsManagementContainer'

import React, {Fragment} from 'react'
import styled from 'styled-components'

import CardTab from './CardTab'
import cardsManagementContainer from '../../containers/cards/cardsManagementContainer'
import {Alert, Text, Group} from '../ui'
import {formatMessage} from '../../i18n'
import {
  isInactive,
  isInProduction,
  isDisplayable
} from '../../containers/cards/utils'

const Layout = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 868px;
  min-width: 768px;
  width: 100%;
  margin: 100px auto 40px;
`

const Sidebar = styled.aside`
  background-color: white;
  width: 300px;
  flex-shrink: 0;
  margin-right: 80px;
  position: sticky;
  top: 100px;
`

const Content = styled.div`
  flex: 1;
  padding-top: ${props => (props.mutipleSections ? '36px' : '0')};
`

const CardsManagement = ({
  myCardsAndAccounts,
  children
}: CardsManagementProps & {children: Node}) => {
  const someCardInactive = myCardsAndAccounts.some(item =>
    isInactive(item.card)
  )
  const someCardInProduction = myCardsAndAccounts.some(item =>
    isInProduction(item.card)
  )
  const displayableCardsAndAccounts = myCardsAndAccounts.filter(item =>
    isDisplayable(item.card)
  )

  return (
    <Layout>
      <Sidebar>
        {displayableCardsAndAccounts.length > 0 && (
          <Fragment>
            {/*otherCardsAndAccounts.length > 0 && (
            <SectionTitle>{formatMessage('myCards')}</SectionTitle>
          )*/}
            <Group data-cy="my-cards">
              {displayableCardsAndAccounts.map(({card, account}) => (
                <CardTab
                  key={card.id}
                  card={card}
                  holder={account.clientName}
                  url={`/cards/${card.id}`}
                  showLogo={false}
                />
              ))}
            </Group>
          </Fragment>
        )}
        {/*otherCardsAndAccounts.length > 0 && (
        <Fragment>
          {displayableCardsAndAccounts.length > 0 && (
            <SectionTitle>{formatMessage('otherCards')}</SectionTitle>
          )}
          <Group data-cy="other-cards">
            {otherCardsAndAccounts.map(({card, account}) => (
              <CardTab
                key={card.id}
                card={card}
                holder={account.clientName}
                url={`/cards/${card.id}`}
                showLogo={false}
              />
            ))}
          </Group>
        </Fragment>
      )*/}
        {displayableCardsAndAccounts.length === 0 && (
          <div data-cy="no-cards">
            <Text>{formatMessage('noCards')}</Text>
          </div>
        )}
      </Sidebar>
      <Content
        mutipleSections={
          !someCardInProduction &&
          !someCardInactive &&
          displayableCardsAndAccounts.length > 0
        }
      >
        {someCardInProduction && (
          <Alert type="info" data-cy="card-in-production">
            {formatMessage('cardBeingProduced')}
          </Alert>
        )}
        {someCardInactive && (
          <Alert type="info" data-cy="card-reissued">
            {formatMessage('cardNeedsActivation')}
          </Alert>
        )}
        {children}
      </Content>
    </Layout>
  )
}

export default cardsManagementContainer(CardsManagement)
