// @flow
import {NavLink as RouterNavLink} from 'react-router-dom'
import styled from 'styled-components'

// NOTE: answer from Max - one of authors of the styled-components
// https://spectrum.chat/thread/8f753cea-75c3-4524-8207-fd0216026665
const activeClassName = 'active'

const NavLink = styled(RouterNavLink).attrs({activeClassName})`
  color: #002f5e;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0;
  align-self: stretch;
  margin-right: 40px;
  position: relative;
  transition: color 0.25s;
  font-weight: 500;
  font-family: 'basier_circle', sans-serif;

  &:hover {
    color: #ffa920;
  }

  &.${activeClassName} {
    color: #ffa920;
  }
`

export default NavLink
