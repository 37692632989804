// @flow
import type {ContextRouter} from 'react-router-dom'
import type {TransactionFilterProps} from '../../../actions/accounts/transactions/transactionsActions'
import type {Transaction, TransactionsData} from '../../../state'

import React, {useState} from 'react'
import {ArrowDown, ArrowUp, Filter, Minus} from 'react-feather'
import {useSelector} from 'react-redux'
import styled from 'styled-components'

import {
  useTransactionFilter,
  useTransactions
} from '../../../actions/accounts/transactions/transactionsActions'
import {formatMessage} from '../../../i18n'
import {accountSelector} from '../../../selectors'
import {Button, Checkbox, InputField, Link, Modal, Text} from '../../ui'
import {DatePicker} from './DatePicker'
import TransactionDetail from './TransactionDetail'

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const StyledItem = styled.li`
  box-shadow: 0 4px 6px rgba(120, 137, 153, 0.14);
  border-radius: 4px;
  border-top: 1px solid #f3f5f6;
  margin-bottom: 16px;
`

const TransactionCard = styled.div`
  cursor: pointer;
  transition: background-color 0.25s;
  &:hover {
    background-color: #fafafa;
  }
`

const TransactionCardHeader = styled.div`
  display: flex;
  text-decoration: none;
  padding: 16px 24px 16px 16px;
`

const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Amount = styled(Text)`
  color: ${props => (props.isNegative ? '#E15151' : '#6EB472')};
`

const TransactionIcon = styled.span`
  margin-right: 16px;
`

const TransactionTitle = styled(Text).attrs({bold: true})`
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  margin-bottom: 4px;
`

const NoResults = styled(Text).attrs({type: 'secondary', small: false})`
  text-align: center;
  display: block;
  margin-top: 40px;
`
const UseFilter = styled(Text).attrs({type: 'secondary', small: false})`
  text-align: center;
  display: block;
  margin-top: 40px;
`

const TransactionListItem = (props: {transaction: Transaction}) => {
  const {transaction} = props

  const account = useSelector(state =>
    accountSelector(state, transaction.accountId)
  )

  const [isOpen, setIsOpen] = useState(false)

  const isNegative = transaction.type === 'DEBIT'
  return (
    <StyledItem
      data-cy="transaction-list-item"
      data-transaction-id={transaction.id}
      active={isOpen}
    >
      <TransactionCard
        onClick={event => {
          // HACK: prevent triggering this when ReportTransactionsModal is open
          let node = event.target
          while (node) {
            if (node === event.currentTarget) {
              setIsOpen(!isOpen)
              return
            }
            node = node.parentNode
          }
        }}
      >
        <TransactionCardHeader>
          <TransactionIcon>
            {transaction.type ? (
              isNegative ? (
                <ArrowDown color="#E15151" size={16} />
              ) : (
                <ArrowUp color="#6EB472" size={16} />
              )
            ) : (
              <Minus color="#6EB472" size={16} />
            )}
          </TransactionIcon>
          <Content>
            <Row>
              <TransactionTitle data-cy="transaction-title">
                {transaction.description}
              </TransactionTitle>
              {transaction.amount != null && (
                <Amount data-cy="transaction-amount" isNegative={isNegative}>
                  {(transaction.amount * (isNegative ? -1 : 1)).toFixed(2)}{' '}
                  {account.currency}
                </Amount>
              )}
            </Row>
            <Text data-cy="transaction-date" type="secondary">
              {formatMessage('dateValue', {
                date: new Date(transaction.date),
                showTime: transaction.isReservation
              })}
              {transaction.isReservation && ` (${formatMessage('reserved')})`}
            </Text>
          </Content>
        </TransactionCardHeader>
        <TransactionDetail transaction={transaction} isOpen={isOpen} />
      </TransactionCard>
    </StyledItem>
  )
}

const TransactionList = (props: {transactionsData: TransactionsData}) => {
  const request = props.transactionsData.request
  if (request.error) return <Text>{formatMessage(request.error)}</Text>
  if (request.loading)
    return <Text>{formatMessage('loadingTransactions')}</Text>

  const result = props.transactionsData.result
  if (!request.success || !result) return null // should never happen

  return (
    <div>
      {result.filteredList.length ? (
        <div>
          <List>
            {result.filteredList.map(transaction => (
              <TransactionListItem
                key={transaction.id}
                transaction={transaction}
              />
            ))}
          </List>
          {!result.endReached && (
            <UseFilter>
              {formatMessage('notAllTransactionsShownSpecifyFilter')}
            </UseFilter>
          )}
        </div>
      ) : (
        <NoResults data-cy="no-results">
          {formatMessage('noTransactionsInSelectedFilter')}
        </NoResults>
      )}
    </div>
  )
}

const ItemsTitle = styled(Text).attrs({type: 'secondary'})`
  padding: 0 0 0.5em 0.5em;
  display: block;
`

const Items = styled.div`
  display: flex;

  & > * {
    flex: 1;
  }

  & > * + * {
    margin-left: 32px;
  }
`

const Icon = styled(Filter)`
  margin-right: 4px;
  position: relative;
  top: 2px;
`

const TransactionFilterModal = (props: TransactionFilterProps) => {
  const {fields, fieldSetters, resetFilter, submit} = useTransactionFilter(
    props
  )
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.close}
        title={formatMessage('filter')}
      >
        <>
          <ItemsTitle>{formatMessage('transactionsType')}</ItemsTitle>
          <Items>
            <Checkbox
              data-cy="filter-credit"
              checked={fields.credit}
              onChange={({target}) => fieldSetters.credit(target.checked)}
              label={formatMessage('credit')}
            />
            <Checkbox
              data-cy="filter-debit"
              checked={fields.debit}
              onChange={({target}) => fieldSetters.debit(target.checked)}
              label={formatMessage('debit')}
            />
          </Items>
          <ItemsTitle>{formatMessage('amount')}</ItemsTitle>
          <Items>
            <InputField
              data-cy="filter-amount-from"
              value={fields.amountFromString}
              onChangeText={fieldSetters.amountFromString}
              placeholder={formatMessage('from')}
            />
            <InputField
              data-cy="filter-amount-to"
              value={fields.amountToString}
              onChangeText={fieldSetters.amountToString}
              placeholder={formatMessage('to')}
            />
          </Items>
          <ItemsTitle>{formatMessage('date')}</ItemsTitle>
          <Items>
            <DatePicker
              data-cy="filter-date-from"
              value={fields.dateFrom}
              onChange={fieldSetters.dateFrom}
              placeholder={formatMessage('from')}
            />
            <DatePicker
              data-cy="filter-date-to"
              value={fields.dateTo}
              onChange={fieldSetters.dateTo}
              placeholder={formatMessage('to')}
            />
          </Items>
        </>
        <Items>
          <Button data-cy="reset-filter" visual="ghost" onClick={resetFilter}>
            {formatMessage('resetFilter')}
          </Button>
          <Button data-cy="submit-filter" visual="secondary" onClick={submit}>
            {formatMessage('showResults')}
          </Button>
        </Items>
      </Modal>
    </>
  )
}

const Layout = styled.div`
  flex: 1;
`

const Transactions = ({match}: ContextRouter) => {
  const accountId = match.params.accountId || ''
  const {
    transactionsData,
    transactionFilterProps,
    openFilter
  } = useTransactions(accountId)

  if (!transactionsData) return null

  const isDefaultFilter = transactionsData.request.isDefaultFilter

  return (
    <Layout>
      <Row
        style={{margin: '16px 0', padding: '0 16px', alignItems: 'flex-start'}}
      >
        <Text type="subheading">
          {formatMessage(
            isDefaultFilter ? 'recentTransactions' : 'filterTransactions'
          )}
        </Text>

        <Link data-cy="open-filter" onClick={openFilter}>
          <Icon color="#5D7083" size="16" />
          {formatMessage(isDefaultFilter ? 'filter' : 'changeFilter')}
        </Link>
      </Row>
      <TransactionList transactionsData={transactionsData} />
      <TransactionFilterModal {...transactionFilterProps} />
    </Layout>
  )
}

export default Transactions
