import type {EpinFormType, State} from '../../state'

import {getInitialEpinForm} from '../../state'
import {AppThunk} from '../../store'
import {apiRequest} from '../apiRequest'
import {SET_FORM_FIELD_VALUE, SET_VALUE} from '../commonActions'
import handleApiError from '../handleApiError'

const PATH = ['forms', 'epinForm']

export const selector = (state: State) => state.forms.epinForm

const openModal = (): AppThunk<void> => dispatch => {
  dispatch(SET_VALUE(PATH, getInitialEpinForm()))
  dispatch(SET_VALUE([...PATH, 'isModalOpen'], true))
}

const closeModal = (): AppThunk<void> => (dispatch, getState) => {
  if (selector(getState()).loading) return
  dispatch(SET_VALUE(PATH, getInitialEpinForm()))
  dispatch(SET_VALUE([...PATH, 'isModalOpen'], false))
}

const submit = (
  cardId: string,
  epin: string
): AppThunk<Promise<boolean | void>> => async (dispatch, getState) => {
  const {loading} = selector(getState())

  if (loading) return undefined

  dispatch(SET_VALUE([...PATH, 'loading'], true))
  try {
    await apiRequest(`/cards/${cardId}/ePIN`, {
      method: 'PUT',
      body: {ePin: epin}
    })
    dispatch(SET_VALUE([...PATH, 'success'], true))
    dispatch(SET_VALUE([...PATH, 'isModalOpen'], false))
    dispatch(SET_VALUE(['cards', cardId, 'settings', 'ePin'], epin))
    return true
  } catch (e) {
    await dispatch(handleApiError(e as Error))
    return false
  } finally {
    dispatch(SET_VALUE([...PATH, 'loading'], false))
  }
}

const setField = <
  FieldsType extends EpinFormType['fields'],
  FieldName extends keyof FieldsType & string
>(
  fieldName: FieldName,
  fieldValue: FieldsType[FieldName]
): AppThunk<void> => dispatch =>
  dispatch(SET_FORM_FIELD_VALUE(PATH, fieldName, fieldValue))

const _actions = {openModal, closeModal, submit, setField}
export type EpinActionsType = typeof _actions
export const actions: EpinActionsType = _actions
