// @flow
import type {ComponentType} from 'react'
import type {
  RequestCardProps,
  Address
} from '../../../containers/auth/requestCardContainer'
import requestCardContainer, {
  ApplicationType,
  flowStepIds,
  flowStepsFor
} from '../../../containers/auth/requestCardContainer'

import React from 'react'
import styled from 'styled-components'
import {CheckCircle} from 'react-feather'

import {getEnv} from '../../../utils/env'
import Layout from '../Layout'
import About from '../About'
import RequestCardSwitch from './RequestCardSwitch'
import {
  Text,
  InputField,
  Button,
  Alert,
  ToggleButton,
  Link,
  Checkbox,
  FileInput,
  Select
} from '../../ui'
import {formatMessage} from '../../../i18n'
import {
  recommendedCountryCodes,
  otherCountryCodes,
  relationships
} from '../../../constants'

type FlowStep = {
  HeaderText?: ComponentType<RequestCardProps>,
  HeaderSubText?: ComponentType<RequestCardProps>,
  body: ComponentType<RequestCardProps>,
  continueButton?: ComponentType<RequestCardProps>,
  hideBackButton?: boolean | ((props: RequestCardProps) => boolean),
  actionLink?: ComponentType<RequestCardProps>
}

const flowSteps: {[number]: FlowStep} = {}

// #regin FLOW STEP basicInfo

const SkipFormButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

const SelectInputWrapper = styled.div`
  margin-bottom: 24px;
`

const OtherNationalityHelpTextWrapper = styled.div`
  margin-left: 8px;
  margin-top: 4px;
`

const CorrespondenceAddressHelpTextWrapper = styled.div`
  margin-left: 8px;
  margin-bottom: 32px;
`

const CardTypeHelpTextWrapper = styled.div`
  margin-left: 8px;
  margin-top: 4px;
`

const getCardTypeHelpText = cardType => {
  if (cardType === 'MasterCard')
    return formatMessage('cardTypeHelperMasterCard')
  return ''
}

const basicInfoFlow = ({usageType, ...props}) => {
  return {
    HeaderText: props => {
      if (usageType === 'THISPERSON') return <DefaultHeaderText />
      return props.applicationType === ApplicationType.Teenager
        ? formatMessage('fillTeenagerDataToRequestCard')
        : formatMessage('fillOtherPersonsDataToRequestCard')
    },
    HeaderSubText: props => {
      if (usageType !== 'THISPERSON') return <></>
      return (
        <>
          {formatMessage('intendedForConsumers')}{' '}
          <Link href="mailto:skpay@skpay.sk">
            {formatMessage('intendedForConsumersEmail')}
          </Link>
        </>
      )
    },
    body: props => {
      const selectCountryOptions = [
        ...recommendedCountryCodes.map(code => ({
          label: formatMessage('countryName', {countryCode: code}),
          value: code
        })),
        {label: '', value: '--------', disabled: true},
        ...otherCountryCodes.map(code => ({
          label: formatMessage('countryName', {countryCode: code}),
          value: code
        })),
        {label: formatMessage('otherCountry'), value: 'OTHER'}
      ]
      const selectCardTypeOptions = [{label: 'MasterCard', value: 'MasterCard'}]
      const selectPostCardOptions = [
        {label: formatMessage('iHavePostCard'), value: true},
        {label: formatMessage('iDontHavePostCard'), value: false}
      ]
      const selectRelationshipOptions = [
        ...relationships.map(code => ({
          label: formatMessage('relationships', {code}),
          value: code
        })),
        {
          label: formatMessage('relationships', {code: 'other'}),
          value: 'OTHER'
        }
      ]
      const stepProps =
        usageType === 'THISPERSON'
          ? props.basicInfo
          : props.otherPersonBasicInfo
      const stepSource =
        usageType === 'THISPERSON' ? 'basicInfo' : 'otherPersonBasicInfo'
      return (
        <>
          {props.applicationType === ApplicationType.Teenager &&
            usageType === 'THISPERSON' && (
              <>
                <SelectInputWrapper>
                  <Select
                    required
                    withoutSpacing
                    data-cy="relationship"
                    label={formatMessage('teenagerRelationship')}
                    placeholder={formatMessage('teenagerRelationship')}
                    options={selectRelationshipOptions}
                    value={stepProps.relationshipSelect}
                    onChange={e =>
                      props.setField(
                        [stepSource, 'relationshipSelect'],
                        e.target.value
                      )
                    }
                  />
                </SelectInputWrapper>
                {stepProps.relationshipSelect === 'OTHER' && (
                  <InputField
                    label={formatMessage('relationships', {
                      code: 'other'
                    })}
                    placeholder={formatMessage('placeholderOtherRelationship')}
                    value={stepProps.relationship}
                    onChange={e =>
                      props.setField(
                        [stepSource, 'relationship'],
                        e.target.value
                      )
                    }
                  />
                )}
              </>
            )}
          <InputField
            required
            data-cy="name"
            label={
              usageType === 'THISPERSON'
                ? formatMessage('fullName')
                : props.applicationType === ApplicationType.Teenager
                ? formatMessage('teenagerFullName')
                : formatMessage('otherPersonFullName')
            }
            placeholder={
              usageType === 'THISPERSON'
                ? formatMessage('fullNamePlaceholder')
                : props.applicationType === ApplicationType.Teenager
                ? formatMessage('teenagerFullName')
                : formatMessage('otherPersonFullName')
            }
            value={stepProps.name}
            onChangeText={props.fieldSetter([stepSource, 'name'])}
          />
          <SelectInputWrapper>
            <Select
              required
              withoutSpacing
              data-cy="nationality"
              label={formatMessage('nationality')}
              placeholder={formatMessage('nationality')}
              options={selectCountryOptions}
              value={stepProps.nationalitySelect}
              onChange={e =>
                props.setField(
                  [stepSource, 'nationalitySelect'],
                  e.target.value
                )
              }
            />
            {stepProps.nationalitySelect === 'OTHER' && (
              <>
                <InputField
                  withoutSpacing
                  data-cy="nationality-input"
                  placeholder={formatMessage('nationality')}
                  value={stepProps.nationality}
                  onChange={e =>
                    props.setField([stepSource, 'nationality'], e.target.value)
                  }
                />
                <OtherNationalityHelpTextWrapper>
                  <Text type="secondary">
                    {formatMessage('otherNationalityHelper')}
                  </Text>
                </OtherNationalityHelpTextWrapper>
              </>
            )}
          </SelectInputWrapper>
          <InputField
            required
            data-cy="email"
            label={formatMessage('email')}
            placeholder={formatMessage('placeholderContactValue', {
              contactType: 'email'
            })}
            value={stepProps.email}
            onChangeText={props.fieldSetter([stepSource, 'email'])}
          />
          <InputField
            required
            data-cy="phone"
            label={formatMessage('mobilePhoneNumber')}
            placeholder={formatMessage('placeholderContactValue', {
              contactType: 'phone'
            })}
            value={stepProps.phone}
            onChangeText={props.fieldSetter([stepSource, 'phone'])}
          />
          {usageType === 'THISPERSON' && (
            <>
              <SelectInputWrapper>
                <Select
                  required
                  withoutSpacing
                  data-cy="card-type"
                  label={formatMessage('cardType')}
                  placeholder={formatMessage('placeholderCardType')}
                  options={selectCardTypeOptions}
                  value={stepProps.cardType || ''}
                  onChange={e =>
                    props.setField([stepSource, 'cardType'], e.target.value)
                  }
                />
                <CardTypeHelpTextWrapper>
                  <Text type="secondary">
                    {getCardTypeHelpText(stepProps.cardType)}
                  </Text>
                </CardTypeHelpTextWrapper>
              </SelectInputWrapper>
              <SelectInputWrapper>
                <Select
                  required
                  withoutSpacing
                  data-cy="post-card"
                  label={formatMessage('postCard')}
                  placeholder={formatMessage('placeholderPostCard')}
                  options={selectPostCardOptions}
                  value={stepProps.hasPostCard || ''}
                  onChange={e =>
                    props.setField([stepSource, 'hasPostCard'], e.target.value)
                  }
                />
              </SelectInputWrapper>
              {stepProps.hasPostCard === 'true' && (
                <InputField
                  required
                  data-cy="vs-of-post-card"
                  label={formatMessage('vsOfPostCardWithAbbr')}
                  placeholder={formatMessage('placeholderVsOfPostCard')}
                  value={stepProps.vsOfPostCard}
                  onChange={e =>
                    props.setField([stepSource, 'vsOfPostCard'], e.target.value)
                  }
                />
              )}
              <InputField
                data-cy="promo-code"
                label={formatMessage('promoCode')}
                placeholder={formatMessage('promoCode')}
                value={stepProps.promoCode}
                onChangeText={props.fieldSetter([stepSource, 'promoCode'])}
              />
            </>
          )}
          {usageType !== 'THISPERSON' && (
            <Checkbox
              data-cy="agreement-check"
              checked={stepProps.agreement}
              onChange={props.checkboxSetter([stepSource, 'agreement'])}
              label={formatMessage('otherPersonAgreementText')}
            />
          )}
        </>
      )
    },
    hideBackButton: false,
    continueButton: props => {
      if (usageType === 'THISPERSON')
        return <DefaultContinueButton {...props} />
      return (
        <Button
          data-cy="continue-button"
          disabled={!props.otherPersonBasicInfo.agreement}
          block
          onClick={props.handleContinue}
        >
          {formatMessage('continue')}
        </Button>
      )
    },
    actionLink: props => (
      <SkipFormButtonWrapper bigSpacing>
        <Link onClick={props.handleSkipForm}>
          {formatMessage('alreadyHaveContract')}
        </Link>
      </SkipFormButtonWrapper>
    )
  }
}

flowSteps[flowStepIds.basicInfo] = basicInfoFlow({
  usageType: 'THISPERSON'
})
flowSteps[flowStepIds.otherPersonBasicInfo] = basicInfoFlow({
  usageType: 'OTHERPERSON'
})

// #endregion FLOW STEP basicInfo

// #region FLOW STEP documents

const BirthNumberInputWrapper = styled.div`
  margin-bottom: 24px;
`

const BirthNumberHelpTextWrapper = styled.div`
  margin-left: 8px;
  margin-top: 4px;
`

const documentsFlow = ({usageType, ...props}) => {
  return {
    HeaderText: props => {
      if (usageType === 'THISPERSON') return <DefaultHeaderText />
      return props.applicationType === ApplicationType.Teenager
        ? formatMessage('fillTeenagerDataToRequestCard')
        : formatMessage('fillOtherPersonsDataToRequestCard')
    },
    body: props => {
      const stepProps =
        usageType === 'THISPERSON'
          ? props.documents
          : props.otherPersonDocuments
      const stepSource =
        usageType === 'THISPERSON' ? 'documents' : 'otherPersonDocuments'
      return (
        <>
          <BirthNumberInputWrapper>
            <InputField
              required
              data-cy="birth-number"
              label={formatMessage('birthNumber')}
              placeholder={formatMessage('birthNumberPlaceholder')}
              value={stepProps.birthNumber}
              onChangeText={props.onBirthNumberChange([
                stepSource,
                'birthNumber'
              ])}
              withoutSpacing
            />
            <BirthNumberHelpTextWrapper>
              <Text
                type="secondary"
                dangerouslySetInnerHTML={{
                  __html: formatMessage('birthNumberHelper')
                }}
              />
            </BirthNumberHelpTextWrapper>
          </BirthNumberInputWrapper>
          {(props.applicationType !== ApplicationType.Teenager ||
            usageType === 'THISPERSON' ||
            stepProps.teenagerHasId) && (
            <>
              {(usageType === 'THISPERSON' ||
                props.applicationType !== ApplicationType.Teenager) && (
                <ToggleButton
                  required
                  data-cy="identity-document-type"
                  label={formatMessage('identityDocument')}
                  items={[
                    {title: formatMessage('ID'), name: 'ID'},
                    {title: formatMessage('passport'), name: 'passport'}
                  ]}
                  activeName={stepProps.identityDocument.type}
                  onClick={props.fieldSetter([
                    stepSource,
                    'identityDocument',
                    'type'
                  ])}
                  withoutSpacing
                />
              )}
              <InputField
                data-cy="identity-document"
                placeholder={
                  stepProps.identityDocument.type === 'ID'
                    ? formatMessage('IDPlaceholder')
                    : formatMessage('passportPlaceholder')
                }
                value={stepProps.identityDocument.number}
                onChangeText={props.fieldSetter([
                  stepSource,
                  'identityDocument',
                  'number'
                ])}
              />
            </>
          )}
        </>
      )
    }
  }
}

flowSteps[flowStepIds.documents] = documentsFlow({
  usageType: 'THISPERSON'
})
flowSteps[flowStepIds.otherPersonDocuments] = documentsFlow({
  usageType: 'OTHERPERSON'
})
// #endregion FLOW STEP documents

// #region FLOW STEP addresses

const AddressInput = (props: {
  label?: string,
  address: Address,
  fieldSetter: Function
}) => (
  <>
    <InputField
      required
      data-cy="address-street"
      label={props.label}
      placeholder={formatMessage('streetName')}
      value={props.address.street}
      onChangeText={props.fieldSetter('street')}
      withoutSpacing
    />
    <InputField
      required
      data-cy="address-postal-code"
      placeholder={formatMessage('postalCode')}
      value={props.address.zip}
      onChangeText={props.fieldSetter('zip')}
      withoutSpacing
    />
    <InputField
      required
      data-cy="address-city"
      placeholder={formatMessage('city')}
      value={props.address.city}
      onChangeText={props.fieldSetter('city')}
    />
  </>
)

const addressesFlow = ({usageType, ...props}) => {
  return {
    HeaderText: props => {
      if (usageType === 'THISPERSON') return <DefaultHeaderText />
      return props.applicationType === ApplicationType.Teenager
        ? formatMessage('fillTeenagerDataToRequestCard')
        : formatMessage('fillOtherPersonsDataToRequestCard')
    },
    body: props => {
      const stepProps =
        usageType === 'THISPERSON'
          ? props.addresses
          : props.otherPersonAddresses
      const stepSource =
        usageType === 'THISPERSON' ? 'addresses' : 'otherPersonAddresses'
      return (
        <>
          <AddressInput
            label={formatMessage('permanentAddress')}
            address={stepProps.permanentAddress}
            fieldSetter={fieldName =>
              props.fieldSetter([stepSource, 'permanentAddress', fieldName])
            }
          />
          <ToggleButton
            required
            label={formatMessage('correspondenceAddress')}
            items={[
              {title: formatMessage('same'), name: 'same'},
              {title: formatMessage('different'), name: 'different'}
            ]}
            activeName={
              stepProps.correspondenceAddress.sameAsPermanent
                ? 'same'
                : 'different'
            }
            onClick={name =>
              props.setField(
                [stepSource, 'correspondenceAddress', 'sameAsPermanent'],
                name === 'same'
              )
            }
            withoutSpacing
          />
          {stepProps.correspondenceAddress.sameAsPermanent === false && (
            <AddressInput
              address={stepProps.correspondenceAddress}
              fieldSetter={fieldName =>
                props.fieldSetter([
                  stepSource,
                  'correspondenceAddress',
                  fieldName
                ])
              }
            />
          )}
          {usageType === 'THISPERSON' && (
            <CorrespondenceAddressHelpTextWrapper>
              <Text type="secondary">
                {formatMessage('correspondenceAddressInfo')}
              </Text>
            </CorrespondenceAddressHelpTextWrapper>
          )}
        </>
      )
    }
  }
}

flowSteps[flowStepIds.addresses] = addressesFlow({
  usageType: 'THISPERSON'
})
flowSteps[flowStepIds.otherPersonAddresses] = addressesFlow({
  usageType: 'OTHERPERSON'
})
// #endregion FLOW STEP addresses

// #region FLOW STEP agreements

flowSteps[flowStepIds.agreements] = {
  body: props => (
    <>
      <Checkbox
        data-cy="agreements-data-protection"
        checked={props.dataProtectionAgreement}
        onChange={props.checkboxSetter('dataProtectionAgreement')}
        label={
          <>
            {formatMessage('agreementDataProtectionText')}{' '}
            <Link href={window.config.skpayUrls.dataProtection}>
              {formatMessage('agreementDataProtectionLink')}
            </Link>
          </>
        }
      />
      <Checkbox
        data-cy="agreements-business-conditions"
        checked={props.businessConditionsAgreement}
        onChange={props.checkboxSetter('businessConditionsAgreement')}
        label={
          <>
            {formatMessage('agreementBusinessConditionsText')}{' '}
            <Link href={window.config.skpayUrls.businessConditions}>
              {formatMessage('agreementBusinessConditionsLink')}
            </Link>
          </>
        }
      />
      <Checkbox
        data-cy="agreements-fees"
        checked={props.feesAgreement}
        onChange={props.checkboxSetter('feesAgreement')}
        label={
          <>
            {formatMessage('agreementFeesText')}{' '}
            <Link href={window.config.skpayUrls.fees}>
              {formatMessage('agreementFeesLink')}
            </Link>
          </>
        }
      />
      <Checkbox
        data-cy="agreements-distance-contract"
        checked={props.distanceContractInfoAgreement}
        onChange={props.checkboxSetter('distanceContractInfoAgreement')}
        label={
          <>
            {formatMessage('agreementDistanceContractInfoText')}{' '}
            <Link href={window.config.skpayUrls.distanceContractInfo}>
              {formatMessage('agreementDistanceContractInfoLink')}
            </Link>
          </>
        }
      />
    </>
  )
}

// #endregion FLOW STEP agreements

// #region FLOW STEP downloadAndSend

const DownloadContractForm = styled.form`
  margin-bottom: 24px;
`

const FileInputWrapper = styled.div`
  margin-bottom: 24px;
`

const ActionStatusText = styled(Text).attrs({type: 'secondary'})`
  display: flex;
  justify-content: center;
  margin-top: 4px;
`

const TooltipTextWrapper = styled.div`
  margin-top: 40px;
`

flowSteps[flowStepIds.downloadAndSend] = {
  HeaderText: () => formatMessage('printYourApplicationAndSend'),
  body: props => (
    <>
      {!!props.contractFields && (
        <DownloadContractForm
          method="post"
          target="_blank"
          data-cy="contract-download"
          action={`${getEnv().REACT_APP_API_URL || ''}/api/generate-contract${
            props.applicationType === ApplicationType.Teenager
              ? '-teenager'
              : props.applicationType === ApplicationType.Other
              ? '-third-person'
              : ''
          }`}
        >
          {Object.entries(props.contractFields).map(([fieldName, key]) => {
            if (key.constructor === Object) {
              return Object.entries(key).map(([keyName, value]) => {
                return (
                  <input
                    key={`${fieldName}.${keyName}`}
                    type="hidden"
                    name={`${fieldName}.${keyName}`}
                    value={value}
                  />
                )
              })
            } else {
              return (
                <input
                  key={fieldName}
                  type="hidden"
                  name={fieldName}
                  value={key}
                />
              )
            }
          })}
          <Button block visual="secondary">
            {formatMessage('downloadApplication')}
          </Button>
        </DownloadContractForm>
      )}
      <FileInputWrapper data-cy="contract-upload">
        <FileInput
          label={formatMessage('loadSignedApplication')}
          selectedFiles={props.signedContractFiles}
          onChangeFiles={props.fieldSetter('signedContractFiles')}
          maxSize={1024 * 1024 * 7} // 7MB
        />
      </FileInputWrapper>
      <FileInputWrapper data-cy="id-document-upload">
        <TooltipTextWrapper>
          <Text type="lead">
            {props.applicationType === ApplicationType.Myself
              ? formatMessage('uploadIdTooltipMyself')
              : props.applicationType === ApplicationType.Teenager
              ? formatMessage('uploadIdTooltipTeenager')
              : formatMessage('uploadIdTooltipOther')}
            {formatMessage('uploadIdOrPassportTooltip')}
          </Text>
        </TooltipTextWrapper>
        <FileInput
          label={formatMessage('loadIdDocument')}
          selectedFiles={props.idDocumentFiles}
          onChangeFiles={props.fieldSetter('idDocumentFiles')}
          maxSize={1024 * 1024 * 7} // 7MB
        />
      </FileInputWrapper>
    </>
  ),
  continueButton: props => (
    <>
      <Button
        block
        onClick={props.handleContinue}
        disabled={props.sendingContract}
      >
        {formatMessage('submit')}
      </Button>
      {props.sendingContract && (
        <ActionStatusText>
          {formatMessage('applicationBeingSent')}
        </ActionStatusText>
      )}
    </>
  ),
  hideBackButton: props => props.sendingContract
}

// #endregion FLOW STEP downloadAndSend

// #region FLOW STEP successfullySent

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;
  margin-bottom: 32px;
`

flowSteps[flowStepIds.successfullySent] = {
  HeaderText: () => <></>,
  body: () => (
    <CenteredDiv>
      <CheckCircle size={50} />
      <Text style={{textAlign: 'center'}}>
        {formatMessage('applicationSentSuccessfully')}
      </Text>
    </CenteredDiv>
  ),
  continueButton: () => (
    <>
      <Button
        block
        onClick={() => {
          window.location.href = formatMessage('mainPageUrl')
        }}
      >
        {formatMessage('backToMainPage')}
      </Button>
    </>
  ),
  hideBackButton: true
}

// #endregion FLOW STEP successfullySent

const HeaderText = styled(Text)`
  margin-top: 8px;
  height: ${props => (props.withSubText ? '108px' : '88px')};
`

const HeaderSubText = styled(Text)`
  margin-top: 8px;
  display: block;
`

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

const DefaultHeaderText = () => formatMessage('fillYourDataToRequestCard')

const DefaultContinueButton = (props: RequestCardProps) => (
  <Button block data-cy="continue-button" onClick={props.handleContinue}>
    {formatMessage('continue')}
  </Button>
)

const RequestCardContent = (props: RequestCardProps) => {
  const flowStepsForApplication = flowStepsFor(props.applicationType)
  const actualStepId = flowStepsForApplication[props.flowStepIndex]
  const flowStepData = flowSteps[actualStepId] || {body: () => null}

  const HeaderTextComponent = flowStepData.HeaderText || DefaultHeaderText
  const HeaderSubTextComponent = flowStepData.HeaderSubText
  const Body = flowStepData.body
  const ContinueButton = flowStepData.continueButton || DefaultContinueButton
  const hideBackButton =
    flowStepData.hideBackButton instanceof Function
      ? flowStepData.hideBackButton(props)
      : flowStepData.hideBackButton
  const ActionLink = flowStepData.actionLink || (() => null)

  return (
    <div>
      <div style={{display: 'flex'}}>
        <HeaderText withSubText={!!HeaderSubTextComponent}>
          <HeaderTextComponent {...props} />
          {HeaderSubTextComponent && (
            <HeaderSubText>
              <HeaderSubTextComponent {...props} />
            </HeaderSubText>
          )}
        </HeaderText>
      </div>

      <Body {...props} />
      {props.error && (
        <Alert data-cy="error" type="error">
          {formatMessage(props.error)}
        </Alert>
      )}
      <ContinueButton {...props} />
      {!hideBackButton && (
        <BackButtonWrapper>
          <Link onClick={props.handleGoBack}>
            {formatMessage('backToPreviousStep')}
          </Link>
        </BackButtonWrapper>
      )}
      <ActionLink {...props} />
    </div>
  )
}

const RequestCardLayout = (props: RequestCardProps) => {
  return (
    <Layout screen="requestCard">
      <About screen="requestCard" />
      {props.applicationType ? (
        <RequestCardContent {...props} />
      ) : (
        <RequestCardSwitch {...props} />
      )}
    </Layout>
  )
}

export default requestCardContainer(RequestCardLayout)
