// @flow
import type {State, EditContactDetailFormType} from '../../state'
import type {Thunk} from '../../types/reduxTypes'

import {get, compact} from 'lodash'
import {SET_VALUE, SET_FORM_FIELD_VALUE} from '../commonActions'
import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import {getInitialEditContactDetailFormState} from '../../state'
import {userSelector} from '../../selectors'
import * as validations from '../../utils/validations'

type FieldsType = $PropertyType<EditContactDetailFormType, 'fields'>

const PATH = ['forms', 'editContactDetailForm']

export const selector = (state: State): EditContactDetailFormType =>
  get(state, PATH)

const setField = <FieldName: $Keys<FieldsType>>(
  fieldName: FieldName,
  fieldValue: $ElementType<FieldsType, FieldName>
) => SET_FORM_FIELD_VALUE(PATH, fieldName, fieldValue)

const startEditing = (editingContactType: string): Thunk<void> => (
  dispatch,
  getState
) => {
  if (
    selector(getState()).editingContactType ||
    selector(getState()).editingFlowStep
  )
    return
  dispatch(SET_VALUE([...PATH, 'editingContactType'], editingContactType))
  dispatch(SET_VALUE([...PATH, 'editingFlowStep'], 'EnterContactDetail'))
}

const finishEditing = (): Thunk<void> => (dispatch, getState) => {
  if (selector(getState()).loading) return
  dispatch(SET_VALUE(PATH, getInitialEditContactDetailFormState()))
}

const setError = error => SET_VALUE([...PATH, 'error'], error)
const setLoading = loading => SET_VALUE([...PATH, 'loading'], loading)

const submitContactDetail = (): Thunk<> => async (dispatch, getState) => {
  const {
    fields: {contactValue},
    loading,
    editingContactType,
    editingFlowStep
  } = selector(getState())

  if (
    loading ||
    !editingContactType ||
    editingFlowStep !== 'EnterContactDetail'
  )
    return

  const validationErrors = compact([
    editingContactType === 'email'
      ? validations.email(contactValue)
      : validations.phone(contactValue)
  ])
  if (validationErrors.length) {
    dispatch(setError(validationErrors[0]))
    return
  }

  dispatch(setError(null)) // remove error during loading
  dispatch(SET_VALUE([...PATH, 'codeForCurrentResend'], null))
  dispatch(SET_VALUE([...PATH, 'codeForNewResend'], null))
  dispatch(setLoading(true))

  try {
    const {validatedContactValue} = await apiRequest(
      '/client/contact-detail/submit',
      {
        method: 'POST',
        body: {contactType: editingContactType, contactValue}
      }
    )
    dispatch(
      SET_VALUE(
        [...PATH, 'data', 'validatedContactValue'],
        validatedContactValue
      )
    )
  } catch (error) {
    await dispatch(
      handleApiError(error, [
        ['ERR_DUPLICATE_RECORD', 'ERR_RATELIMIT_EXCEEDED'],
        errorCode => dispatch(setError(errorCode))
      ])
    )
    return
  } finally {
    dispatch(setLoading(false))
  }

  dispatch(SET_VALUE([...PATH, 'editingFlowStep'], 'EnterCodes'))
}

const updateContactDetail = (): Thunk<> => async (dispatch, getState) => {
  const user = userSelector(getState())
  const {
    fields: {contactValue, codeForCurrent, codeForNew},
    loading,
    editingContactType,
    editingFlowStep
  } = selector(getState())

  if (
    !user ||
    loading ||
    !editingContactType ||
    editingFlowStep !== 'EnterCodes'
  )
    return

  const validationErrors = compact([
    !codeForCurrent && 'ERR_INVALID_DATA',
    !codeForNew && 'ERR_INVALID_DATA'
  ])
  if (validationErrors.length) {
    dispatch(setError(validationErrors[0]))
    return
  }

  dispatch(setError(null)) // remove error during loading
  dispatch(SET_VALUE([...PATH, 'codeForCurrentResend'], null))
  dispatch(SET_VALUE([...PATH, 'codeForNewResend'], null))
  dispatch(setLoading(true))

  try {
    const {validatedContactValue} = await apiRequest(
      '/client/contact-detail/update',
      {
        method: 'POST',
        body: {
          contactType: editingContactType,
          contactValue,
          codeForCurrent,
          codeForNew
        }
      }
    )
    // if user has editing contact type, update in user
    if (user[editingContactType]) {
      dispatch(SET_VALUE(['user', editingContactType], validatedContactValue))
    }
    // if user has clientId, update in clients map
    if (user.clientId) {
      dispatch(
        SET_VALUE(
          ['clients', user.clientId, editingContactType],
          validatedContactValue
        )
      )
    }
  } catch (error) {
    await dispatch(
      handleApiError(error, [
        ['ERR_EXPIRED', 'ERR_INVALID_DATA', 'ERR_CODE_ENTER_LIMIT_EXCEEDED'],
        errorCode => dispatch(setError(errorCode))
      ])
    )
    return
  } finally {
    dispatch(setLoading(false))
  }

  dispatch(finishEditing())
}

const resendVerificationCode = (currentOrNew: string): Thunk<> => async (
  dispatch,
  getState
) => {
  const {
    fields: {contactValue},
    loading,
    editingContactType,
    editingFlowStep
  } = selector(getState())

  if (loading || !editingContactType || editingFlowStep !== 'EnterCodes') return

  dispatch(setError(null)) // remove error during loading
  dispatch(SET_VALUE([...PATH, 'codeForCurrentResend'], null))
  dispatch(SET_VALUE([...PATH, 'codeForNewResend'], null))
  dispatch(setLoading(true))

  const url =
    currentOrNew === 'new'
      ? '/send-verification-code'
      : '/send-verification-code-to-user'
  const resendFieldName =
    currentOrNew === 'new' ? 'codeForNewResend' : 'codeForCurrentResend'
  try {
    await apiRequest(url, {
      method: 'POST',
      body: {
        contactType: editingContactType,
        contactValue: currentOrNew === 'new' ? contactValue : undefined,
        reason: 'UPDATE_CONTACT'
      }
    })
    dispatch(SET_VALUE([...PATH, resendFieldName], {success: true}))
  } catch (error) {
    await dispatch(
      handleApiError(error, [
        ['ERR_RATELIMIT_EXCEEDED'],
        errorCode => {
          dispatch(
            SET_VALUE([...PATH, resendFieldName], {
              success: false,
              error: errorCode
            })
          )
        }
      ])
    )
  } finally {
    dispatch(setLoading(false))
  }
}

export const actions = {
  setField,
  startEditing,
  finishEditing,
  submitContactDetail,
  updateContactDetail,
  resendVerificationCode
}
