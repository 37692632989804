// @flow
import type {RequestCardProps} from '../../../containers/auth/requestCardContainer'
import {ApplicationType} from '../../../containers/auth/requestCardContainer'

import React from 'react'
import styled from 'styled-components'

import {Text, Button, Link} from '../../ui'
import {formatMessage} from '../../../i18n'

const HeaderText = styled(Text).attrs({type: 'title'})`
  height: 66px;
`

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

const ChooseButton = styled(Button)`
  margin-top: 32px;
`

const RequestCardSwitch = (props: RequestCardProps) => (
  <div>
    <div style={{display: 'flex'}}>
      <HeaderText>{formatMessage('requestCardSwitchTitle')}</HeaderText>
    </div>
    <ChooseButton
      block
      data-cy="choose-client"
      onClick={() => props.setApplicationType(ApplicationType.Myself)}
    >
      {formatMessage('requestCardForMyself')}
    </ChooseButton>
    <ChooseButton
      block
      data-cy="choose-teenager"
      onClick={() => props.setApplicationType(ApplicationType.Teenager)}
    >
      {formatMessage('requestCardForTeenager')}
    </ChooseButton>
    <ChooseButton
      block
      data-cy="choose-other"
      onClick={() => props.setApplicationType(ApplicationType.Other)}
    >
      {formatMessage('requestCardForOther')}
    </ChooseButton>
    <BackButtonWrapper>
      <Link
        data-cy="choose-skip"
        onClick={() => {
          props.handleSkipForm()
          props.setApplicationType(ApplicationType.Myself)
        }}
      >
        {formatMessage('alreadyHaveContract')}
      </Link>
    </BackButtonWrapper>
  </div>
)

export default RequestCardSwitch
