// NOTE used only for REACT_APP_API_URL
// everything else comes from server and saves into window.config

// native equivalent of env vars is stored in Expo.Constants.Manifest
// this provides a single endpoint for both web and native

export type EnvType = Record<string, unknown> & {
  REACT_APP_API_URL: string
}

let env: EnvType

export const initEnvWeb = () => {
  env = {
    ...process.env,
    REACT_APP_API_URL: process.env.REACT_APP_API_URL || window.location.origin
  }
}

export const initEnvNative = (importedEnv: EnvType) => {
  env = importedEnv
}

export const getEnv = () => env
