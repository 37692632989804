import React from 'react'
import styled from 'styled-components'

import Loader from './Loader'
import Text from './Text'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const StyledText = styled(Text)`
  text-align: center;
  margin-top: 3em;
`

type Props = {
  title?: string
}

export default ({title}: Props) => (
  <Container>
    <Loader $onLightBackground />
    {title && <StyledText>{title}</StyledText>}
  </Container>
)
