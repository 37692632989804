// @flow
import React, {Fragment} from 'react'
import styled from 'styled-components'

import {useContactUsForm} from '../../actions/profile/contactUsForm'
import {Text, Button, InputField, Alert, Paragraph} from '../ui'
import {formatMessage} from '../../i18n'

const Heading = styled(Text).attrs({type: 'heading'})`
  margin-bottom: 8px;
`

const Textarea = styled.textarea`
  width: 100%;
  border-color: #d8e1e6;
  font-size: 16px;
  font-family: 'basier_circle', sans-serif;
  padding: 16px 12px;
  line-height: 1.4;
  outline: none;
  min-height: 100px;
  margin-bottom: 16px;
  margin-top: 8px;

  ::placeholder {
    color: ${props => props.theme.inputfield.colors.input.placeholder};
  }
`

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`

const ContactUs = () => {
  const {
    loading,
    error,
    fields,
    fieldSetters,
    submit,
    success
  } = useContactUsForm()
  return (
    <Fragment>
      <Heading>{formatMessage('contactUs')}</Heading>
      <Paragraph>
        <Text type="secondary">{formatMessage('contactUsDescription')}</Text>
      </Paragraph>
      <form
        onSubmit={e => {
          e.preventDefault()
          submit()
        }}
      >
        <InputField
          data-cy="contact-us-email"
          placeholder={`${formatMessage('yourEmail')} - ${formatMessage(
            'optional'
          )}`}
          value={fields.email}
          onChangeText={fieldSetters.email}
        />
        <Textarea
          data-cy="contact-us-text"
          placeholder={formatMessage('yourMessage')}
          value={fields.text}
          onChange={e => fieldSetters.text(e.target.value)}
        />
        <StyledAlert>
          <Text bold>{formatMessage('optionalEmailInfo')}</Text>
        </StyledAlert>
        <Button
          data-cy="contact-us-submit"
          block
          visual="secondary"
          disabled={loading}
        >
          {formatMessage('submit')}
        </Button>
        {error && <Alert type="error">{formatMessage(error)}</Alert>}
        {success && (
          <Alert type="success">{formatMessage('contactUsSuccess')}</Alert>
        )}
      </form>
    </Fragment>
  )
}
export default ContactUs
