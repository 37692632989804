// @flow
import type {Node} from 'react'
import type {ContextRouter} from 'react-router-dom'

import React, {Fragment, useState} from 'react'
import {Redirect} from 'react-router-dom'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import {accountSelector, accountClientSelector} from '../../selectors'
import {Text, Button, AccountCard} from '../ui'
import {formatMessage} from '../../i18n'
import AccountTopupModal from './AccountTopupModal'
import Nav from './Nav'

const Title = styled(Text).attrs({type: 'title'})`
  color: #002f5e;
  margin-bottom: 0;
  width: 550px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    background-color: transparent;
    background-image: linear-gradient(
      90deg,
      rgba(243, 245, 245, 0),
      rgba(243, 245, 245, 1)
    );
  }
`

const Background = styled.div`
  background-color: ${props => props.theme.app.colors.background.dark};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${props => props.theme.app.sizes.maxWidth};
  min-width: ${props => props.theme.app.sizes.minWidth};
  width: 100%;
  margin: 80px auto 36px;
  flex-shrink: 0;
`

const StyledSidebar = styled.aside`
  background-color: #fff;
  position: sticky;
  top: 142px;
  width: 306px;
  align-self: flex-start;
  margin-right: 80px;
`

const Content = styled.div`
  display: flex;
  max-width: ${props => props.theme.app.sizes.maxWidth};
  min-width: ${props => props.theme.app.sizes.minWidth};
  width: 100%;
  margin: 56px auto;
`

const Account = (props: ContextRouter & {children: Node}) => {
  const [topupModalOpen, setTopupModalOpen] = useState(false)

  const accountId = props.match.params.accountId || ''
  const account = useSelector(state => accountSelector(state, accountId))
  const accountClient = useSelector(state =>
    accountClientSelector(state, accountId)
  )

  if (!account) return <Redirect to="/404" />

  return (
    <Fragment>
      <Background>
        <Row>
          <div>
            <Title data-cy="account-title">{accountClient.name}</Title>
            <Text data-cy="account-vs" type="secondary" bold uppercase>
              {formatMessage('variableSymbolWithAbbreviation')}:{' '}
              {account.variableSymbol}
            </Text>
          </div>
          <Button
            data-cy="account-open-topup-modal"
            onClick={() => setTopupModalOpen(true)}
          >
            {formatMessage('topUpAccount')}
          </Button>
        </Row>
      </Background>
      <Nav account={account} />
      <Content>
        <StyledSidebar>
          <AccountCard hasShadow account={account} />
        </StyledSidebar>
        {props.children}
      </Content>
      <AccountTopupModal
        variableSymbol={account.variableSymbol}
        isOpen={topupModalOpen}
        onRequestClose={() => setTopupModalOpen(false)}
      />
    </Fragment>
  )
}

export default Account
