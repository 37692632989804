import React from 'react'
import styled, {css} from 'styled-components'

const Label = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;

  ${({
    theme: {
      switch: {sizes}
    }
  }) => css`
    width: ${sizes.width};
    height: ${sizes.height};
    border-radius: ${sizes.height};
  `};
`

const Checkbox = styled.input`
  visibility: hidden;
  /* Some browsers can have issues if dimensions are 0px. */
  width: 1px;
  height: 1px;
  position: absolute;
`

const Knob = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 100%;
  transition: transform 0.25s;
  cursor: pointer;
  background-color: ${props => props.theme.switch.colors.off.knob};
  ${({
    theme: {
      switch: {sizes}
    }
  }) => css`
    width: ${sizes.height};
    height: ${sizes.height};
  `};

  ${Checkbox}:checked + & {
    transform: translateX(${props => props.theme.switch.sizes.height});
    background-color: ${props => props.theme.switch.colors.on.knob};
  }

  ${Checkbox}:disabled + & {
    background-color: ${props => props.theme.switch.colors.disabled.knob};
    cursor: not-allowed;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  border-radius: ${props => props.theme.switch.sizes.height};
  background-color: ${props => props.theme.switch.colors.off.background};

  ${Checkbox}:checked + * + & {
    background-color: ${props => props.theme.switch.colors.on.background};
  }

  ${Checkbox}:disabled + * + & {
    background-color: ${props => props.theme.switch.colors.disabled.background};
    cursor: not-allowed;
  }
`

const Switch = ({onChange, onChangeChecked, ...restProps}) => (
  <Label data-cy="switch">
    <Checkbox
      onChange={e => {
        if (onChange) onChange(e)
        if (onChangeChecked) onChangeChecked(e.target.checked)
      }}
      {...restProps}
      type="checkbox"
    />
    <Knob />
    <Background />
  </Label>
)

export default Switch
