// @flow
export const PING_COOLDOWN = 60000

export const NATIVE_APP_PIN_LENGTH = 4 // App pin
export const NATIVE_APP_EPIN_LENGTH = 4 // Card e-pin
export const NATIVE_APP_CARD_PIN_LENGTH = 4 // Card pin

export const recommendedCountryCodes = ['SK', 'CZ']

export const otherCountryCodes = [
  'BE',
  'BG',
  'HR',
  'CY',
  'DK',
  'EE',
  'FI',
  'FR',
  'GR',
  'NL',
  'IE',
  'LT',
  'LV',
  'LU',
  'HU',
  'MT',
  'DE',
  'PL',
  'PT',
  'AT',
  'RO',
  'SI',
  'ES',
  'SE',
  'IT',
  'GB',
  'UA'
]

export const relationships = ['PARENT', 'GRANDPARENT', 'AUNT', 'UNCLE']

export const DATE_FORMAT = 'dd/MM/yyyy'
