// @flow
import type {HOC} from 'recompose'
import type {State, CardAndAccountType} from '../../state'

import {connect} from 'react-redux'
import {compose, hoistStatics} from 'recompose'
import {allCardsAndAccountsListSelector} from '../../selectors'

export type CardsManagementProps = {
  myCardsAndAccounts: CardAndAccountType[]
  //otherCardsAndAccounts: CardAndAccountType[],
}

const container: HOC<*, CardsManagementProps> = compose(
  connect((state: State, props) => ({
    myCardsAndAccounts: allCardsAndAccountsListSelector(state)
  }))
)

export default hoistStatics(container)
