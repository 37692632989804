// @flow
export let showConfirmationDialog: (
  title: string
) => Promise<boolean> = async () => await false

export const initConfirmationDialog = (
  alertFunction: typeof showConfirmationDialog
) => {
  showConfirmationDialog = alertFunction
}
