// @flow
import type {HOC} from 'recompose'
import type {CardWithSettings, Account, CardSettings} from '../../state'

import {compose, hoistStatics, withProps} from 'recompose'
import {connect} from 'react-redux'
import * as cardManagementActions from '../../actions/cards/cardManagementActions'
import handleNotFound from '../../handleNotFound'
import cardContainer from './cardContainer'
import {formatMessage} from '../../i18n'

export type CardManagementProps = {
  card: CardWithSettings,
  account: Account
}

const _cardManagementContainer: HOC<CardManagementProps, {}> = compose(
  cardContainer,
  handleNotFound(props => props.card.openable === false)
)
export const cardManagementContainer = hoistStatics(_cardManagementContainer)

type InputCardManagementControlProps = {
  label: string,
  fieldName: $Keys<CardSettings>,
  card: CardWithSettings
}

export type CardManagementControlProps = InputCardManagementControlProps & {
  loading: boolean,
  error: ?string
} & typeof cardManagementActions.actions

export const cardManagementControlContainer: HOC<
  CardManagementControlProps,
  InputCardManagementControlProps
> = compose(
  withProps(props => ({
    loading: props.card.settingsLoading === props.fieldName,
    error:
      (props.card.settingsError &&
        props.card.settingsError.fieldName === props.fieldName &&
        formatMessage(
          props.card.settingsError.errorText,
          props.card.settingsError.errorData
        )) ||
      null
  })),
  connect(null, cardManagementActions.actions)
)
