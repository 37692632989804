// @flow
import React from 'react'
import styled from 'styled-components'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import {DateUtils} from 'react-day-picker'
import {Calendar} from 'react-feather'
import 'react-day-picker/lib/style.css'
import * as d from 'date-fns'

import {InputField, Text} from '../../ui'

const parseDate = (str: string, format, locale) => {
  // check length
  if (str.length !== format.length) return undefined
  // parse
  const parsed = d.parse(str, format, 0, {locale})
  // check validity
  if (!DateUtils.isDate(parsed)) return undefined
  // move to start of day
  return d.startOfDay(parsed)
}

const formatDate = (date: Date, format, locale) => {
  return d.format(date, format, {locale})
}

const FORMAT = 'dd/MM/yyyy' // see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md

type Props = {
  value: ?Date,
  onChange: (date: ?Date) => any,
  placeholder: string,
  label?: string,
  'data-cy': string
}

// Stateless component throws warning on console, because it cannot have ref.
class Input extends React.PureComponent<Props> {
  render() {
    return <InputField {...this.props} />
  }
}

const StyledDatePicker = styled.div`
  position: relative;

  .DayPickerInput {
    display: block;
  }
`

const Label = styled(Text).attrs({type: 'secondary'})`
  padding: 0 0 0.5em 0.5em;
  display: block;
`

const Icon = styled(Calendar)`
  position: absolute;
  bottom: 40px;
  right: 16px;
  pointer-events: none;
`

export const DatePicker = (props: Props) => (
  <StyledDatePicker data-cy={props['data-cy']}>
    {props.label && <Label>{props.label}</Label>}
    <DayPickerInput
      parseDate={parseDate}
      formatDate={formatDate}
      format={FORMAT}
      onDayChange={props.onChange}
      value={props.value}
      placeholder={props.placeholder}
      component={Input}
    />
    <Icon color="#5D7083" />
  </StyledDatePicker>
)
