// @flow
import type {NewPasswordModalProps} from '../../../../actions/auth/forgotPasswordActions'

import React from 'react'
import styled from 'styled-components'

import {useNewPasswordForm} from '../../../../actions/auth/forgotPasswordActions'
import {formatMessage} from '../../../../i18n'
import {Modal, Button, InputField, Text, Alert, Paragraph} from '../../../ui'

const Action = styled.div`
  margin-top: 24px;
`

const NewPasswordModal = (props: NewPasswordModalProps) => {
  const {
    loading,
    error,
    fields,
    fieldSetters,
    submitNewPasswordAndContinue
  } = useNewPasswordForm({platform: 'web', ...props})
  return (
    <Modal
      data-cy="enter-new-password-modal"
      isOpen={props.isOpen}
      onRequestClose={props.close}
      title={formatMessage('forgotPasswordTitle')}
      small
    >
      <Paragraph>
        <Text>{formatMessage('identitySuccessfullyConfirmed')}</Text>
      </Paragraph>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          submitNewPasswordAndContinue()
        }}
      >
        <InputField
          data-cy="password"
          type="password"
          label={formatMessage('password')}
          placeholder={formatMessage('yourPassword')}
          value={fields.password}
          onChangeText={fieldSetters.password}
          withoutSpacing
        />
        <InputField
          data-cy="password-again"
          type="password"
          placeholder={formatMessage('repeatPassword')}
          value={fields.confirmPassword}
          onChangeText={fieldSetters.confirmPassword}
        />
        {error && (
          <Alert data-cy={error} type="error">
            {formatMessage(error)}
          </Alert>
        )}
        <Action>
          <Button
            visual="secondary"
            block
            data-cy="submit-new-password"
            disabled={loading}
          >
            {formatMessage('signInWithNewPassword')}
          </Button>
        </Action>
      </form>
    </Modal>
  )
}

export default NewPasswordModal
