// @flow
import type {Node} from 'react'
import type {Transaction} from '../../../state'
import type {ReportTransactionModalProps} from '../../../actions/accounts/transactions/reportTransactionForm'

import React from 'react'
import styled from 'styled-components'

import {
  useReportTransactionFlow,
  useReportTransactionForm
} from '../../../actions/accounts/transactions/reportTransactionForm'
import {Modal, Text, InputField, Button, Alert, Paragraph} from '../../ui'
import {formatMessage} from '../../../i18n'

const Textarea = styled.textarea`
  width: 100%;
  border-color: #d8e1e6;
  font-size: 16px;
  font-family: 'basier_circle', sans-serif;
  padding: 16px 12px;
  line-height: 1.4;
  outline: none;
  min-height: 100px;
  margin-bottom: 16px;
  margin-top: 8px;
`

const ReportTransactionModal = (props: ReportTransactionModalProps) => {
  const {
    loading,
    error,
    fields,
    fieldSetters,
    submit
  } = useReportTransactionForm(props)
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.close}
      title={formatMessage('reportOfTransaction')}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          submit()
        }}
      >
        <Paragraph>
          <Text>{formatMessage('reportOfTransactionDesc')}</Text>
        </Paragraph>
        <InputField
          data-cy="report-transaction-email"
          label={formatMessage('yourEmail')}
          value={fields.email}
          onChangeText={fieldSetters.email}
        />
        <Text type="secondary">{formatMessage('errorDesc')}</Text>
        <Textarea
          data-cy="report-transaction-text"
          label={formatMessage('errorDesc')}
          value={fields.text}
          onChange={e => fieldSetters.text(e.target.value)}
        />
        {error && <Alert type="error">{formatMessage(error)}</Alert>}
        <Button
          data-cy="report-transaction-submit"
          block
          visual="secondary"
          disabled={loading}
        >
          {formatMessage('reportTransaction')}
        </Button>
      </form>
    </Modal>
  )
}

const ReportTransactionBlock = (props: {
  transaction: Transaction,
  render: (open: Function) => Node
}) => {
  const {open, reportTransactionModalProps} = useReportTransactionFlow(
    props.transaction
  )
  return (
    <>
      {props.render(open)}
      <ReportTransactionModal {...reportTransactionModalProps} />
    </>
  )
}

export default ReportTransactionBlock
