import * as React from 'react'
import RModal from 'react-modal'
import styled, {keyframes} from 'styled-components'
import {X} from 'react-feather'

import Text from './Text'
import {theme} from '../../components/ui/theme'

type RModalAdapterProps = {
  className?: string
  modalClassName?: string
  isOpen: boolean
} & Omit<RModal.Props, 'className' | 'portalClassName'>

// NOTE: It enables to style react-modal using styled-components.
const RModalAdapter = ({
  className,
  modalClassName,
  isOpen,
  ...restProps
}: RModalAdapterProps) => (
  <RModal
    className={modalClassName}
    portalClassName={className}
    isOpen={isOpen}
    {...restProps}
  />
)

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% {opacity: 1; }
`

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% {opacity: 0; }
`

// http://reactcommunity.org/react-modal/styles/classes.html
const StyledRModal = styled(RModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal'
})<{small?: boolean}>`
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(93, 112, 131, 0.88);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${fadeIn} 0.25s;
    cursor: pointer;
  }

  .ReactModal__Overlay--before-close {
    animation: ${fadeOut} 0.25s;
  }

  .Modal {
    max-height: 90%;
    overflow-y: auto;
    background-color: white;
    width: ${props => (props.small ? '450px' : '644px')};
    position: relative;
    cursor: default;
    border-radius: 4px;

    &:focus {
      outline: 0;
    }
  }

  .ReactModal__Content {
    opacity: 0;
    transform: translateY(-20vh);
    transition: all 250ms;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
    transform: translateY(0);
  }

  .ReactModal__Content--before-close {
    transform: translateY(-20vh);
    opacity: 0;
  }
`

const Close = styled.button`
  font-size: 1rem;
  line-height: 1rem;
  color: #002f5e;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
`

const Header = styled.header`
  border-bottom: 1px solid #ddd;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  padding: 16px;
`

type Props = {
  children: React.ReactNode
  title?: string
  warning?: string
  warning2?: string
  onRequestClose?: () => void
  small: boolean
  'data-cy': string
  isOpen: boolean
}

const Modal = ({
  children,
  title,
  warning,
  warning2,
  onRequestClose,
  small,
  'data-cy': datacy,
  ...restProps
}: Props) => (
  <StyledRModal
    closeTimeoutMS={250}
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
    ariaHideApp={false}
    onRequestClose={onRequestClose}
    small={small}
    {...(datacy ? {data: {cy: datacy}} : {})}
    {...restProps}
  >
    {title && (
      <Header>
        <TitleContainer>
          <Text bold>{title}</Text>
          {warning && (
            <Text type="secondary" style={{color: theme.text.colors.red}}>
              {warning}
            </Text>
          )}
          {warning2 && (
            <Text type="secondary" style={{color: theme.text.colors.red}}>
              {warning2}
            </Text>
          )}
        </TitleContainer>
        <Close onClick={onRequestClose}>
          <X color="#5D7083" />
        </Close>
      </Header>
    )}
    <Content>{children}</Content>
  </StyledRModal>
)

Modal.defaultProps = {
  small: false
}

export default Modal
