import {useState, useEffect, useMemo} from 'react'
import {compact, mapValues} from 'lodash'

import {apiRequest} from '../../apiRequest'
import handleApiError from '../../handleApiError'
import * as validations from '../../../utils/validations'
import {usePrevious} from '../../../utils/hooks'
import {useAppDispatch} from '../../../reduxHooks'

export type InviteModalProps = {
  isOpen: boolean
  close: () => void
  accountId: string
  continueFlow: () => void
}

export const useInviteForm = ({
  isOpen,
  accountId,
  continueFlow
}: InviteModalProps) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [usernameType, setUsernameType] = useState('email')
  const [username, setUsername] = useState('')

  const fieldSetters = useMemo(() => {
    const wrapper = (fieldSetter, fieldName) => newValue => {
      setError(null)
      if (fieldName === 'usernameType') setUsername('')
      fieldSetter(newValue)
    }
    return mapValues(
      {
        usernameType: setUsernameType,
        username: setUsername
      },
      wrapper
    )
  }, [])

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      setLoading(false)
      setError(null)
      setUsernameType('email')
      setUsername('')
    }
  }, [prevIsOpen, isOpen])

  const submitInvite = async () => {
    if (loading) return

    const validationErrors = compact([
      usernameType === 'email'
        ? validations.email(username)
        : validations.phone(username)
    ])
    if (validationErrors.length) {
      setError(validationErrors[0])
      return
    }

    setLoading(true)
    try {
      const {invite} = await apiRequest('/invite', {
        method: 'POST',
        body: {accountId, [usernameType]: username}
      })

      //insert invite to redux
      dispatch({type: 'SET_VALUE', path: ['invites', invite.id], value: invite})
      continueFlow()
    } catch (err) {
      await dispatch(
        handleApiError(err as Error, [
          [
            'ERR_CANT_INVITE_MYSELF',
            'ERR_INVITE_ALREADY_EXIST',
            'ERR_RATELIMIT_EXCEEDED'
          ],
          errorCode => setError(errorCode)
        ])
      )
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    fields: {usernameType, username},
    fieldSetters,
    submitInvite
  }
}

export const useInviteFlow = (accountId: string) => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false)

  const open = () => setInviteModalOpen(true)

  const inviteModalProps = useMemo(
    () => ({
      isOpen: inviteModalOpen,
      close: () => setInviteModalOpen(false),
      accountId,
      continueFlow: () => setInviteModalOpen(false)
    }),
    [accountId, inviteModalOpen]
  )

  return {open, inviteModalProps}
}
