// @flow
import type {
  CardManagementProps,
  CardManagementControlProps
} from '../../containers/cards/cardManagementContainer'
import type {RouterHistory} from 'react-router-dom'

import React, {Fragment} from 'react'
import {compose} from 'recompose'
import styled from 'styled-components'
import {ChevronRight, Edit, Check, X} from 'react-feather'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import {formatMessage} from '../../i18n'
import {
  Switch,
  Button,
  InputField,
  Link,
  Group,
  GroupItem,
  SectionTitle,
  Alert,
  LoadingOverlay,
  LoadingPlaceholder,
  Text
} from '../ui'
import {
  cardManagementContainer,
  cardManagementControlContainer
} from '../../containers/cards/cardManagementContainer'
import {withUserAndCardData} from '../../withData'
import CardTab from './CardTab'
import ReissueCard from './ReissueCard'
import ReprintPin from './ReprintPin'
import RequestMoney from './RequestMoney'
import Epin from './Epin'

const Layout = styled.div`
  flex: 1;
`

const _SwitchRow = (
  props: CardManagementControlProps & {disabled?: boolean}
) => (
  <GroupItem
    data-field-name={props.fieldName}
    value={props.label}
    action={
      <Switch
        disabled={props.disabled}
        data-cy="setting-switch"
        checked={props.card.settings[props.fieldName]}
        onChangeChecked={() =>
          props.submitToggleableField(props.card.id, props.fieldName)
        }
      />
    }
  />
)
const SwitchRow = cardManagementControlContainer(_SwitchRow)

const _ButtonRow = (
  props: CardManagementControlProps & {
    disabled?: boolean,
    buttonLabel: string,
    description?: string
  }
) => (
  <GroupItem
    data-field-name={props.fieldName}
    value={props.label}
    label={props.description}
    reverseContent
    action={
      props.buttonLabel && (
        <Button
          size="small"
          visual="secondary"
          disabled={props.disabled}
          data-cy="setting-button"
          onClick={() =>
            props.submitToggleableField(props.card.id, props.fieldName)
          }
        >
          {props.buttonLabel}
        </Button>
      )
    }
  />
)

const ButtonRow = cardManagementControlContainer(_ButtonRow)

const LimitControls = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-left: 8px;
`

const LimitActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  flex-shrink: 0;

  & > * + * {
    margin-left: 24px;
  }
`

const StyledSlider = styled(Slider)`
  margin-right: 24px;
`

const _LimitRow = (props: CardManagementControlProps) => {
  const editing = props.card.settingsEditingFieldName === props.fieldName
  if (!editing) {
    return (
      <GroupItem
        data-field-name={props.fieldName}
        value={props.card.settings[props.fieldName]}
        label={props.label}
        action={
          <Link
            data-cy="edit-limit"
            onClick={() =>
              props.startEditingField(props.card.id, props.fieldName)
            }
          >
            <Edit color="#5D7083" />
          </Link>
        }
      />
    )
  } else {
    return (
      <GroupItem data-field-name={props.fieldName}>
        <>
          <Text type="secondary">
            {formatMessage('cardLimitMaxValue', {
              label: props.label,
              maxValue: window.config.cardLimitsMaxValues[props.fieldName]
            })}
          </Text>
          {props.error && (
            <Alert data-cy="error" type="error">
              {props.error}
            </Alert>
          )}
          <LimitControls>
            <StyledSlider
              min={0}
              max={window.config.cardLimitsMaxValues[props.fieldName]}
              step={10}
              railStyle={{backgroundColor: '#D8E1E6', height: '1px'}}
              trackStyle={{backgroundColor: '#002F5E', height: '1px'}}
              handleStyle={{
                backgroundColor: '#002F5E',
                width: '16px',
                height: '16px',
                border: 0,
                marginTop: '-8px',
                boxShadow: 'none'
              }}
              value={Number(props.card.settingsEditingFieldValue) || 0}
              onChange={text =>
                props.editFieldValue(props.card.id, props.fieldName, text)
              }
            />
            <InputField
              withoutSpacing
              value={props.card.settingsEditingFieldValue}
              onChangeText={text =>
                props.editFieldValue(props.card.id, props.fieldName, text)
              }
            />
            <LimitActions>
              <Link
                data-cy="save-limit"
                onClick={() =>
                  props.submitEditableField(props.card.id, props.fieldName)
                }
              >
                <Check color="#6EB472" />
              </Link>
              <Link
                data-cy="cancel-change-limit"
                onClick={() =>
                  props.stopEditingField(props.card.id, props.fieldName)
                }
              >
                <X color="#E15151" />
              </Link>
            </LimitActions>
          </LimitControls>
        </>
      </GroupItem>
    )
  }
}
const LimitRow = cardManagementControlContainer(_LimitRow)

type LimitGroup = {
  isAllowedFieldName: string,
  isAllowedLabel?: string, // default value is same as isAllowedFieldName
  valueFieldName?: string,
  valueLabel?: string // default value is same as valueFieldName
}
const limitGroups: LimitGroup[] = [
  {
    isAllowedFieldName: 'allowATMWithdrawal',
    valueFieldName: 'atmWithdrawalDayLimit'
  },
  {
    isAllowedFieldName: 'allowCashlessPayments',
    valueFieldName: 'cashlessPaymentsDayLimit'
  },
  {
    isAllowedFieldName: 'allowInternetPayments',
    valueFieldName: 'internetPaymentDayLimit'
  },
  {
    isAllowedFieldName: 'allowContactlessPayments',
    valueLabel: 'contactlessPayments'
  }
]
const CardLimits = (props: CardManagementProps) => {
  const Wrapper = props.account.isMine ? Fragment : Group
  return (
    <>
      <SectionTitle>
        {formatMessage(props.account.isMine ? 'setLimits' : 'limits')}
      </SectionTitle>
      <Wrapper>
        {limitGroups.map(
          ({isAllowedFieldName, isAllowedLabel, valueFieldName, valueLabel}) =>
            props.account.isMine ? (
              <Group key={isAllowedFieldName}>
                <SwitchRow
                  label={formatMessage(isAllowedLabel || isAllowedFieldName)}
                  fieldName={isAllowedFieldName}
                  card={props.card}
                />
                {valueFieldName && props.card.settings[isAllowedFieldName] && (
                  <LimitRow
                    label={formatMessage('dayLimit')}
                    fieldName={valueFieldName}
                    card={props.card}
                  />
                )}
              </Group>
            ) : (
              <GroupItem
                key={isAllowedFieldName}
                value={
                  props.card.settings[isAllowedFieldName]
                    ? valueFieldName
                      ? props.card.settings[valueFieldName]
                      : formatMessage('allowed')
                    : formatMessage('notAllowed')
                }
                label={formatMessage(valueLabel || valueFieldName)}
              />
            )
        )}
      </Wrapper>
    </>
  )
}

const CardManagement = (
  props: CardManagementProps & {history: RouterHistory}
) => {
  return (
    <Layout data-cy="card-settings-limits">
      {props.history.location.pathname.startsWith('/accounts') && (
        <SectionTitle>
          <Link
            data-cy="card-management-go-back"
            to={`/accounts/${props.account.id}/cards`}
          >
            &lt; {formatMessage('cardsManagement')}
          </Link>
        </SectionTitle>
      )}
      <Group>
        <CardTab
          card={props.card}
          holder={props.account.clientName}
          hideStatus
        />
      </Group>
      <Group style={{marginTop: 6}}>
        {/*<SwitchRow label={formatMessage('addToMyCards')} fieldName="favorite" card={props.card} />*/}
        <ButtonRow
          label={`${formatMessage('statusOfCard')}: ${formatMessage(
            'cardStatus',
            {
              status: props.card.status
            }
          )}`}
          description={
            props.card.status === 'adminSuspended' &&
            formatMessage('toUnblockContactUsOn', {
              supportPhone: window.config.supportPhone.split(' ').join('\xa0')
            })
          }
          buttonLabel={
            props.card.status === 'adminSuspended'
              ? ''
              : props.card.settings.blocked
              ? formatMessage('unblock')
              : formatMessage('block')
          }
          fieldName="blocked"
          card={props.card}
          disabled={
            props.card.status === 'adminSuspended' ||
            (!props.account.isMine && props.card.settings.blocked)
          }
        />
      </Group>
      {props.account.isMine && (
        <Fragment>
          <SectionTitle />
          <Group>
            <Epin card={props.card} />
          </Group>
        </Fragment>
      )}
      {!props.card.settings.blocked && <CardLimits {...props} />}
      {props.account.isMine && (
        <Fragment>
          <SectionTitle />
          <Group>
            <ReprintPin
              card={props.card}
              Link={props => (
                <GroupItem
                  data-cy="open-reprint-pin-modal"
                  label={formatMessage('reprintPinDescription')}
                  value={formatMessage('reprintPin')}
                  reverseContent
                  onClick={props.openModal}
                  action={<ChevronRight color="#5D7083" />}
                />
              )}
            />
            <ReissueCard
              card={props.card}
              Link={props => (
                <GroupItem
                  data-cy="open-reissue-card-modal"
                  label={formatMessage('reissueCardDescription')}
                  value={formatMessage('reissueCard')}
                  reverseContent
                  onClick={props.openModal}
                  action={<ChevronRight color="#5D7083" />}
                />
              )}
            />
            <RequestMoney
              account={props.account}
              Link={props => (
                <GroupItem
                  data-cy="open-request-money-modal"
                  label={formatMessage('requestMoneyDescription')}
                  value={formatMessage('requestMoney')}
                  reverseContent
                  onClick={props.openModal}
                  action={<ChevronRight color="#5D7083" />}
                />
              )}
            />
          </Group>
        </Fragment>
      )}
      <LoadingOverlay isOpen={!!props.card.settingsLoading} />
    </Layout>
  )
}

export default compose(
  withUserAndCardData({
    loadingComponent: () => (
      <LoadingPlaceholder title={formatMessage('loadingCards')} />
    )
  }),
  cardManagementContainer
)(CardManagement)
