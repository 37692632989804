import React from 'react'
import {Check, Square} from 'react-feather'
import styled from 'styled-components'

import Text from './Text'

const Label = styled.label`
  margin-bottom: 24px;
  height: auto;
  display: flex;
  align-items: center;
  border: 1px solid #d8e1e6;
  text-align: center;
  padding: 16px 16px;
  border-radius: 2px;
  background-color: ${props => (props.checked ? '#F3F5F6' : '#fff')};
  cursor: pointer;
  user-select: none;
`

const Input = styled.input`
  display: inline-block;
  margin-right: 0.5rem;
  width: 0;
  height: 0;
  visibility: hidden;
`

const Checkbox = ({label, checked, 'data-cy': datacy, ...restProps}) => (
  <Label checked={checked} data-cy={datacy}>
    <div>
      {checked ? <Check color="#002F5E" /> : <Square color="#D8E1E6" />}
    </div>
    <Input type="checkbox" checked={checked} {...restProps} />
    <Text bold={checked}>{label}</Text>
  </Label>
)

export default Checkbox
