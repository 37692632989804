// @flow
import type {HOC} from 'recompose'
import type {State, EditContactDetailFormType, Client} from '../../state'

import {compose, hoistStatics, branch, renderComponent} from 'recompose'
import {connect} from 'react-redux'

import * as editContactDetailForm from '../../actions/profile/editContactDetailForm'
import {userSelector, clientSelector} from '../../selectors'

type InputContactDetailProps = {
  client?: Client,
  contactType: 'email' | 'phone'
}

export type ContactDetailProps = InputContactDetailProps &
  EditContactDetailFormType &
  typeof editContactDetailForm.actions & {
    contactValue: string,
    editable: boolean,
    doNotRender: boolean
  }

const container: HOC<ContactDetailProps, InputContactDetailProps> = compose(
  connect((state: State, {client, contactType}) => {
    const user = userSelector(state)
    const userClient = clientSelector(state)
    const form = editContactDetailForm.selector(state)

    // not current user
    if (client && (!userClient || client.id !== userClient.id)) {
      if (client[contactType]) {
        return {contactValue: client[contactType], editable: false, ...form}
      }
      return {doNotRender: true}
    }

    // current user
    if (user && user[contactType]) {
      return {contactValue: user[contactType], editable: true, ...form}
    }
    if (userClient && userClient[contactType]) {
      return {contactValue: userClient[contactType], editable: true, ...form}
    }
    return {doNotRender: true}
  }, editContactDetailForm.actions),
  branch(
    props => props.doNotRender,
    renderComponent(() => null)
  )
)

export default hoistStatics(container)
