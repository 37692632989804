// @flow
import React, {useState} from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../../i18n'
import {Text, Modal, Paragraph, Link} from '../../ui'

const VSHintModal = ({isOpen, setIsOpen}) => (
  <Modal
    data-cy="vs-hint-modal"
    isOpen={isOpen}
    onRequestClose={() => setIsOpen(false)}
    title={formatMessage('VSHintTitle')}
  >
    <Paragraph>
      <Text>{formatMessage('VSHintDescription')}</Text>
    </Paragraph>
  </Modal>
)

const Help = styled.div`
  display: flex;
  height: 96px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 48px;
`

const SpacedText = styled(Text)`
  margin-bottom: 8px;
`

const HelpSection = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Help>
      <SpacedText>
        {formatMessage('signUpUsingDataFromYourContract')}
      </SpacedText>
      <Link
        data-cy="open-vs-hint-modal"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {formatMessage('whereCanIFindTheData')}
      </Link>
      <VSHintModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Help>
  )
}

export default HelpSection
