import React, {HTMLInputTypeAttribute, PropsWithChildren, useState} from 'react'
import styled from 'styled-components'
import {formatMessage} from '../../i18n'
import {Eye, EyeOff} from 'react-feather'

import Text from './Text'

type CommonInputFieldProps = PropsWithChildren & {
  label?: string
  errorMessage?: string
  withoutSpacing?: boolean
  action?: React.ReactNode
  required?: boolean
  withVisibilityToggle?: boolean
  type?: HTMLInputTypeAttribute
  maxLength?: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeText?: (text: string) => void
  withoutLabel?: boolean
  hasError?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const Field = styled.label<CommonInputFieldProps>`
  display: block;
  margin-bottom: ${props => (props.withoutSpacing ? '0' : '24px')};
  position: relative;
`

const Label = styled.div`
  padding: 0 0 0.5em 0.5em;
`

const Input = styled.input<CommonInputFieldProps>`
  background-color: ${props => props.theme.inputfield.colors.input.background};
  border: 1px solid
    ${props =>
      props.hasError
        ? props.theme.inputfield.colors.input.border.error
        : props.theme.inputfield.colors.input.border.normal};
  border-radius: ${props => (props.errorMessage ? '2px 2px 0 0' : '2px')};
  color: ${props => props.theme.inputfield.colors.input.color};
  appearance: none;
  font-family: 'basier_circle', sans-serif;
  font-size: 16px;
  padding: 16px;
  margin: 0;
  margin-top: ${props => (props.withoutLabel ? '-1px' : '0.25rem')};
  outline: none;
  display: block;
  width: 100%;

  ::placeholder {
    color: ${props => props.theme.inputfield.colors.input.placeholder};
    opacity: 1;
  }
`

const ToggleIcon = styled.span`
  float: right;
  margin-right: 16px;
  margin-top: -36px;
  position: relative;
  z-index: 2;
  cursor: pointer;
`

const Error = styled.div`
  background-color: ${props => props.theme.inputfield.colors.error.background};
  color: ${props => props.theme.inputfield.colors.error.color};
  padding: 1em;
  border-radius: 0 0 2px 2px;
  position: relative;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: -12px;
    border-style: solid;
    border-width: 6px 8px;
    border-color: transparent transparent
      ${props => props.theme.inputfield.colors.error.background} transparent;
  }
`

const Action = styled.div`
  position: absolute;
  top: 0;
  right: 0.5em;
`

const InputField = ({
  label,
  onChange,
  onChangeText,
  errorMessage,
  withoutSpacing,
  action,
  required,
  withVisibilityToggle,
  type,
  maxLength,
  ...restProps
}: CommonInputFieldProps) => {
  const [currentType, setCurrentType] = useState(type)

  return (
    <Field withoutSpacing={withoutSpacing}>
      {label && (
        <Label>
          <Text type="secondary">{label}</Text>
          {required && (
            <Text type="secondary">{` (${formatMessage('mandatory')})`}</Text>
          )}
        </Label>
      )}
      <Input
        onChange={e => {
          // same API as native TextInput
          if (onChange) onChange(e)
          if (onChangeText) onChangeText(e.target.value)
        }}
        withoutLabel={!label}
        type={currentType}
        maxLength={maxLength}
        {...restProps}
      />
      {withVisibilityToggle && (
        <ToggleIcon
          onClick={() => {
            setCurrentType(currentType === 'password' ? 'text' : 'password')
          }}
        >
          {currentType === 'password' ? (
            <Eye color="#5D7083" />
          ) : (
            <EyeOff color="#5D7083" />
          )}
        </ToggleIcon>
      )}
      {errorMessage && <Error>{errorMessage}</Error>}
      {action && <Action>{action}</Action>}
    </Field>
  )
}

export default InputField
