//@flow
import type {ContactDetailProps} from '../../containers/profile/contactDetailContainer'

import React from 'react'
import {Edit} from 'react-feather'

import contactDetailContainer from '../../containers/profile/contactDetailContainer'
import {formatMessage} from '../../i18n'
import {
  Modal,
  Button,
  InputField,
  Alert,
  GroupItem,
  Link,
  Paragraph,
  Text
} from '../ui'

const EnterContactDetailModal = (props: ContactDetailProps) => (
  <Modal
    data-cy="enter-contact-detail-modal"
    isOpen={
      props.editingContactType === props.contactType &&
      props.editingFlowStep === 'EnterContactDetail'
    }
    onRequestClose={props.finishEditing}
    title={formatMessage('changeContact', {contactType: props.contactType})}
  >
    <form
      onSubmit={e => {
        e.preventDefault()
        props.submitContactDetail()
      }}
    >
      <InputField
        data-cy={`input-${props.contactType}`}
        label={formatMessage('yourNewContact', {
          contactType: props.contactType
        })}
        value={props.fields.contactValue}
        onChangeText={text => props.setField('contactValue', text)}
        placeholder={formatMessage('placeholderContactValue', {
          contactType: props.contactType
        })}
      />
      <Button
        data-cy={`submit-${props.contactType}`}
        block
        visual="secondary"
        disabled={props.loading}
      >
        {formatMessage('changeContact', {contactType: props.contactType})}
      </Button>
      {props.error && <Alert type="error">{formatMessage(props.error)}</Alert>}
    </form>
  </Modal>
)

const EnterCodesModal = (props: ContactDetailProps) => (
  <Modal
    data-cy="enter-codes-modal"
    isOpen={
      props.editingContactType === props.contactType &&
      props.editingFlowStep === 'EnterCodes'
    }
    onRequestClose={props.finishEditing}
    title={formatMessage('changeContactConfirmation', {
      contactType: props.contactType
    })}
  >
    <form
      onSubmit={e => {
        e.preventDefault()
        props.updateContactDetail()
      }}
    >
      <Paragraph>
        <Text data-cy="text-code-for-current">
          {formatMessage('pleaseEnterVerificationCodeContactValue', {
            contactType: props.contactType,
            contactValue: props.contactValue
          })}
        </Text>
      </Paragraph>
      <InputField
        data-cy="input-code-for-current"
        label={formatMessage('enterVerificationCode')}
        placeholder={formatMessage('verificationCodeFromContact', {
          contactType: props.contactType
        })}
        value={props.fields.codeForCurrent}
        onChangeText={text => props.setField('codeForCurrent', text)}
        action={
          <Link
            small
            data-cy="resend-code-for-current"
            onClick={() => props.resendVerificationCode('current')}
            type="button"
          >
            {formatMessage('resendCode')}
          </Link>
        }
      />
      {props.codeForCurrentResend &&
        (props.codeForCurrentResend.success ? (
          <Alert type="success" data-cy="code-for-current-resent-successfully">
            {formatMessage('codeResentSuccessfully')}
          </Alert>
        ) : (
          <Alert type="error" data-cy="code-for-current-resend-error">
            {formatMessage(props.codeForCurrentResend.error)}
          </Alert>
        ))}
      <Paragraph>
        <Text data-cy="text-code-for-new">
          {formatMessage('pleaseEnterVerificationCodeContactValue', {
            contactType: props.contactType,
            contactValue: props.data.validatedContactValue
          })}
        </Text>
      </Paragraph>
      <InputField
        data-cy="input-code-for-new"
        label={formatMessage('enterVerificationCode')}
        placeholder={formatMessage('verificationCodeFromContact', {
          contactType: props.contactType
        })}
        value={props.fields.codeForNew}
        onChangeText={text => props.setField('codeForNew', text)}
        action={
          <Link
            small
            data-cy="resend-code-for-new"
            onClick={() => props.resendVerificationCode('new')}
            type="button"
          >
            {formatMessage('resendCode')}
          </Link>
        }
      />
      {props.codeForNewResend &&
        (props.codeForNewResend.success ? (
          <Alert type="success" data-cy="code-for-new-resent-successfully">
            {formatMessage('codeResentSuccessfully')}
          </Alert>
        ) : (
          <Alert type="error" data-cy="code-for-new-resend-error">
            {formatMessage(props.codeForNewResend.error)}
          </Alert>
        ))}
      <Button
        data-cy="submit-codes"
        block
        visual="secondary"
        disabled={props.loading}
      >
        {formatMessage('submit')}
      </Button>
      {props.error && <Alert type="error">{formatMessage(props.error)}</Alert>}
    </form>
  </Modal>
)

const ContactDetail = (props: ContactDetailProps) => (
  <>
    <GroupItem
      data-cy={props.contactType}
      label={formatMessage(props.contactType)}
      value={props.contactValue}
      action={
        props.editable ? (
          <Link
            data-cy={`edit-${props.contactType}`}
            onClick={() => props.startEditing(props.contactType)}
          >
            <Edit color="#5D7083" />
          </Link>
        ) : undefined
      }
    />
    <EnterContactDetailModal {...props} />
    <EnterCodesModal {...props} />
  </>
)

export default contactDetailContainer(ContactDetail)
